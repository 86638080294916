import { GraphQLStatus } from 'types/graphql/constants/Status'

export const INITIAL = 'INITIAL'
export const LOADING = 'LOADING'
export const ERROR = 'ERROR'
export const DONE = 'DONE'

export const GRAPHQL_STATUS: GraphQLStatus = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  DONE: 'DONE'
}
