/**
 * Grabs the data we dump from the server into the DOM and exports them as a singleton.
 * This allows us to conveniently dump server data into the dom and use this to load
 * that data into our app.
 */
import debug from 'debug'
import { isEmpty } from 'lodash'

import { ClientData } from './types/ClientData'

// @ts-ignore
const clientData: ClientData = {}

const init = () => {
  if (!isEmpty(clientData)) return

  const dataNode = document.getElementById('client-data')

  const clientDataString = (dataNode && dataNode.innerHTML) || '{}'

  try {
    Object.assign(clientData, JSON.parse(clientDataString))
    debug('shoplistnodeweb:client-data:success')('load', clientData)
  } catch (error) {
    debug('shoplistnodeweb:client-data:error')('load', error)
  }
}

export default clientData
export { init }
