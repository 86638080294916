export enum Page {
  Hub = 'hub',
  SavedOffers = 'saved-offers',
  RewardsDashboard = 'rewards-dashboard'
}

export type Dimensions = {
  page?: Page
}

export enum MetricName {
  traffic = 'traffic',
  error = 'error'
}
