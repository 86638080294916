function cloneError(originalError: unknown, newMessage: string): Error {
  const clonedError = new Error(newMessage)
  if (!(originalError instanceof Error)) {
    return clonedError
  }
  clonedError.name = originalError.name

  if (originalError.stack) {
    clonedError.stack = originalError.stack
  }

  return clonedError
}

export default cloneError
