import { getHoneyAPIUrl } from 'lib/getHoneyAPIUrl'
import { HoneyOperationName } from 'types/HoneyOperationName'
import { GraphQLResponse } from 'shoplist-app/shared/types'

// See https://dash.joinhoney.com/operations/view/web_paypal_dashboard_getPopularStores
export interface PopularPPStore {
  currentCashbackOffer: {
    active: boolean
    fixedRatePercent: number
  } | null
  currentDoubleCashbackOffer: {
    activeNow: boolean
  } | null
  logoUrl: string
  name: string
  publicCouponCount: number | null
  searchBuyNowPayLaterDescription: string | null
  searchBuyNowPayLaterEligible: boolean | null
  storeId: string
  storeUrl: string
  encryptedMerchantId: string | null
  searchEncryptedMerchantId: string | null
}

interface PopularStoreSearchResponse {
  storeSearch: {
    stores: PopularPPStore[]
  }
}

interface FetchPopularStoresProps {
  abortController?: AbortController
  limit?: number
}

export async function fetchPopularStores(
  args?: FetchPopularStoresProps
): Promise<PopularPPStore[]> {
  const popularSearchUrl = getHoneyAPIUrl({
    variables: {
      limit: args?.limit || 6,
      storeCountry: 'US'
    },
    operationName: HoneyOperationName.GetPopularStores
  })

  const response = await fetch(popularSearchUrl, {
    signal: args?.abortController?.signal
  })
  const data =
    (await response.json()) as GraphQLResponse<PopularStoreSearchResponse>
  return data?.data?.storeSearch?.stores || []
}
