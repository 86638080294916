import { IncentiveType } from '../../../types/graphql/constants/IncentiveType'
import { TrackingDetails } from '../../shared/types'
import { getComponentType } from './Carousel'
import { Dispatch } from 'react'

export type NavBarTouchpoint = {
  rank: number
  content: {
    category_list: string
    gift_cards_title: string
    categories_title: string
  }
  tracking_details: TrackingDetails
}

export interface BannerTouchPoint extends Item {
  primaryImage: Image
  description: string
  trackingDetails: TrackingDetails
}

export type PageLayoutTouchPoint = {
  content: {
    section_id: string
    section_title: string
    container_type: string
    item_type: string
    section_subtitle: string
    actions: Array<string>
    loading_text: string
    background_color: string
    icon_url: string
  }
  tracking_details: TrackingDetails
}

export type ShoppingPageDataType = {
  data: {
    uiShopping: {
      shoppingFeed: {
        sections: SectionType[]
      }
    }
  }
  extensions: { correlationId: string }
}

export type ShoppingPageSuccessStateType = {
  type: 'SUCCESS'
  data: ShoppingPageDataType['data']
  extensions: ShoppingPageDataType['extensions']
}

export type CardType =
  | 'HERO'
  | 'HERO_FLEX'
  | 'MEDIUM_CARD'
  | 'SMALL_CARD'
  | 'BIG_CARD'
  | 'IMAGE_BANNER'
  | 'GIFT_CARD'
  | 'LOGO_CARD'
  | 'LOGO_CARD_WITH_OVERLAY'
  | 'STORE_CARD'
  | 'MEDIUM_INFORMATION_CARD'
  | 'TILE_CARD'
  | 'LARGE_CARD'

export type CardContainerTypes = 'LIST' | 'GRID'

interface CardTypeInfo {
  cardType: CardType
  numOfCarouselElements: number
  numOfCarouselElementsTablet: number
  numOfCarouselElementsMobile: number
  getComponent: getComponentType
  container?: CardContainerTypes
}

export interface ItemTypes {
  HERO: Function | null
  BIG_CARD: Function | null
  MEDIUM_CARD: Function | null
  SMALL_CARD: Function | null
  LOGO_CARD_WITH_TEXT: Function | null
  STORE_CARD: Function | null
  TILE_CARD: Function | null
}

export interface CarouselType {
  MEDIUM_CARD: CardTypeInfo
  SMALL_CARD: CardTypeInfo
  BIG_CARD: CardTypeInfo
  IMAGE_BANNER: CardTypeInfo
  LOGO_CARD_WITH_OVERLAY: CardTypeInfo
  LOGO_CARD: CardTypeInfo
}

export type ShoppingPageStateType =
  | { type: 'LOADING' }
  | { type: 'ERROR' }
  | ShoppingPageSuccessStateType

export interface OffersWithPstudioData {
  offer_value: string
  offer_redemption_channel: string
  offer_type: string
  merchant_image: string
  merchant_name: string
  expires: string
  expire_date: string
  offer_id: string
  offer_program_id: string
  offer_value_type: string
}

export type HoneyRecommendedMessageContent = {
  section_title: string
  section_description: string
  section_logo: string
  num_of_offers: string
  num_of_offers_text: string
  average_savings: string
  average_savings_text: string
  cta_button_text: string
  cta_action: string
  merchant_name: string
  merchant_logo: string
  merchant_id: string
  experiment_id: string
  treatment_id: string
  has_icon: string
}

export type HoneyFeaturedDealMessageContent = {
  section_title: string
  section_description: string
  section_logo: string
  merchant_name: string
  merchant_logo: string
  merchant_id: string
  offer_description: string
  num_of_uses: string
  num_of_uses_text: string
  last_saved_amount: string
  last_saved_amount_text: string
  cta_button_text: string
  cta_action: string
  coupon_class: string
  experiment_id: string
  treatment_id: string
  merchant_has_icon: string
}

export type ExtraContentType = {
  rank: number
  content: {
    recommended_deals_loading_text: string
  }
  tracking_details: TrackingDetails
}

export type BuyNowPayLaterType = {
  rank: number
  content: {
    section_title: string
    section_subtitle: string
    section_cta_text: string
    section_cta_link: string
    background_color: string
    image_url: string
    merchant_name: string
    description: string
    offer_program_id: string
    decrypted_offer_program_id: string
  }
  tracking_details: TrackingDetails
}

export type Preferences = {
  rank: number
  content: {
    id: string
    header: string
    button_text: string
    title: string
    style: string
    customer_list_id: string | null
    tracking_version: string | null
  }
  tracking_details: TrackingDetails
}[]

export type PreferencesOptions = {
  rank: number
  content: {
    name: string
    customer_list_id: string
    corresponding_question_id: string
  }
  tracking_details: TrackingDetails
}[]

export type NewOrExpiringOfferType = {
  id: string
  decrypted_offer_program_id: string
  merchant_name: string
  offer_url: string
  logo_url: string
  description: string
}

export type NewOrExpiringSectionType = {
  rank: string
  content: {
    section_title: string
    section_cta_text: string
    section_cta_link: string
    loading_section_text: string
  }
  tracking_details: TrackingDetails
}

export interface BigCardType extends Item {
  title: string
  primaryImage: Image
  secondaryImage: Image | null
  description: string
}

export interface SmallCardType extends Item {
  title: string
  primaryImage: Image
  description: string
  storeIds: [string, string]
  itemType?: Item['itemType']
  rewardsPointsLabel: string | null
  shoppingCategories: string[] | null
  savedToWallet?: boolean | null
}

export interface StoreCardType extends Item {
  title: string
  primaryImage: Image
  description: string
  storeIds: [string, string]
  itemType?: Item['itemType']
  rewardsPointsLabel: string | null
  rewardsPointsSubtitle?: string | null
  shoppingCategories?: string[] | null
}

export interface TileCardType extends Item {
  title: string
  primaryImage: Image
  description: string
  savedToWallet: boolean | null
  storeIds?: string[]
}

export interface OfferCardType {
  honeyOfferId: string
  honeyOfferType: 'CODE' | 'DEAL'
  rank: number
  title: string
  subtitle: string
  description: string
  storeIds?: string[]
  primaryImage: {
    accessibilityLabel: string
    url: string
    name: string | null
    backgroundColor: string | null
    imageSize: string | null
  } | null
  actions: Action[]
  tags: Tag[] | null
  customRedemptionCode?: string | null
  offerUrl?: string | null
}

export interface CouponTermsType {
  title: string
  content: string
}

export interface SectionTypeMeta {
  slotId?: string
  incentiveType?: IncentiveType
  sectionId?: string
  touchpointId: string
  actions: Action[]
  title?: string | null
  subtitle?: string | null
  htmlSubtitle?: {
    content: string
  } | null
  icon?: {
    accessibilityLabel?: string | null
    backgroundColor?: string | null
    url: string | null
    name?: string | null
    imageSize?: string | null
  }
  collectionId?: string | null
}

export interface SectionType extends SectionTypeMeta {
  collectionId?: string | null
  items: Item[]
  rank: number
  backgroundColor?: string | null
  loadingText?: string
  layout: {
    containerType: string
    itemType: string
  }
  trackingDetails?: TrackingDetails
  emptySectionMessage?: string | null
  emptySectionImage?: Image | null
}

export interface Item {
  itemType?: 'OFFER' | 'REWARDS_STORE' | 'NON_REWARDS_STORE' | 'HYBRID_OFFER'
  itemId: string
  id?: string
  rank: number
  campaignId?: string | null
  rewardsPointsLabel?: string | null
  rewardsPointsSubtitle?: string | null
  title: string | null
  subtitle: string | null
  description: string | null
  primaryImage: Image | null
  secondaryImage: Image | null
  storeIds?: string[] | null
  tags: Tag[] | null
  actions: (Action | null)[] | null
  trackingDetails?: TrackingDetails | null
  totalItems?: number | null
  encryptedOfferProgramId?: string | null
  decryptedOfferProgramId?: string | null
  gradient?: boolean | null
  imageSize?: 'FULL' | 'PARTIAL' | null
  backgroundColor?: string | null
  textColor?: string | null
  termsAndConditionsSection?: { title: string; content: string } | null
  shoppingCategories?: string[] | null | undefined
  savedToWallet?: boolean | null
  cardImage?: Image | null
  decryptedOfferId?: string | null
  conditions?:
    | {
        image: Image | null
        content: string
      }[]
    | null
  details?: { title: string; content: string } | null
  disclaimer?: string | null
  howItWorksSection?: SectionType | null
  howToUseSection?: { title: string; content: string } | null
  landingUrl?: string | null
  logoImage?: Image | null
  offerSpecificsSection?: {
    title: string | null
    items:
      | {
          title: string | null
          primaryImage: Image | null
        }[]
      | null
  } | null
  offerType?: string | null
  shortTitle?: string | null
  storeName?: string | null
}

export interface SeoModalResult {
  itemId: string
  itemType: string
  rank: number
  title: string
  description: string
  primaryImage: {
    accessibilityLabel: string
    url: string
    backgroundColor: string
  }
  storeIds: string[]
  actions: Action[]
  textColor: string
  offerType: string
  redemptionChannels: string[]
  details: { title: string; content: string }
  termsAndConditionsSection: { title: string; content: string }
  couponCodeSection: { title: string; content: string }
  howToUseSection: { title: string; content: string }
  conditions: {
    image: Image
    content: string
  }[]
  landingUrl: string
  savedToWallet: boolean
}

export interface GroupItem {
  [key: string]: [
    {
      [key: string]: string
    }
  ]
}
export interface SectionTypeItems<E> {
  items: E
}

export interface OfferDetailsType extends Item {
  itemId: string
  title: string
  subtitle: string
  description: string
  landingUrl: string
  details: { title: string; content: string } | null
  termsAndConditionsSection: { title: string; content: string } | null
  howToUseSection: { title: string; content: string } | null
  couponCodeSection: { title: string; content: string } | null
  conditions: {
    image: Image
    content: string
  }[]
  primaryImage: Image
  decryptedOfferId: string | null
  actions: Action[]
  savedToWallet: boolean
  savedToWalletMessage?: string
  decryptedOfferProgramId: string
  shoppingCategories: string[] | null
}

export interface SaveOfferToWalletType {
  offerId: string
  savedToWallet: boolean
  message?: string
  conditions:
    | {
        image: Image
        content: string
      }[]
    | null
  nextActions: Action[]
}

export interface Conditions {
  icon: string
  url: string
  content: string
}

export interface Image {
  name?: string | null
  accessibilityLabel?: string
  url: string
  backgroundColor?: string
  imageSize?: 'FULL' | 'PARTIAL' | null
}

export interface Tag {
  text: string
  type: TagType
}

export interface Action {
  type?: ActionType | null
  title?: string | null
  navigationType?: NavigationType | null
  navigationTarget?: string | null
  tooltipDetails?: string
  modalContent?: ModalContent | null
}

export interface PrivacyType extends Item {
  primaryImage: Image
}

export interface ModalContent {
  title: string
  actions: Action[] | null
  description: string
  primaryImage?: Image | null
  modalSections: {
    description: string
    icon?: Image | null
    title: string
  }[]
}
export interface ContainerType {
  CAROUSEL: 'CAROUSEL'
  GRID: 'GRID'
  MOSAIC_GRID: 'MOSAIC_GRID'
  IMAGE_BANNER_CAROUSEL: 'IMAGE_BANNER_CAROUSEL'
  LIST: 'LIST'
}

export type TagType =
  | 'OFFER_REDEMPTION_CHANNEL'
  | 'EXPIRATION_DATE'
  | 'PERCENT_CLAIMED'
  | 'LAST_SAVED'
  | 'NUM_OF_USES'
  | 'COUPON_CODE'
  | 'LAST_USED'

export type ActionType =
  | 'NAVIGATION'
  | 'SAVE_TO_WALLET'
  | 'LOGIN_TO_SAVE_TO_WALLET'
  | 'TOOLTIP'
  | 'MODAL'
  | 'DISMISS'
  | 'COPY_CODE'
  | 'SAVE_HYBRID_OFFER'
  | 'QUICK_SAVE_HYBRID_OFFER'

export type NavigationType =
  | 'IAB'
  | 'WEBVIEW'
  | 'NATIVE'
  | 'SAME_TAB'
  | 'NEW_TAB'
  | 'OFFER_DETAILS_MODAL'
  | 'GOLD_MODAL'
  | 'HYBRID_OFFER_MODAL'
export interface OfferSearchType {
  rank: number
  content: {
    placeholder: string
  }
  tracking_details: TrackingDetails
}

// Offer Details Modal Context
type OfferDetailsModalContextStatusType = 'OPEN' | 'SAVED_OFFER' | 'CLOSED'
export type OfferDetailsModalDispatchActionType =
  | {
      type: 'OPEN'
      payload: {
        offerProgramId?: string | null
        honeyStoreId?: string | null
        honeyOfferId?: string | null
      }
    }
  | { type: 'CLOSED' }
  | { type: 'SAVED_OFFER' }

export type OfferDetailsModalContextStateType = {
  status: OfferDetailsModalContextStatusType
  offerProgramId?: string | null
  honeyOfferId?: string | null
  honeyStoreId?: string | null
}

export type OfferDetailsModalContextType = {
  state: OfferDetailsModalContextStateType
  dispatch: Dispatch<OfferDetailsModalDispatchActionType>
}

export type OfferDetailsModalContextReducerType = (
  prevState: OfferDetailsModalContextStateType,
  action: OfferDetailsModalDispatchActionType
) => OfferDetailsModalContextStateType

export type OfferDetailsModalDispatch =
  React.Dispatch<OfferDetailsModalDispatchActionType>

export type OnClickOrKeyboardEventType =
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  | React.KeyboardEvent

// Gold Store Terms Modal Context
type GoldStoreTermsModalContextStatusType = 'OPEN' | 'CLOSED'
export type GoldStoreTermsModalDispatchActionType =
  | {
      type: 'OPEN'
      payload: {
        storeId: string | null
      }
    }
  | { type: 'CLOSE' }

export type GoldStoreTermsModalContextStateType = {
  status: GoldStoreTermsModalContextStatusType
  storeId: string | null
}

export type GoldStoreTermsModalContextType = {
  state: GoldStoreTermsModalContextStateType
  dispatch: Dispatch<GoldStoreTermsModalDispatchActionType>
}

export type GoldStoreTermsModalContextReducerType = (
  prevState: GoldStoreTermsModalContextStateType,
  action: GoldStoreTermsModalDispatchActionType
) => GoldStoreTermsModalContextStateType

export type GoldStoreTermsModalDispatch =
  React.Dispatch<GoldStoreTermsModalDispatchActionType>

//Modal Context
type OfferDetailsModalContextStatus =
  | 'OPEN'
  | 'OPEN_SAVE'
  | 'OFFER_SAVED'
  | 'CLOSED'
type CashBackOfferDetailsModalContextStatus =
  | 'OPEN'
  | 'OPEN_SAVE'
  | 'OFFER_SAVED'
  | 'CLOSED'
type GoldModalContextStatus = 'OPEN' | 'CLOSED'

export type OfferDetailsModalContextContextState = {
  status: OfferDetailsModalContextStatus
  offerProgramId: string | null
  honeyOfferId: string | null
  honeyStoreId: string | null
}
export interface CashBackOfferDetailsModalContextState {
  status: CashBackOfferDetailsModalContextStatus
  campaignId: string | null
  storeId: string | null
}

export interface GoldModalContextState {
  status: GoldModalContextStatus
  storeId: string | null
}

export interface ModalContextState {
  offerDetailsModal: OfferDetailsModalContextContextState
  cashBackOfferDetailsModal: CashBackOfferDetailsModalContextState
  goldModal: GoldModalContextState
}
export interface OpenOfferDetailsModalDispatchAction {
  type: 'OPEN_OFFER_DETAILS'
  payload: {
    offerProgramId?: string | null
    honeyStoreId?: string | null
    honeyOfferId?: string | null
  }
}
export interface OpenSaveOfferDetailsModalDispatchAction {
  type: 'OPEN_SAVE_OFFER_DETAILS'
  payload: {
    offerProgramId?: string | null
    honeyStoreId?: string | null
    honeyOfferId?: string | null
  }
}
interface CloseOfferDetailsModalDispatchAction {
  type: 'CLOSE_OFFER_DETAILS'
}
interface SaveOfferDetailsModalDispatchAction {
  type: 'SAVE_OFFER_DETAILS'
}

type OfferDetailsModalDispatchAction =
  | OpenOfferDetailsModalDispatchAction
  | OpenSaveOfferDetailsModalDispatchAction
  | CloseOfferDetailsModalDispatchAction
  | SaveOfferDetailsModalDispatchAction

export interface OpenCashBackOfferDetailsModalDispatchAction {
  type: 'OPEN_CASHBACK_OFFER_DETAILS'
  payload: {
    storeId: string | null
    campaignId: string | null
  }
}

export interface OpenSaveCashBackOfferDetailsModalDispatchAction {
  type: 'OPEN_SAVE_CASHBACK_OFFER_DETAILS'
  payload: {
    storeId: string | null
    campaignId: string | null
  }
}

interface CloseCashBackOfferDetailsModalDispatchAction {
  type: 'CLOSE_CASHBACK_OFFER_DETAILS'
}

interface SaveCashBackOfferDetailsModalDispatchAction {
  type: 'SAVE_CASHBACK_OFFER_DETAILS'
}

type CashBackOfferDetailsModalDispatchAction =
  | OpenCashBackOfferDetailsModalDispatchAction
  | OpenSaveCashBackOfferDetailsModalDispatchAction
  | CloseCashBackOfferDetailsModalDispatchAction
  | SaveCashBackOfferDetailsModalDispatchAction

export interface OpenGoldStoreTermsModalDispatchAction {
  type: 'OPEN_GOLD_STORE_TERMS'
  payload: {
    storeId: string | null
  }
}

interface CloseGoldStoreTermsModalDispatchAction {
  type: 'CLOSE_GOLD_STORE_TERMS'
}
type GoldStoreTermsModalDispatchAction =
  | OpenGoldStoreTermsModalDispatchAction
  | CloseGoldStoreTermsModalDispatchAction

export type ModalContextDispatchAction =
  | OfferDetailsModalDispatchAction
  | CashBackOfferDetailsModalDispatchAction
  | GoldStoreTermsModalDispatchAction

export type ModalContextDispatch = Dispatch<ModalContextDispatchAction>

export interface ModalContext {
  state: ModalContextState
  dispatch: ModalContextDispatch
}

export type ModalContextReducer = (
  prevState: ModalContextState,
  action: ModalContextDispatchAction
) => ModalContextState

export type HandleClick<EventType = OnClickOrKeyboardEventType> = (
  event?: EventType
) => void

export type ActionHandler = () => void

export type LinkTarget = '_blank' | '_self'

export type StoreDetailItem = {
  title: string
  content: string
  icon?: {
    name: string
    url: string
  }
}

export interface StoreDetail {
  itemId: string
  itemType: string
  title: string
  description: string
  landingUrl: string
  storeIds: string[]
  primaryImage: Image
  rewardsPointsLabel: string
  rewardsPointsSubtitle?: string
  secondaryImage: Image
  termsAndConditionsSection: StoreDetailItem | null
  storeDescription: string
  shoppingCategories?: string[]
  aboutStore?: StoreDetailItem
  averageSavings30d?: StoreDetailItem | null
  freeShipping?: StoreDetailItem | null
  freeReturn?: StoreDetailItem | null
  payInFourPayments?: StoreDetailItem | null
  cashBackRewards: StoreDetailItem
  honeyDeals: OfferCardType[]
  defaultCouponTerms: StoreDetailItem
  totalHoneyOffers: number
  totalHoneyCoupons: number
  totalHoneyDeals: number
  actions: Action[]
  metadataSection?: {
    metadataItems: {
      title: string
      description: null
      icon: {
        accessibilityLabel: null
        name: string
        url: string
      }
    }[]
  }
  rewardsSection?: {
    title: string
    rewards: {
      title: string
      description: string
      value: string
    }[]
  }
  honeyDealsSection?: {
    title: string
    description: string
    stats: {
      title: string
      value: string
    }[]
    offers: StoreCardType[]
  }
  paypalMerchantOffersSection: {
    title: string
    offers: Item[]
  }
  honeyCoupons?: (OfferCardType | null)[] | null
}

export interface GoldStore {
  itemId: string
  title: string
  primaryImage: Image
  rewardsPointsLabel?: string
  rewardsPointsSubtitle?: string
  howRewardPointsWork: SectionType
  actions: Action[]
  storeIds: string[]
  shoppingCategories: string[] | null
}

// TODO: Update CashbackOfferDetailsType once graphQL schema for this response is done!!
export interface CashbackOfferDetailsType extends Item {
  title: string
  primaryImage: Image
  howItWorksSection: SectionType
  howToUseSection: {
    title: string
    content: string
  } | null
  conditions:
    | {
        image: Image
        content: string
      }[]
    | null
  offersEffectivePeriodInfo: { content: string }[] | null
  savedToWallet: boolean | null
  savedToWalletMessage: string | null
  landingUrl: string | null
}

export enum RecentSearchType {
  STORE = 'STORE',
  QUERY = 'QUERY'
}

export interface RecentSearch {
  name: string
  type: RecentSearchType
}

export interface RecentSearchStore extends RecentSearch {
  storeId: string
  storeUrl?: string | null
  logoUrl?: string | null
  type: RecentSearchType.STORE
}

export interface RecentSearchQuery extends RecentSearch {
  storeId?: string | null
  type: RecentSearchType.QUERY
}

export type RecentSearchItem = RecentSearchQuery | RecentSearchStore

export interface StorageValue {
  [encryptedAccountNumber: string]: RecentSearchItem[]
}
