import useTreatment from './useTreatment'
import EXPERIMENTS from 'constants/experiment'

const useWebSearchEnabled = () => {
  const isWebSearchEnabled = useTreatment({
    name: EXPERIMENTS.WEB_SEARCH_ENABLED
  })
  return isWebSearchEnabled
}

export default useWebSearchEnabled
