import { SHOPPING_FEED_FRAGMENT } from './fragments/ShoppingFeedFragment'
import { REDEEM_FLOW_FRAGMENT } from './fragments/RedeemFlowFragment'

// Use GetShoppingFeedQuery type when using this query on useGraphqQL
export const GET_SHOPPING_FEED_QUERY = `query getOffersByCategory($feedType: UI_Shopping_FeedType, $origin: UI_Shopping_FeedType, $isPageLayout: Boolean) {
  uiShopping ${SHOPPING_FEED_FRAGMENT}
}`

// Use GetRedeemFlowQuery type when using this query on useGraphqQL
export const GET_REDEEM_FLOW_QUERY = `query redeemFlow($step: UI_Shopping_RedeemFlowStep, $method: UI_Shopping_RedeemFlowMethod, $points: Int, $consentGranted: Boolean, $nonProfitId: ID, $idempotencyKey: ID) {
  uiShopping ${REDEEM_FLOW_FRAGMENT}
}`
