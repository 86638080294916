import React, { ReactNode } from 'react'
import { RecentSearchItem } from 'shoplist-app/shopping/types/ShoppingPageType'
import clientData from 'lib/client-data'
import useRecentSearches from 'hooks/useRecentSearches'

export type RecentSearchesContextType = {
  recentSearches?: RecentSearchItem[] | null
  addRecentSearch: (searchItem: RecentSearchItem) => void
  removeRecentSearch: (searchItem: RecentSearchItem) => void
}

export const RecentSearchesContext =
  React.createContext<RecentSearchesContextType | null>(null)

export const RecentSearchesProvider = (props: { children?: ReactNode }) => {
  const { encryptedAccountNumber } = clientData
  const { recentSearches, addRecentSearch, removeRecentSearch } =
    useRecentSearches(encryptedAccountNumber)

  return (
    <RecentSearchesContext.Provider
      value={{ recentSearches, addRecentSearch, removeRecentSearch }}
    >
      {props.children}
    </RecentSearchesContext.Provider>
  )
}
