import React, { ReactNode, useContext } from 'react'
import { Link, PAYPAL_THEME } from '@paypalcorp/pp-react'
import { Item } from 'shoplist-app/shopping/types/ShoppingPageType'
import { modalContext } from '../../../../contexts/ModalContext/ModalContext'
import CardContent from './CardContent'
import { getActionProps } from '../../../../lib/getActionProps'
import { useLocatorContext } from '../../../../contexts/locator'
import { LOCATORS } from '../../../../constants/automation'

const { sysColorBackgroundSurface: ppBackgroundWhite } = PAYPAL_THEME

interface Props {
  actions?: Item['actions']
  className?: string
  onClick?: (
    event?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void
  children?: ReactNode
  isTile?: boolean
  backgroundColor?: string
  privacyDisclosureModalHandler?: () => void
  feedbackModalHandler?: () => void
}

export const Card = ({
  className,
  onClick,
  children,
  actions,
  isTile = true,
  backgroundColor = ppBackgroundWhite || '#ffffff',
  privacyDisclosureModalHandler,
  feedbackModalHandler
}: Props) => {
  const context = useContext(modalContext)
  const { getLocatorProps } = useLocatorContext(LOCATORS.LINK)

  const cardClassName = className ? 'card--v2 ' + className : 'card--v2'
  const tileClassName = className
    ? 'card--v2__tile-card ' + className + '__tile-card'
    : 'card--v2__tile-card'

  const action = actions?.[0] ?? {}
  const {
    isLink,
    href,
    target,
    handleLinkClick,
    isButton,
    handleButtonClick,
    handleButtonKeyPress
  } = getActionProps({
    action,
    modalContext: context,
    privacyDisclosureModalHandler,
    feedbackModalHandler,
    onClick
  })

  const linkProps = {
    href,
    target,
    rel: 'noreferrer noopener',
    onClick: handleLinkClick
  }

  const buttonProps = {
    as: 'div',
    role: 'button',
    tabIndex: 0,
    onClick: handleButtonClick,
    onKeyPress: handleButtonKeyPress
  }

  const onClickDefault = () => {
    if (onClick) {
      onClick()
    }
  }

  if (isLink || isButton || onClick) {
    return (
      <Link
        {...getLocatorProps()}
        className={cardClassName}
        theme="v2"
        {...(isLink ? linkProps : buttonProps)}
      >
        <CardContent
          isTile={isTile}
          className={tileClassName}
          backgroundColor={backgroundColor}
        >
          {children}
        </CardContent>
      </Link>
    )
  }

  return (
    <div
      {...getLocatorProps()}
      className={cardClassName}
      onClick={onClickDefault}
    >
      <CardContent
        isTile={isTile}
        className={tileClassName}
        backgroundColor={backgroundColor}
      >
        {children}
      </CardContent>
    </div>
  )
}
