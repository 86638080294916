import React, { useCallback } from 'react'
import { OmniChannelComponent } from '@paypalcorp/shopping-components'
import { SectionType } from 'shoplist-app/shopping/types/ShoppingPageType'
import { Section } from 'types/graphql/fragments/Section'

import { SectionWrapper } from '../Section'
import { getBaseWidgetProps } from '../../../../lib/widget'

export interface OmniChannelComponentSectionProps {
  layoutId: string
  section: Section
  onEmpty: (sectionId: SectionType['sectionId']) => void
  onError: (sectionId: SectionType['sectionId']) => void
}
export type OmniChannelEventTypes = 'EMPTY_ITEMS' | 'EMPTY_SECTION' | 'ERROR'

export const OMNICHANNEL_EVENT_TYPES: {
  [key in OmniChannelEventTypes]: OmniChannelEventTypes
} = {
  EMPTY_ITEMS: 'EMPTY_ITEMS',
  EMPTY_SECTION: 'EMPTY_SECTION',
  ERROR: 'ERROR'
}

const OmniChannelComponentSection = ({
  layoutId,
  section,
  onEmpty,
  onError
}: OmniChannelComponentSectionProps) => {
  const { actions, layout, subtitle, title, sectionId } = section

  const handleEvent = useCallback(
    ({ type }: { type: OmniChannelEventTypes }) => {
      if (
        type === OMNICHANNEL_EVENT_TYPES.EMPTY_SECTION ||
        type === OMNICHANNEL_EVENT_TYPES.ERROR
      ) {
        onError(section.sectionId)
      } else if (type === OMNICHANNEL_EVENT_TYPES.EMPTY_ITEMS) {
        onEmpty(section.sectionId)
      }
    },
    [onEmpty, onError, section]
  )

  return (
    /**
     * TODO: remove SectionType['actions'] and SectionType['title'] casting when RenderSections accept new types
     * https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2717
     */
    <SectionWrapper
      actions={actions as SectionType['actions']}
      subtitle={subtitle}
      title={title as SectionType['title']}
    >
      <OmniChannelComponent
        {...getBaseWidgetProps()}
        layoutId={layoutId}
        sectionId={sectionId}
        // @ts-ignore DTSHOPATMS-2717
        layout={layout}
        onEvent={handleEvent}
      />
    </SectionWrapper>
  )
}

export default OmniChannelComponentSection
