import { IMAGE_FRAGMENT } from './ImageFragment'

export const MODAL_SECTION_FRAGMENT = `{
  title
  description
  icon ${IMAGE_FRAGMENT}
}`

export const MODAL_CONTENT_ACTION_FRAGMENT = `{
  type
  title
  navigationType
  navigationTarget
}`

export const MODAL_CONTENT_FRAGMENT = `{
  title
  description
  primaryImage ${IMAGE_FRAGMENT}
  modalSections ${MODAL_SECTION_FRAGMENT}
  actions ${MODAL_CONTENT_ACTION_FRAGMENT}
}`
