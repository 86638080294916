import { useState } from 'react'
import type { Suggestion } from '@paypalcorp/pp-react'
import {
  AutocompletePPStore,
  fetchAutoCompleteSearchResults
} from '../views/SearchHoney/fetchSearchSuggestions'
import clientData from 'lib/client-data'

export type StoreSuggestion = Suggestion & { storeUrl: string | null }

const useGetHoneyAutocomplete = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [suggestions, setSuggestions] = useState<AutocompletePPStore[]>(
    clientData?.searchStores?.slice(0, 5) || []
  )
  const [error, setError] = useState<Error | undefined>()
  const [isEmptyResults, setIsEmptyResults] = useState<boolean>(false)
  const abortController = new AbortController()
  const count = 5

  const fetchSuggestions = async (input: string) => {
    try {
      setIsLoading(true)

      const suggestionList: AutocompletePPStore[] =
        await fetchAutoCompleteSearchResults(input, count, abortController)
      setSuggestions(suggestionList)
      setIsEmptyResults(input !== '' && suggestionList.length === 0)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    fetchSuggestions,
    isLoading,
    isEmptyResults,
    suggestions,
    error
  }
}

export default useGetHoneyAutocomplete
