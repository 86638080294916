import { ItemType } from '../types/Carousel'
import { sendPStudioTrackingEvent } from './send-pstudio-tracking'
import {
  SHOPPING_HOME_MODULE_TILE_PRESSED_V2,
  IS_GIFT_CARD_FPTI_EVENT,
  IS_INFORMATION_FEEDBACK_FPTI_EVENT,
  IS_CATEGORY_FPTI_EVENT,
  IS_COLLECTION_FPTI_EVENT,
  IS_HYBRID_OFFER_DEALS_OR_STORES_FPTI_EVENTS
} from '../../../lib/client-constants'
import { get } from 'lodash'
import { FptiDataType, sendFptiClick } from '../../../lib/fpti-pa'

// when user clicks on a specific offer inside the carousel
const getPayloadForFpti = (deals: ItemType, bannerType: string = '') => {
  const { rank, title, decryptedOfferProgramId, itemId } = deals
  const merchant_id: string = get(deals, 'storeIds[0]', '')
  const merchant_name: string = title || ''
  const cashback_amt: string = get(deals, 'rewardsPointsLabel') || ''
  const cashback_enabled = !!cashback_amt
  const offer_program_id: string = decryptedOfferProgramId || ''
  const store_id: string = get(deals, 'storeIds[1]') || ''
  const link_name = get(deals, 'actions[0].title') || ''
  const message_id: string = get(deals, 'itemId') || ''
  const intent_type: string = get(deals, 'itemType') || ''

  let payload: FptiDataType = {
    event_name: SHOPPING_HOME_MODULE_TILE_PRESSED_V2,
    banner_type: bannerType,
    rank
  }

  if (IS_HYBRID_OFFER_DEALS_OR_STORES_FPTI_EVENTS.includes(bannerType)) {
    const offerOrStorePayload = {
      merchant_id,
      merchant_name,
      cashback_amt,
      cashback_enabled,
      offer_program_id,
      store_id
    }
    payload = {
      ...payload,
      ...offerOrStorePayload
    }
  }
  if (IS_GIFT_CARD_FPTI_EVENT.includes(bannerType)) {
    const giftCardPayload = {
      merchant_id,
      merchant_name
    }
    payload = {
      ...payload,
      ...giftCardPayload
    }
  }
  if (IS_INFORMATION_FEEDBACK_FPTI_EVENT.includes(bannerType)) {
    const informationFeedbackPayload = {
      link_name,
      message_id,
      intent_type
    }
    payload = {
      ...payload,
      ...informationFeedbackPayload
    }
  }
  if (IS_COLLECTION_FPTI_EVENT.includes(bannerType)) {
    const collectionsPayload = {
      theme: get(deals, 'title', ''),
      message_id
    }
    payload = {
      ...payload,
      ...collectionsPayload
    }
  }
  if (IS_CATEGORY_FPTI_EVENT.includes(bannerType)) {
    const categoryPayload = {
      category: itemId || ''
    }
    payload = {
      ...payload,
      ...categoryPayload
    }
  }

  return payload
}
export const sendCarouselDealClick = (
  deals: ItemType,
  bannerType: string = ''
) => {
  const { trackingDetails } = deals
  const payload = getPayloadForFpti(deals, bannerType)
  sendFptiClick(payload)
  if (trackingDetails && trackingDetails.clickUrl) {
    const clickUrl = trackingDetails.clickUrl
    sendPStudioTrackingEvent(clickUrl, 'click', `CTA for ${bannerType} section`)
  }
}
