import { datadogRum } from '@datadog/browser-rum'
import beaverLogger from './lib/beaverLogger'
import { getCounterPayload } from '../metrics'
import { MetricName } from '../types/metric'
import cloneError from './lib/cloneError'
import getEventMessage from './lib/getEventMessage'

export type EventType = 'error' | 'warn' | 'info' | 'debug' | 'metric'
export type CaptureContext = object

const createCaptureEvent = (eventType: EventType) => {
  return (event: unknown, captureContext: CaptureContext = {}) => {
    const context = {
      ...captureContext,
      eventType
    }

    const eventMessage = getEventMessage(event)

    if (!eventMessage) {
      return
    }

    if (eventType === 'error' || event instanceof Error) {
      const error = cloneError(event, eventMessage)
      datadogRum.addError(error, context)
      beaverLogger.metric('error', getCounterPayload(MetricName.error))
    }

    beaverLogger[eventType](event, captureContext)
    datadogRum.addError(event, context)
  }
}

const createLogger = () => ({
  error: createCaptureEvent('error'),
  warn: createCaptureEvent('warn'),
  info: createCaptureEvent('info'),
  debug: createCaptureEvent('debug'),
  metric: createCaptureEvent('metric')
})

export default createLogger()
