import { ACTION_FRAGMENT } from './ActionFragment'
import { ITEM_FRAGMENT } from './ItemFragments'
import { TRACKING_DETAILS_FRAGMENT } from './TrackingDetailsFragment'
import { HTML_FRAGMENT } from './HtmlFragment'

export const SECTION_LAYOUT_FRAGMENT = `{
  containerType
  itemType
}`

export const SECTION_FRAGMENT = `{
  actions ${ACTION_FRAGMENT}
  collectionId
  incentiveType
  items ${ITEM_FRAGMENT}
  layout ${SECTION_LAYOUT_FRAGMENT}
  rank
  sectionId
  slotId
  subtitle
  htmlSubtitle ${HTML_FRAGMENT}
  title
  trackingDetails ${TRACKING_DETAILS_FRAGMENT}
  touchpointId
}`
