import { getHoneyAPIUrl } from 'lib/getHoneyAPIUrl'
import { HoneyOperationName } from 'types/HoneyOperationName'
import { GraphQLResponse } from 'shoplist-app/shared/types'

type CheckoutState =
  | 'COMING_SOON'
  | 'COUPON_ONLY'
  | 'HEALTH'
  | 'UNAVAILABLE'
  | 'UNSUPPORTED'

interface CurrentDoubleCashback {
  activeNow: boolean
}
export interface AutocompletePPStore {
  // This is somewhat normalized since there is not a consistent definition of store
  checkoutState: CheckoutState
  country: string
  currentCashbackOffer: {
    active: boolean
    fixedRatePercent: number
  } | null
  currentDoubleCashbackOffer: CurrentDoubleCashback | null
  encryptedMerchantId: string | null
  searchEncryptedMerchantId: string | null
  logoUrl: string | null
  name: string
  publicCouponCount: number | null
  searchBuyNowPayLaterDescription: string | null // this is renamed from buyNowPayLaterDescription
  searchBuyNowPayLaterEligible: boolean | null // renamed from buyNowPayLaterEligible
  storeId: string
  storeUrl: string | null
}
export interface AutocompleteStoreSearchResponse {
  autocomplete_pp: {
    stores: AutocompletePPStore[]
  }
}

export async function fetchAutoCompleteSearchResults(
  input: string,
  count: number,
  abortController: AbortController
): Promise<AutocompletePPStore[]> {
  const searchUrl = getHoneyAPIUrl({
    variables: {
      count, // see https://github.paypal.com/Muse-R/shoplistnodeweb/blob/495345552642565fb12d28b37ea56647e26fbef8/server/src/controllers/search.js#L41
      query: input,
      storeCountry: 'US'
    },
    operationName: HoneyOperationName.SearchAutoComplete
  })

  const response = await fetch(searchUrl, { signal: abortController.signal })
  const data =
    (await response.json()) as GraphQLResponse<AutocompleteStoreSearchResponse>
  return data?.data?.autocomplete_pp?.stores || []
}
