import React, { useContext } from 'react'
import { Button } from '@paypalcorp/pp-react'
import { get } from 'lodash'
import { getActionProps } from '../../../../../lib/getActionProps'
import { modalContext } from '../../../../../contexts/ModalContext/ModalContext'
import {
  Action,
  ActionHandler,
  HandleClick,
  Item
} from '../../../types/ShoppingPageType'
import { createLocatorProps } from '../../../../../contexts/locator'
import { LOCATORS } from '../../../../../constants/automation'

interface ButtonProps {
  size?: 'sm' | 'lg'
  inverse?: boolean
  secondary?: boolean
  branded?: boolean
  tertiary?: boolean
  icon?: boolean
  btnState?: 'success' | 'processing' | 'initial'
  className?: string
}

interface Props extends ButtonProps {
  actions: Item['actions']
  feedbackModalHandler?: ActionHandler
  privacyDisclosureModalHandler?: ActionHandler
  saveToWalletHandler?: ActionHandler
  dismissHandler?: ActionHandler
  onClick?: HandleClick
}

const ActionButton = ({
  size,
  inverse,
  secondary,
  branded,
  tertiary,
  icon,
  btnState,
  className,
  actions,
  feedbackModalHandler,
  privacyDisclosureModalHandler,
  saveToWalletHandler,
  dismissHandler,
  onClick
}: Props) => {
  const context = useContext(modalContext)
  const action: Action | null = get(actions, '[0]') || null
  if (!action) return null

  const buttonClassName = className
    ? `action-button ${className}`
    : 'action-button'
  const { title, isLink, href, target, handleLinkClick, handleButtonClick } =
    getActionProps({
      action,
      modalContext: context,
      feedbackModalHandler,
      privacyDisclosureModalHandler,
      saveToWalletHandler,
      dismissHandler,
      onClick
    })

  const linkProps = {
    as: 'a',
    href,
    target,
    onClick: handleLinkClick
  }
  const buttonProps = {
    onClick: handleButtonClick
  }
  return (
    <Button
      {...createLocatorProps(LOCATORS.ACTION_BUTTON)}
      size={size}
      inverse={inverse}
      secondary={secondary}
      branded={branded}
      tertiary={tertiary}
      icon={icon}
      btnState={btnState}
      className={buttonClassName}
      theme="v2"
      {...(isLink ? linkProps : buttonProps)}
    >
      {title}
    </Button>
  )
}

export default ActionButton
