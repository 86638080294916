import clientData, { init as initClientData } from './client-data'
import { sendFptiClick } from './fpti-pa'
import { get } from 'lodash'

export type event = {
  eventData?: {
    pgrp: string
    page: string
    link?: string
    context_id?: string
    offer_category?: string
    fltp?: string
    cust?: string
    entry_point?: string
  }
  mrid?: string
  cust?: string
  merchant_cart_id?: string
  status?: any
  webdocid?: string
  JSONobject?: any
  offer_id?: string | null
  product_id?: string
  sinfo?: any
  fltp?: string
  flow_subtype?: string
  offer_program_id?: string
  offer_status?: string
  incentive_offer_value?: string
  rank?: number | number[]
  gcid?: string
  version?: string | null
  optsel?: string
  entry_point?: string
  is_pref?: string | null
  offer_program_code?: string | null
  banner_type?: string
  count?: number
  honey_installed?: string
  encr_merchant_id?: string
  amt_type?: string
  amount?: string
  offer_count?: string
  active_total_users?: string
  xe?: string
  xt?: string
  event_name?: string
  merchant_name?: string
  category?: string
  referrer?: string
  campaign_id?: string
  item?: any
  state?: string
  module?: string
  merchant_id?: string
  store_id?: string
  dismiss_method?: string
  source?: string
  page_variant?: string
  link_name?: string
  expiry_date?: string
  pageid?: number
  screen_category?: string
  redeem_method?: string
  coord_x?: string
  title?: string
  event_source?: string
}

export type SetupPa = (
  additionalStateName: string,
  eventName: string,
  eventData?: event
) => void

const commonEventInfo = {
  component: 'shoplistnodeweb',
  event_source: 'web'
}

// send impression for module click event
export const trackClick = (trackingClickInfo?: any) => {
  sendFptiClick({
    ...trackingClickInfo,
    ...commonEventInfo
  })
}

// Prevent the App to crash if PAYPAL is not loaded correctly
export const isPayPalAnalyticsLoaded = () =>
  window.hasOwnProperty('PAYPAL') && PAYPAL.hasOwnProperty('analytics')

// PAYPAL.analytics.setup is called lower down in this file. it calls setupComplete when it is done.
// Previously, calling setup() then attempting to use the PAYPA.analytics.instance methods was a race condition.
// This resolves it.
export const ready = new Promise(resolve => {
  if (!isPayPalAnalyticsLoaded()) return resolve(undefined)
  PAYPAL.analytics.setupComplete = resolve
  return undefined
})

export const setupPa: SetupPa = (additionalStateName, eventName, eventData) => {
  initClientData()
  if (isPayPalAnalyticsLoaded()) {
    const options = {
      data: {
        page: `main:walletweb:pp-shopping::main::${additionalStateName}`,
        pgrp: 'main:walletweb:pp-shopping::main',
        ccpg: get(window, 'cwContext.ccpg') || '',
        comp: 'shoplistnodeweb',
        product: 'shopping',
        tsrce: 'shoplistnodeweb',
        entry_point: 'other',
        event_name: eventName,
        user_state: clientData.isUserLoggedIn ? 'logged in' : 'logged out',
        cust: clientData.encryptedAccountNumber,
        ...eventData
      }
    }
    PAYPAL.analytics.setup(options)
  }
}
