export const PAGE_VIEW = 'im'
export const CLICK = 'cl'
export const WEB = 'web'
export const AVAILABLE_TO_CONSUME = 'av'
export const DESKTOP = 'desktop'
export const MOBILE = 'mobile'

// flows ramped by elmo experiment
export const SHOPLIST_FLOW_WITHOUT_DEALS = 'Trmt_Shoplist_elmo_experiment_1'
export const SHOPLIST_FLOW_WITH_DEALS = 'Trmt_Shoplist_elmo_experiment_2'
export const HOLIDAY_DEALS_FLOW = 'Trmt_Shoplist_elmo_experiment_3'
export const HOLIDAY_DEALS_FLOW_WITH_PFR = 'Trmt_Shoplist_elmo_experiment_4'

// shopping 1.1 instrumentation variables
export const SHOPPING_HOME_SCREEN_SHOWN = 'shopping_home_screen_shown'
export const SHOPPING_HOME_MODULE_RENDERED = 'shopping_home_module_rendered'
export const SHOPPING_HOME_MODULE_SHOWN = 'shopping_home_module_shown'

// Use shorter flow name in FPTI page name
export const FLOW_NAME_MAPPING = {
  [SHOPLIST_FLOW_WITHOUT_DEALS]: 'no-deal',
  [SHOPLIST_FLOW_WITH_DEALS]: 'personalized-deals',
  [HOLIDAY_DEALS_FLOW]: 'holiday-deals',
  [HOLIDAY_DEALS_FLOW_WITH_PFR]: 'pfr-deals'
}
export const DEFAULT_MERCHANT_LOGO =
  'https://www.paypalobjects.com/muse/img/honey/honey-default-card-logo.png'
export const CRITICAL_ICON =
  'https://www.paypalobjects.com/paypal-ui/illustrations/svg/critical.svg'
export const EMPTY_CRITICAL_ICON =
  'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-critical.svg'

export const EMPTY_RESULT_IMAGE =
  'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-magnifying-glass-white.svg'

export const REDEMPTION_NO_POINTS_IMAGE =
  'https://www.paypalobjects.com/paypal-ui/pictograms/grayscale/svg/grayscale-3.svg'

export const PRE_HOLIDAY = 'pre-holiday'
export const PEAK_DEALS = 'peak-deals'
export const LAST_CHANCE = 'last-chance'
export const NO_CAMPAIGN = 'no-campaign'

export const TIME_Nov25_MIDNIGHT_PST = new Date('November 25,2019 07:00:00')
export const TIME_DEC7_MIDNIGHT_PST = new Date('December 7,2019 07:00:00')
export const TIME_JAN1_MIDNIGHT_PST = new Date('January 1,2019 07:00:00')

// Feedback fpti variable names
export const SHOPPING_MODULE = 'module'
export const FPTI_USER_STATE_LOGGED_IN = 'logged_in'
export const FPTI_USER_STATE_LOGGED_OUT = 'logged_out'
export const FPTI_PRODUCT = 'shopping'

export const MAX_SMALL_MOBILE_WIDTH = 374
export const MAX_MOBILE_WIDTH = 751
export const MAX_TABLET_WIDTH = 1023
export const MAX_DESKTOP_WIDTH = 1439
export const MAX_WIDTH_FOR_MOBILE_ASSETS = 480
export const MAX_WIDTH_FOR_CAROUSEL = 1320
export const MIN_LARGE_DESKTOP_WIDTH = 1440
export const SMALL_MOBILE_PADDING = 8
export const MOBILE_PADDING = 16
export const TABLET_PADDING = 45

export const FEEDBACK_MODULE = 'feedback'

// Rewards fpti variable names
export const DASHBOARD_MODULE = 'dashboard'
export const SHOPPING_CASHBACK_TERMS_SCREEN_SHOWN =
  'shopping_cashback_terms_screen_shown'
export const SHOPPING_CASHBACK_FAQ_ACCTMERGE_SHOWN =
  'shopping_cashback_faq_acctmerge_shown'

// Omnichannel FPTI
export const SHOPPING_FAILED_CUSTOMER_INTERACTION_OCCURRED =
  'shopping_failed_customer_interaction_occurred'
export const SHOPPING_FAILED_CUSTOMER_INTERACTION_RETRY_PRESSED =
  'shopping_failed_customer_interaction_retry_pressed'
export const SHOPPING_SECONDARY_NAV_EXPLORE_PRESSED =
  'shopping_secondary_nav_explore_pressed'
export const SHOPPING_SECONDARY_NAV_SAVED_PRESSED =
  'shopping_secondary_nav_saved_pressed'
export const SHOPPING_SECONDARY_NAV_SEARCH_PRESSED =
  'shopping_secondary_nav_search_pressed'
export const SHOPPING_OMNI_CHANNEL_COMPONENT_ADD_PRESSED =
  'shopping_omni_channel_component_add_pressed'

// Shopping CDNX icons
export const ICON_HONEY =
  'https://www.paypalobjects.com/shopping/icons/small_honey.svg'
export const ICON_CHECK_GREEN_CIRCLE =
  'https://www.paypalobjects.com/shopping/icons/check_green_circle.svg'
export const ICON_CHECK_CIRCLE =
  'https://www.paypalobjects.com/shopping/icons/check-circle.svg'

export const GOLD = 'https://www.paypalobjects.com/shopping/icons/gold.svg'

// V3 Icons
export const ICON_V3_HOUR_GLASS =
  'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/out-of-time-hourglass.svg'

// Search store and Deals
export const NEW_SEARCH_STORE_AND_DEALS = '/offers/search?keyword='
export const SHOPPING_HOME_SEARCH_PRESSED = 'shopping_home_search_pressed'
export const SHOPPING_HOME_SEARCH_CANCEL_CLICKED =
  'shopping_home_search_cancel_pressed'
export const SHOPPING_HOME_SEARCH_TYPING_STARTED =
  'shopping_home_search_typing_started'

//ShoppingPage
export const SHOPPING_PAGE = '/offers'
export const PAYPAL_ERROR_PAGE = 'https://www.paypal.com/error'

//Popular search page
export const POPULAR_SEARCH_PAGE = '/offers/popular-searches'

//Store Search
export const POPULAR_SEARCHES = 'popular_searches'
export const RECENT_SEARCHES = 'recent_searches'
export const SEE_ALL = 'see_all'
export const DEALS = 'deals'

export const PP_SHOPPING_EXCLUSIVE_OFFERS_AVAILABLE =
  'ppshopping_available_offers'
export const PP_SHOPPING_EXCLUSIVE_OFFERS_SAVED = 'ppshopping_saved_offers'
export const PPSHOPPING_CATEGORY = 'ppshopping_category'
export const PPSHOPPING_NAV_CATEGORY = 'ppshopping_all_categories'

export const SEARCH_RESULT_PAGE = 'search_result_page'
export const SEARCH_RESULT_SCREEN_SHOWN = 'shopping_search_result_screen_shown'
export const SEARCH_RESULT_SEARCH_TYPING = 'shopping_home_offer_search_pressed'
export const SEARCH_RESULT_SEARCH_CLICKED =
  'shopping_search_result_search_pressed'
export const SEARCH_RESULT_SEARCH_CANCEL_CLICKED =
  'shopping_search_result_search_cancel_pressed'
export const SEARCH_RESULT_DISMISS_CLICKED =
  'shopping_search_result_dismiss_pressed'
export const SEARCH_RESULT_RETURNED_MODULE_SHOWN =
  'shopping_search_result_returned_module_shown'
export const SEARCH_RESULT_RETURNED_TILE_SHOWN =
  'shopping_search_result_returned_tile_shown'
export const SEARCH_RESULT_RETURNED_TILE_PRESSED =
  'shopping_search_result_returned_tile_pressed'
export const SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN =
  'shopping_search_result_default_module_shown'
export const SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_PRESSED =
  'shopping_search_result_default_module_pressed'
export const SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN =
  'shopping_search_result_default_tile_shown'
export const SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED =
  'shopping_search_result_default_tile_pressed'
export const SHOPPING_SEARCH_AUTOCOMPLETE_SCREEN_SHOWN =
  'shopping_search_autocomplete_screen_shown'
export const SHOPPING_SEARCH_AUTOCOMPLETE_TILE_SHOWN =
  'shopping_search_autocomplete_tile_shown'
export const SHOPPING_SEARCH_AUTOCOMPLETE_TILE_PRESSED =
  'shopping_search_autocomplete_tile_pressed'
export const SHOPPING_SEARCH_RESULT_SEARCH_TYPING_STARTED =
  'shopping_search_result_search_typing_started'
export const SHOPPING_SEARCH_RESULT_SEARCH_TYPING_ENDED =
  'shopping_search_result_search_typing_ended'
export const SEARCH_SUBPAGE_SCREEN_SHOWN =
  'shopping_search_subpage_screen_shown'
export const SEARCH_SUBPAGE_SELECTION_PRESSED =
  'shopping_search_subpage_selection_pressed'
export const SEARCH_SUBPAGE_SELECTION_SHOWN =
  'shopping_search_subpage_selection_shown'

export const OFFER_DETAIL_SCREEN_SHOWN = 'shopping_offer_detail_screen_shown'
export const OFFER_DETAIL_CTA_CLICKED = 'shopping_offer_detail_cta_pressed'
export const OFFER_DETAIL_DISMISS_CLICKED =
  'shopping_offer_detail_dismiss_pressed'

// Search and Shopping FPTI variable name
export const SHOPPING_DETAIL_SCREEN_SHOWN = 'shopping_search_screen_shown'
export const SHOPPING_SEARCH_SPINNER_SHOWN = 'shopping_search_spinner_shown'
export const SHOPPING_SEARCH_RESULT_DETAILS_PRESSED =
  'shopping_search_results_detail_pressed'

export const REDEEM_FLOW_SCREENS = {
  REDEMPTION_METHOD: 'REDEMPTION_METHOD',
  REDEMPTION_CHOOSE_AMOUNT: 'REDEMPTION_CHOOSE_AMOUNT',
  REDEMPTION_CONFIRMED: 'REDEMPTION_CONFIRMED',
  REDEMPTION_TERMS: 'REDEMPTION_TERMS'
}

export const IS_OFFER_DEALS_FPTI_EVENTS = [
  'ppshopping_featured_deals',
  'ppshopping_available_offers',
  'ppshopping_hero_offer',
  'ppshopping_new_deals',
  'ppshopping_expiring_deals',
  'ppshopping_honey_featured_deal',
  'ppshopping_recommended_deals',
  'ppshopping_buy_now_pay_later',
  'ppshopping_gold_balance',
  'ppshopping_saved_offers',
  'ppshopping_hybrid_offers'
]
export const IS_INFORMATION_FEEDBACK_FPTI_EVENT = [
  'engagement_card',
  'gold_balance',
  'ppshopping_informational_touchpoint',
  'ppshopping_feedback',
  'ppshopping_privacy',
  'ppshopping_loyaltycardentry',
  'ppshopping_login_banner',
  'ppshopping_notificationentry'
]
export const IS_GIFT_CARD_FPTI_EVENT = 'ppshopping_gift_card'
export const IS_REWARDS_STORE_FPTI_EVENT = 'REWARDS_STORE'
export const IS_COLLECTION_FPTI_EVENT = 'ppshopping_collections'
export const IS_CATEGORY_FPTI_EVENT = [
  PPSHOPPING_CATEGORY,
  PPSHOPPING_NAV_CATEGORY
]

// Touchpoints IDs
export const HYBRID_OFFERS_TOUCHPOINT_ID = 'ppshopping_hybrid_offers'

export const SHOPPING_HOME_MODULE_TILE_PRESSED_V2 =
  'shopping_home_module_tile_pressed'
export const PP_SHOPPING_STORES_FOR_HUB = 'ppshopping_stores_for_hub'

// Store Details - Offer details modal - query parameters keys
export const HONEY_OFFER_ID_QUERY_KEY = 'honeyOfferId'
export const HONEY_STORE_ID_QUERY_KEY = 'honeyStoreId'
export const PP_SHOPPING_STORES_FPTI = [
  'ppshopping_trending_coupons',
  'ppshopping_stores_for_hub',
  'ppshopping_doublegold_stores_for_hub'
]

export const IS_HYBRID_OFFER_DEALS_OR_STORES_FPTI_EVENTS = [
  ...IS_OFFER_DEALS_FPTI_EVENTS,
  ...PP_SHOPPING_STORES_FPTI,
  HYBRID_OFFERS_TOUCHPOINT_ID
]

// Store Details FPTI
export const SHOPPING_STORE_DETAIL_PAGE = 'shopping_store_detail_page'
export const SHOPPING_STORE_SCREEN_SHOWN = 'shopping_store_screen_shown'

export const SHOPPING_CARD_LIST_LIST_BACK_PRESSED =
  'shopping_card_list_back_pressed'
export const SHOPPING_CARD_LIST_SCREEN_SCROLLED =
  'shopping_card_list_screen_scrolled'

export const SHOPPING_COLLECTION_DISMISS_PRESSED =
  'shopping_collection_dismiss_pressed'
export const SHOPPING_COLLECTION_SCREEN_SCROLLED =
  'shopping_collection_screen_scrolled'

export const SHOPPING_STORE_TAB_PRESSED = 'shopping_store_tab_pressed'
export const SHOPPING_STORE_EXPAND_PRESSED = 'shopping_store_expand_pressed'
export const SHOPPING_STORE_ITEM_SHOWN = 'shopping_store_item_shown'
export const SHOPPING_STORE_ITEM_PRESSED = 'shopping_store_item_pressed'
export const SHOPPING_STORE_DISMISS_PRESSED = 'shopping_store_dismiss_pressed'
export const SHOPPING_STORE_SHOP_PRESSED = 'shopping_store_shop_pressed'

// Offer Details FPTI
export const SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN =
  'shopping_offer_deal_detail_screen_shown'
export const SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED =
  'shopping_offer_deal_detail_button_pressed'
export const SHOPPING_OFFER_DEAL_DETAIL_ACTION_OCCURRED =
  'shopping_offer_deal_detail_action_occurred'
export const SHOPPING_OFFER_DEAL_DETAIL_DISMISS_PRESSED =
  'shopping_offer_deal_detail_dismiss_pressed'

// Reward Points Feed main content constants
export const PENDING_POINTS_AMOUNT = 'PENDING_POINTS_AMOUNT'
export const LIFETIME_POINTS_AMOUNT = 'LIFETIME_POINTS_AMOUNT'

// Redeem Touchpoints
export const PENDING_ACTIVITY = 'ppshopping_pending_activity'
export const COMPLETED_ACTIVITY = 'ppshopping_completed_activity'
export const LOADING = 'lazy'
// Modal Context
export const MODAL_OPEN_STATUS = 'OPEN'
export const MODAL_OPEN_SAVE_STATUS = 'OPEN_SAVE'
export const MODAL_OFFER_SAVED_STATUS = 'OFFER_SAVED'
export const MODAL_CLOSED_STATUS = 'CLOSED'

export const MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION = 'OPEN_OFFER_DETAILS'
export const MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION =
  'OPEN_SAVE_OFFER_DETAILS'
export const MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION =
  'SAVE_OFFER_DETAILS'
export const MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION = 'CLOSE_OFFER_DETAILS'
export const MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION =
  'OPEN_CASHBACK_OFFER_DETAILS'
export const MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_SAVE_ACTION =
  'OPEN_SAVE_CASHBACK_OFFER_DETAILS'
export const MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_SAVE_OFFER_ACTION =
  'SAVE_CASHBACK_OFFER_DETAILS'
export const MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_CLOSE_ACTION =
  'CLOSE_CASHBACK_OFFER_DETAILS'
export const MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION =
  'OPEN_GOLD_STORE_TERMS'
export const MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION =
  'CLOSE_GOLD_STORE_TERMS'

// Query Param Constants (For SPNS strings in URL query format)
export const OFFER_PROGRAM_ID = 'offerProgramId'
export const STORE_ID = 'storeId'
export const CAMPAIGN_ID = 'campaignId'

//Time constants
export const SECOND_IN_MILLISECONDS = 1000
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * 60

export const ITEM_TYPE_HYBRID_OFFER = 'HYBRID_OFFER'

/**
 * Possible values of section.layout.layoutType
 */
export const LAYOUT_CONTAINER_TYPES = {
  CAROUSEL: 'CAROUSEL',
  IMAGE_BANNER: 'IMAGE_BANNER',
  IMAGE_BANNER_CAROUSEL: 'IMAGE_BANNER_CAROUSEL',
  LIST: 'LIST',
  PAGE_TITLE: 'PAGE_TITLE',
  NONE: 'NONE'
}

// FPTI V3
export const SAVED_OFFERS_PAGE_MODULE = 'saved'
export const SAVED_OFFERS_PAGE_SCREEN_SHOWN = 'shopping_saved_screen_shown'
export const EXPLORE_TAB_SOURCE = 'explore_tab'
export const SAVED_TAB_SOURCE = 'saved_tab'
export const TXN_EMAIL_SOURCE = 'txn_email'
export const EMAIL_LANDING_SCREEN = 'email_landing'
export const SHOPPING_EXPLORE_SCREEN_SHOWN = 'shopping_explore_screen_shown'
export const SHOPPING_SAVED_SCREEN_SHOWN = 'shopping_saved_screen_shown'

// Honey
export const BASE_HONEY_URL = 'https://d.joinhoney.com/v3'

// PayPal
export const PP_REWARDS_TERMS_LINK =
  'https://www.paypal.com/us/webapps/mpp/ua/pp-rewards-program-tnc'
export const PAY_LATER_NM_DISCLOSURES_URL =
  'https://www.paypal.com/us/newmexicodisclosure'
