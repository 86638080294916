import { useCallback, useContext, useMemo } from 'react'
import compact from 'lodash/compact'

import LocatorContext from '../context/LocatorContext'
import createLocatorProps from '../utils/createLocatorProps'

const useLocatorContext = (...elementLocator: string[]) => {
  const stringifiedElementLocators = elementLocator.join('__')
  const context = useContext(LocatorContext)
  const getLocator = useCallback(
    (...locators) => {
      if (!context) {
        return ''
      }
      return compact([context, stringifiedElementLocators, ...locators]).join(
        '__'
      )
    },
    [context, stringifiedElementLocators]
  )

  const getLocatorProps = useCallback(
    (...locators) => createLocatorProps(getLocator(...locators)),
    [getLocator]
  )

  return useMemo(
    () => ({
      context,
      getLocator,
      getLocatorProps
    }),
    [context, getLocator, getLocatorProps]
  )
}

export default useLocatorContext
