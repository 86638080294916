import React from 'react'
import ReactDOM from 'react-dom'

export const initA11yLinting = async () => {
  if (process.env.NODE_ENV === 'development' && APP_REACT_AXE) {
    try {
      // eslint-disable-next-line import/no-extraneous-dependencies
      const { default: axe } = await import('@axe-core/react/dist/index.js')
      axe(React, ReactDOM, 1000)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
}
