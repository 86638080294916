import React, { useState, useEffect } from 'react'
import { get } from 'lodash'

const useObserver = (
  /**
    This hook allows you to use the intersection observer for simple 
    situations where you want to run an event on visibility
    
    Setup requires only three steps in your component
    1. const containerRef = useRef<HTMLDivElement>(null)
    2. useObserver(containerRef, onVisible, {useOnce: true})
    3. set ref={containerRef} on the component that you want to observe
  */

  containerRef: React.RefObject<HTMLDivElement>,
  onVisible: () => void,
  useObserverOptions?: {
    intersectionOptions?: IntersectionObserverInit
    useOnce?: boolean
  }
): void => {
  interface ObserverEntry {
    isIntersecting: boolean
  }

  const [isVisible, setIsVisible] = useState(false)
  const [callbackUsed, setCallbackUsed] = useState(false)

  const isVisibleCallback = (entries: ObserverEntry[]) => {
    if (entries.length) {
      const [entry] = entries
      setIsVisible(entry.isIntersecting)
    }
  }

  const defaultOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const options = get(useObserverOptions, 'intersectionOptions', defaultOptions)

  useEffect(() => {
    const currentContainerRef = containerRef.current

    const observer = new IntersectionObserver(isVisibleCallback, options)
    if (currentContainerRef) observer.observe(currentContainerRef)

    return () => {
      if (currentContainerRef) observer.unobserve(currentContainerRef)
    }
  }, [containerRef, options])

  useEffect(() => {
    if (isVisible && !callbackUsed) {
      onVisible()
      if (get(useObserverOptions, 'useOnce', true)) {
        setCallbackUsed(true)
      }
    }
  }, [callbackUsed, isVisible, onVisible, useObserverOptions])
}

export default useObserver
