import React, { ReactEventHandler, useState, useEffect } from 'react'
import { get } from 'lodash'
import {
  BodyText,
  HeadingText,
  IconButton,
  Link,
  ArrowLeftIcon
} from '@paypalcorp/pp-react'

import { useLocatorContext, withLocatorContext } from 'contexts/locator'
import { LOCATORS } from 'constants/automation'

import { Item, GoldStore } from '../../../types/ShoppingPageType'
import ResponsiveModal from '../ResponsiveModal/ResponsiveModal'
import ModalLoadingSpinner from '../ModalLoadingSpinner/ModalLoadingSpinner'
import ModalFooter from '../ModalFooter/ModalFooter'
import { useGoldStoreTermsModalData } from '../../../../../hooks/useGoldStoreTermsModalData'
import ModalStoreLogo from '../ModalStoreLogo/ModalStoreLogo'
import ActionButton from '../ActionButton/ActionButton'
import {
  sendFptiClick,
  sendFptiImpression,
  FptiDataType
} from '../../../../../lib/fpti-pa'
import {
  SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED,
  SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN
} from '../../../../../lib/client-constants'
import InlineTextButton from '../../../../../components/InlineTextButton'
import useWorldReady from 'hooks/useWorldReady'

type TermsAndConditions = { title: string; content: string } | null | undefined

export type GoldStoreTermsModalProps = {
  isOpen: boolean
  onClose: ReactEventHandler<Element>
  storeId: string | null
  fptiSource: string
}
interface ModalContentProps {
  isVisible?: boolean
  store: GoldStore | null
  termsAndConditions: TermsAndConditions
  setTermsAndConditions: Function
}
interface GoldStoreTermsModalFooterProps {
  isVisible: boolean
  store: GoldStore | null
  fptiSource: string
}

interface DoubleGoldLabelProps {
  isVisible: boolean
  content?: string
}

interface HowItWorksProps {
  store: GoldStore
  setTermsAndConditions: Function
}
interface FptiEventType {
  store: GoldStore
  source: string
}

interface FptiActionEventType extends FptiEventType {
  linkName: FptiDataType['link_name']
}

const getFptiDefaultProps = ({
  store,
  source
}: FptiEventType): Pick<
  FptiDataType,
  Exclude<keyof FptiDataType, 'event_name'>
> => {
  return {
    cashback_amt: store.rewardsPointsLabel, // Cashback amount of offer
    cashback_enabled: !!store.rewardsPointsLabel, // Cashback enabled indicator of store or offer
    store_id: get(store, 'storeIds[1]'), // Honey store id
    merchant_id: get(store, 'storeIds[0]'),
    merchant_name: store.title,
    category: store.shoppingCategories,
    offer_type: 'affiliate',
    click_source: source
  }
}
const handleFptiOnActionClick = ({
  store,
  linkName,
  source
}: FptiActionEventType) => {
  sendFptiClick({
    event_name: SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED,
    link_name: linkName,
    ...getFptiDefaultProps({ store, source })
  })
}

const handleFptiOnImpression = ({ store, source }: FptiEventType) => {
  sendFptiImpression({
    event_name: SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN,
    ...getFptiDefaultProps({ store, source })
  })
}

const HowItWorks = ({ store, setTermsAndConditions }: HowItWorksProps) => {
  const { getLocatorProps } = useLocatorContext()

  if (!store || !store.howRewardPointsWork) return null

  const classes = {
    subTitleImage: 'gold-store-terms-modal__how-it-works__subtitle-image',
    howItWorksImage: 'gold-store-terms-modal__how-it-works__image',
    boldText: 'gold-store-terms-modal__how-it-works__boldText',
    howItWorksText: 'gold-store-terms-modal__how-it-works__text'
  }

  const anchorTagRegex = new RegExp(/<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/)

  const hasInlineLinks = (text: string) => {
    return anchorTagRegex.test(text)
  }

  const getHrefFromText = (text: string) => {
    // Using the DOM API to get href value
    const container = document.createElement('div')
    container.innerHTML = text
    const anchorTag = container.querySelector('a')
    return anchorTag ? anchorTag.getAttribute('href') : ''
  }

  const renderWithInlineLinks = (
    text: string,
    termsAndConditionsSection: TermsAndConditions
  ) => {
    const matchingAnchorTagRegex = text.match(anchorTagRegex)
    const innerText = matchingAnchorTagRegex ? matchingAnchorTagRegex[2] : ''
    const outerText = text.replace(anchorTagRegex, '')
    return (
      <>
        {outerText}
        {termsAndConditionsSection && text.includes('data-terms-exclusions') ? (
          <InlineTextButton
            {...getLocatorProps(LOCATORS.EXCLUSIONS_LINK)}
            onClick={() => setTermsAndConditions(termsAndConditionsSection)}
          >
            {innerText}
          </InlineTextButton>
        ) : (
          <Link theme="v2" href={getHrefFromText(text)} target="_blank">
            {innerText}
          </Link>
        )}
      </>
    )
  }

  return (
    <>
      {store.howRewardPointsWork.items.map(
        (
          {
            primaryImage,
            title,
            subtitle,
            description,
            termsAndConditionsSection
          }: Item,
          index: number
        ) => {
          const imageStyle =
            subtitle !== null ? classes.subTitleImage : classes.howItWorksImage

          const titleStyle =
            subtitle !== null ? classes.boldText : classes.howItWorksText

          return (
            <div key={index} className="gold-store-terms-modal__how-it-works">
              <img
                className={imageStyle}
                src={primaryImage ? primaryImage.url : ''}
                aria-label={get(primaryImage, 'accessibilityLabel', 'logo')}
              />

              <BodyText className={titleStyle}>
                {title && hasInlineLinks(title)
                  ? renderWithInlineLinks(title, termsAndConditionsSection)
                  : title}

                {subtitle && (
                  <span className="gold-store-terms-modal__how-it-works__subTitle">
                    {subtitle}
                  </span>
                )}
              </BodyText>

              {description && (
                <BodyText className="gold-store-terms-modal__how-it-works__description">
                  {description}
                </BodyText>
              )}
            </div>
          )
        }
      )}
    </>
  )
}

const DoubleGoldLabel = ({
  isVisible = false,
  content
}: DoubleGoldLabelProps) => {
  if (!isVisible) return null
  return (
    <span className="gold-store-terms-modal__double-gold-label">{content}</span>
  )
}

const ModalContent = ({
  isVisible = true,
  store,
  termsAndConditions,
  setTermsAndConditions
}: ModalContentProps) => {
  if (!isVisible || !store) return null
  return (
    <div
      className="gold-store-terms-modal__container"
      data-cy="gold-store-terms-modal-container"
    >
      {termsAndConditions && (
        <BodyText className="gold-store-terms-modal__terms">
          {termsAndConditions.content}
        </BodyText>
      )}

      {!termsAndConditions && (
        <>
          <ModalStoreLogo logo={store.primaryImage} storeName={store.title} />

          <HeadingText className="gold-store-terms-modal__gold-label">
            {store.rewardsPointsLabel}
            <DoubleGoldLabel
              isVisible={!!store.rewardsPointsSubtitle}
              content={store.rewardsPointsSubtitle}
            />
          </HeadingText>

          <HowItWorks
            store={store}
            setTermsAndConditions={setTermsAndConditions}
          />
        </>
      )}
    </div>
  )
}

const GoldStoreTermsModalFooter = ({
  isVisible = true,
  store,
  fptiSource
}: GoldStoreTermsModalFooterProps) => {
  if (!isVisible || !store || !store.actions) return null

  const actions = store.actions

  const handleButtonClick = () => {
    const linkName = get(actions, '[0].title') || ''
    handleFptiOnActionClick({ store, linkName, source: fptiSource })
  }

  return (
    <ModalFooter>
      <ActionButton actions={actions} onClick={handleButtonClick} />
    </ModalFooter>
  )
}

const GoldStoreTermsModal = ({
  isOpen,
  onClose,
  storeId,
  fptiSource
}: GoldStoreTermsModalProps) => {
  const { formatMessage } = useWorldReady()
  const { getLocatorProps } = useLocatorContext()
  const { fetchAllSections, store, loading, error } =
    useGoldStoreTermsModalData({ storeId })
  const [termsAndConditions, setTermsAndConditions] =
    useState<TermsAndConditions>(null)

  useEffect(() => {
    if (isOpen && storeId) {
      setTermsAndConditions(null)
      fetchAllSections()
    }
  }, [isOpen, storeId, fetchAllSections])

  useEffect(() => {
    if (!loading && store) handleFptiOnImpression({ store, source: fptiSource })
  }, [loading, store, fptiSource])

  const backArrowElement = () => {
    if (termsAndConditions) {
      return (
        <IconButton
          svgIconComponent={ArrowLeftIcon}
          className="gold-store-terms-modal__back"
          buttonType="tertiary"
          size="sm"
          theme="v2"
          onClick={() => setTermsAndConditions(null)}
        />
      )
    }

    return null
  }

  return (
    <ResponsiveModal
      {...getLocatorProps()}
      title={formatMessage('main.shoppingHub.modal.goldStoreTerms.title')}
      action={backArrowElement()}
      containerClassName="gold-store-terms-modal"
      contentClassName="gold-store-terms-modal__content"
      isOpen={isOpen}
      requestClose={onClose}
      closeOnBackgroundClick
      hideTitle
      footerContents={
        <GoldStoreTermsModalFooter
          isVisible={!loading && !error && !termsAndConditions}
          store={store}
          fptiSource={fptiSource}
        />
      }
    >
      <ModalContent
        isVisible={!loading && !error}
        store={store}
        termsAndConditions={termsAndConditions}
        setTermsAndConditions={setTermsAndConditions}
      />
      <ModalLoadingSpinner isVisible={loading} />
    </ResponsiveModal>
  )
}

export default withLocatorContext(LOCATORS.GOLD_STORE_TERMS_MODAL, {
  isRootContext: true
})(GoldStoreTermsModal)
