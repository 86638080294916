import React from 'react'
import { Link } from '@paypalcorp/pp-react'

import './inlineTextButton.scss'

export interface InlineTextButtonProps {
  className?: string
  children: React.ReactNode
  leftIcon?: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

/**
 * Component to use when we want to style some text like a link, but
 * use it as a button, with an onClick prop instead of href
 *
 * Note: Inline text tap targets are not required to be 44x44px
 * https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
 */
const InlineTextButton = ({
  className,
  children,
  leftIcon,
  onClick,
  ...rest
}: InlineTextButtonProps) => {
  return (
    <Link
      as="button"
      className={`inline-text-button ${className || ''}`}
      leftIcon={leftIcon}
      onClick={onClick}
      theme="v2"
      {...rest}
    >
      {children}
    </Link>
  )
}

export default InlineTextButton
