import React, { useState } from 'react'
import { useLocatorContext, withLocatorContext } from 'contexts/locator'

import { LOCATORS } from '../../constants/automation'
import { EXPLORE, SAVED } from '../../constants/link'
import Links from './Links'
import Popover from 'components/Popover'
import SearchPreview from 'components/SearchPreview'
import SearchAutocomplete from 'components/SearchAutocomplete'
import { StoreSuggestion } from 'hooks/useGetHoneyAutocomplete'
import useWorldReady from 'hooks/useWorldReady'
import useWebSearchEnabled from 'hooks/useWebSearchEnabled'
import getAffiliateLink from 'shoplist-app/store/utils/get-affiliate-link'
import clientData from 'lib/client-data'
import sanitizeHtml from 'sanitize-html'
import {
  NEW_SEARCH_STORE_AND_DEALS,
  SHOPPING_SEARCH_RESULT_SEARCH_TYPING_ENDED,
  SHOPPING_SEARCH_RESULT_SEARCH_TYPING_STARTED
} from 'lib/client-constants'
import { sendFptiClick } from 'lib/fpti-pa'

export type SubNavBarV3Props = {
  active: '' | 'explore' | 'savedOffers'
  query?: string
  points?: number
  onSearch?: (searchWord: string) => void
}

const SubNavBarV3 = ({ active, points, query, onSearch }: SubNavBarV3Props) => {
  const { formatMessage } = useWorldReady()
  const { getLocatorProps } = useLocatorContext()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [searchInput, setSearchInput] = useState(query || '')
  const [showNoMatches, setShowNoMatches] = useState(false)
  const [isInputSelected, setIsInputSelected] = useState(false)
  // This boolean is defined to trigger typing started and ended event
  const [typingStarted, setTypingStarted] = useState(false)
  const placeholder = formatMessage('main.subnavigation.search.placeholder')
  const webSearchIsEnabled = useWebSearchEnabled()
  const LINKS = [EXPLORE, SAVED]

  const closePopover = () => {
    setIsPopoverOpen(false)
  }

  const openPopover = () => {
    setIsPopoverOpen(true)
  }

  const dismissPopoverOnEnter = () => {
    setIsInputSelected(false)
    closePopover()
  }

  const handleFPTITypingEndedEvent = () => {
    if (typingStarted) {
      sendFptiClick({
        event_name: SHOPPING_SEARCH_RESULT_SEARCH_TYPING_ENDED
      })
      setTypingStarted(false)
    }
  }

  const handleAutocompleteChange = (input: string) => {
    const hasClearedInput = searchInput !== '' && input === ''
    if (hasClearedInput) {
      openPopover()
      setShowNoMatches(false)
      handleFPTITypingEndedEvent()
    } else if (input !== '' && !typingStarted) {
      sendFptiClick({
        event_name: SHOPPING_SEARCH_RESULT_SEARCH_TYPING_STARTED
      })
      setTypingStarted(true)
    }

    setSearchInput(input)
  }

  const handleAutocompleteSelect = (suggestion: StoreSuggestion) => {
    handleFPTITypingEndedEvent()
    if (suggestion.storeUrl) {
      const affiliateLink = getAffiliateLink(
        suggestion.id,
        clientData.fptiSessionId || '',
        suggestion.storeUrl
      )
      window.open(affiliateLink, '_blank')
    }
  }

  const handleAutocompletePressedEsc = () => {
    handleFPTITypingEndedEvent()
    closePopover()
  }

  const handleAutocompletePressedEnter = (input: string) => {
    handleFPTITypingEndedEvent()
    if (onSearch) {
      onSearch(input)
      dismissPopoverOnEnter()
    } else {
      const cleanKeyword = sanitizeHtml(input)
      const url = `${NEW_SEARCH_STORE_AND_DEALS}${encodeURIComponent(
        cleanKeyword
      )}`
      window.location.href = url
    }
  }

  const handleAutocompleteLoading = (
    isLoading: boolean,
    isEmptyResults: boolean
  ) => {
    if (searchInput !== '') {
      if (isLoading) {
        closePopover()
      } else if (isEmptyResults && isInputSelected) {
        openPopover()
        setShowNoMatches(true)
        handleFPTITypingEndedEvent()
      }
    }
  }

  const handleInputSelect = (selected: boolean) => {
    if (!selected) {
      handleFPTITypingEndedEvent()
    }
    setIsInputSelected(selected)
  }

  return (
    <div {...getLocatorProps()} className="subNavBarV3">
      <nav className="subNavBarV3__container">
        <Links linkItems={LINKS} activeLinkId={active} points={points} />
        {webSearchIsEnabled && (
          <Popover
            isOpen={isPopoverOpen}
            onClose={closePopover}
            onOpen={openPopover}
            searchInput={searchInput}
            onInputSelect={handleInputSelect}
            SearchAutocomplete={
              <SearchAutocomplete
                searchInput={searchInput}
                isInputSelected={isInputSelected}
                placeholder={placeholder}
                onChange={handleAutocompleteChange}
                onSelect={handleAutocompleteSelect}
                onPressedEsc={handleAutocompletePressedEsc}
                onPressedEnter={handleAutocompletePressedEnter}
                onLoading={handleAutocompleteLoading}
              />
            }
          >
            <SearchPreview
              isOpen={isPopoverOpen}
              onStoreClick={closePopover}
              showNoMatches={showNoMatches}
              query={searchInput}
            />
          </Popover>
        )}
      </nav>
    </div>
  )
}

export default withLocatorContext<SubNavBarV3Props>(LOCATORS.SUBNAVIGATION_V3, {
  isRootContext: true
})(SubNavBarV3)
