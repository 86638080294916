import React, { useCallback, useRef, useState } from 'react'
import { get } from 'lodash'
import { Button, PAYPAL_THEME } from '@paypalcorp/pp-react'

import { LOCATORS } from 'constants/automation'

import { BannerTouchPoint, Item } from '../../../types/ShoppingPageType'
import { trackClick } from '../../../../../lib/track-client-side'
import {
  FEEDBACK_MODULE,
  SHOPPING_HOME_MODULE_SHOWN
} from '../../../../../lib/client-constants'
import { sendFptiImpression } from '../../../../../lib/fpti-pa'
import useBreakpoint from '../../../../../lib/useBreakpoint'
import useObserver from '../../../../../lib/useObserver'
import { sendPStudioTrackingEvent } from '../../../fpti/send-pstudio-tracking'
import { Card } from '../Card'
import BannerContent from './BannerContent'
import BannerContentMobile from './BannerContentMobile'
import ActionModal from '../ActionModal/ActionModal'
import { sendCarouselDealClick } from '../../../fpti/shared-carousel-deals-fpti-v2'
import { withLocatorContext } from '../../../../../contexts/locator'
import clientData from 'lib/client-data'

export const VISIBILITY_THRESHOLD = 0.9
const {
  sysColorBackgroundSurface: ppBackgroundWhite,
  sysColorBackgroundHighContrast: ppBackgroundBlack,
  sysColorSecondaryMain: ppButtonBlue
} = PAYPAL_THEME
interface BannerProps {
  item: Item
  touchpointId: string
  onVisible?: () => void
}

const LARGER_BANNER_TOUCHPOINTS = [
  'ppshopping_loyaltycardentry',
  'ppshopping_login_banner'
]
const REVERSED_TEXT_CONTENT_TOUCHPOINTS = ['ppshopping_privacy']

const BannerButton = ({
  actions,
  isWhiteBackground
}: {
  actions: Item['actions']
  isWhiteBackground: boolean
}) => {
  const { isMobile } = useBreakpoint()
  if (!(actions && actions[0])) return null
  const contentButtonColor = isWhiteBackground
    ? ppButtonBlue
    : ppBackgroundWhite
  const contentButtonColorMobile = isMobile ? contentButtonColor : ''
  const buttonText = actions[0].title
  return (
    <Button
      as="div"
      theme="v2"
      className="banner--v2__button ppvx_btn"
      inverse={!isWhiteBackground || isMobile}
      tertiary={isMobile}
      style={{ color: contentButtonColorMobile }}
    >
      {buttonText}
    </Button>
  )
}

const Banner = ({ item, touchpointId, onVisible = () => {} }: BannerProps) => {
  const { isMobile, isTablet } = useBreakpoint()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleInView = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    onVisible?.()
    sendFptiImpression({
      event_name: SHOPPING_HOME_MODULE_SHOWN,
      banner_type: touchpointId,
      rank: item.rank
    })
  }, [item, onVisible, touchpointId])

  useObserver(containerRef, handleInView, {
    useOnce: true,
    intersectionOptions: { threshold: VISIBILITY_THRESHOLD }
  })

  const isTallBanner =
    LARGER_BANNER_TOUCHPOINTS.includes(touchpointId) && isTablet ? true : false
  const hasReversedDiv =
    REVERSED_TEXT_CONTENT_TOUCHPOINTS.includes(touchpointId)
  const backgroundColor = get(item, 'backgroundColor') || ppBackgroundWhite
  const isWhiteBackground =
    backgroundColor.toLocaleLowerCase() === ppBackgroundWhite
  const defaultTextColor = isWhiteBackground
    ? ppBackgroundBlack
    : ppBackgroundWhite
  const contentTextColor = get(item, 'textColor') || defaultTextColor

  const isHoneyBanner = touchpointId === 'ppshopping_honey_join'
  const { honeyOutboundSsoUrl } = clientData
  let cardActions = item.actions

  if (isHoneyBanner && honeyOutboundSsoUrl && cardActions?.length) {
    const joinHoneyAction = {
      ...cardActions[0],
      navigationTarget: honeyOutboundSsoUrl
    }

    cardActions = [joinHoneyAction]
  }

  const feedbackModalHandler = () => {
    // Check if we need to track this action
    if (window.hasOwnProperty('PAYPAL') && PAYPAL.hasOwnProperty('analytics')) {
      const trackingDetails = item.trackingDetails
      const clickTrackingData = {
        flow_subtype: touchpointId ? touchpointId : '',
        banner_type: FEEDBACK_MODULE
      }
      trackClick(clickTrackingData)
      sendPStudioTrackingEvent(
        trackingDetails?.clickUrl ? trackingDetails.clickUrl : '',
        'click',
        'banner'
      )
      PAYPAL.analytics.reloadQualtrics('shopping-passive-feedbacklink')
    }
  }

  const privacyModalHandler = () => {
    setIsOpen(true)
  }

  const bannerButton = (
    <BannerButton isWhiteBackground={isWhiteBackground} actions={cardActions} />
  )

  const bannerContentProps = {
    isWhiteBackground,
    children: bannerButton,
    contentTextColor,
    description: item.description,
    isReversed: hasReversedDiv,
    primaryImage: item.primaryImage,
    title: item.title
  }

  return (
    <div ref={containerRef}>
      <Card
        isTile
        className="banner--v2"
        actions={cardActions}
        backgroundColor={backgroundColor}
        onClick={() => sendCarouselDealClick(item, touchpointId)}
        privacyDisclosureModalHandler={privacyModalHandler}
        feedbackModalHandler={feedbackModalHandler}
      >
        {!isMobile ? (
          <BannerContent {...bannerContentProps} isTallBanner={isTallBanner} />
        ) : (
          <BannerContentMobile {...bannerContentProps} />
        )}
      </Card>

      <ActionModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        modalContent={get(item, 'actions[0].modalContent') || null}
      />
    </div>
  )
}

export const getBanner = (item: BannerTouchPoint, touchpointId: string) => (
  <Banner touchpointId={touchpointId} item={item} />
)

export default withLocatorContext<BannerProps>(LOCATORS.BANNER, {
  isRootContext: true
})(Banner)
