import { isEmpty } from 'lodash'
import clientData, { init as initClientData } from '../lib/client-data'

if (isEmpty(clientData)) {
  initClientData()
}

export const isProd = () => clientData.env.production === true
export const isDev = () => clientData.env.development === true
export const isStage = () => clientData.env.staging === true
export const isTest = () => clientData.env.test === true

export const URL_PROD = 'https://www.paypal.com'
export const URL_STAGE = 'https://www.msmaster.qa.paypal.com'
