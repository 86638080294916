import clientData from '../../client-data'

/**
 * These props are universally required
 * when using widgets from @paypalcorp/shopping-components
 */
const getBaseWidgetProps = () => {
  return {
    localeConfig: {
      country: clientData.locality.country,
      language: clientData.locality.language
    },
    networkConfig: { csrf: clientData.csrf },
    userLoggedIn: !!clientData.isUserLoggedIn
  }
}

export default getBaseWidgetProps
