import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import { Dialog, Button, HeadingText, BodyText } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

import clientData from '../../../../../lib/client-data'
import ModalFooter from '../ModalFooter/ModalFooter'
import {
  SECOND_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS
} from '../../../../../lib/client-constants'
import { SELECTORS_TEST_ID } from 'constants/automation'
const OPEN_MODAL_INTERVAL_IN_MINUTES = 12
const RELOAD_PAGE_INTERVAL_IN_MINUTES = 15

const LogoutModalFooter = ({ onClick }: { onClick: () => void }) => {
  return (
    <ModalFooter>
      <Button
        theme="v2"
        onClick={onClick}
        data-cy={SELECTORS_TEST_ID.LOGOUT_MODAL_CTA_BUTTON}
      >
        <Message id="main.holidaydeals.logoutModal.button" />
      </Button>
    </ModalFooter>
  )
}

const LogoutModal = () => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const openModalTime = useRef(new Date())
  const reloadPageTime = useRef(new Date())
  const reloadTriggered = useRef(false)
  const modalOpened = useRef(false)

  const resetTime = (dateObject: Date, minutesToAdd: number) => {
    const newTime = new Date(Date.now() + minutesToAdd * MINUTE_IN_MILLISECONDS)
    dateObject.setTime(newTime.getTime())
  }

  useEffect(() => {
    let reloadPageInterval: NodeJS.Timer
    let openModalInterval: NodeJS.Timer

    if (clientData.isUserLoggedIn) {
      reloadPageInterval = setInterval(() => {
        if (
          Date.now() >= reloadPageTime.current.getTime() &&
          !reloadTriggered.current
        ) {
          clearInterval(reloadPageInterval)
          location.reload()
          reloadTriggered.current = true
        }
      }, SECOND_IN_MILLISECONDS)

      openModalInterval = setInterval(() => {
        if (
          Date.now() >= openModalTime.current.getTime() &&
          !modalOpened.current
        ) {
          setIsLogoutModalOpen(true)
          modalOpened.current = true
        }
      }, SECOND_IN_MILLISECONDS)
    }

    return () => {
      if (clientData.isUserLoggedIn) {
        clearInterval(reloadPageInterval)
        clearInterval(openModalInterval)
      }
    }
  }, [])

  useEffect(() => {
    if (!isLogoutModalOpen) {
      resetTime(openModalTime.current, OPEN_MODAL_INTERVAL_IN_MINUTES)
      resetTime(reloadPageTime.current, RELOAD_PAGE_INTERVAL_IN_MINUTES)
      modalOpened.current = false
    }
  }, [isLogoutModalOpen])

  const handleCloseModal = () => {
    setIsLogoutModalOpen(false)
    // making a call to /shopping/refresh-session makes it so we go through the
    // auth middleware which refreshes the session
    axios('/offers/refresh-session')
      .then(res => {
        // Production returns 302 if user is not logged in
        if (res.status === 302) location.reload()
        return
      })
      .catch(() => {
        // Staging returns 401 if user is not logged in
        location.reload()
        return
      })
  }

  return (
    <Dialog
      data-cy="logout-modal--v2"
      containerClassName="logout-modal--v2"
      className="logout-modal--v2__container"
      title=""
      isOpen={isLogoutModalOpen}
      closeOnBackgroundClick
      closeButtonProps={{
        'data-cy': SELECTORS_TEST_ID.LOGOUT_MODAL_CLOSE_BUTTON
      }}
      footerContents={<LogoutModalFooter onClick={handleCloseModal} />}
      onClose={handleCloseModal}
      requestClose={handleCloseModal}
    >
      <img
        className="logout-modal--v2__icon"
        src="https://www.paypalobjects.com/shopping/icons/warning.svg"
        alt=""
      />
      <HeadingText className="logout-modal--v2__title" size="lg">
        <Message id="main.holidaydeals.logoutModal.title" />
      </HeadingText>
      <BodyText className="logout-modal--v2__description">
        <Message id="main.holidaydeals.logoutModal.description" />
      </BodyText>
    </Dialog>
  )
}

export default LogoutModal
