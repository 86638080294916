import { ACTION_FRAGMENT } from './ActionFragment'
import { IMAGE_FRAGMENT } from './ImageFragment'
import { TAGS_FRAGMENT } from './TagsFragment'
import { TRACKING_DETAILS_FRAGMENT } from './TrackingDetailsFragment'

export const ITEM_CONDITION_FRAGMENT = `{
  image ${IMAGE_FRAGMENT}
  content
}`

export const ITEM_OFFER_SPECIFICS_SECTION_ITEM_FRAGMENT = `{
  title
  primaryImage ${IMAGE_FRAGMENT}
}`

export const ITEM_OFFER_SPECIFICS_SECTION_FRAGMENT = `{
  title
  items ${ITEM_OFFER_SPECIFICS_SECTION_ITEM_FRAGMENT}
}`

export const ITEM_CONTENT_FRAGMENT = `{
  title
  content
  icon ${IMAGE_FRAGMENT}
  actions ${ACTION_FRAGMENT}
}`

export const ITEM_HOW_IT_WORKS_SECTION_ITEM_FRAGMENT = `{
  title
  primaryImage ${IMAGE_FRAGMENT}
  termsAndConditionsSection ${ITEM_CONTENT_FRAGMENT}
}`

export const ITEM_HOW_IT_WORKS_SECTION_FRAGMENT = `{
  title
  items ${ITEM_HOW_IT_WORKS_SECTION_ITEM_FRAGMENT}
}`

export const ITEM_FRAGMENT = `{
  actions ${ACTION_FRAGMENT}
  backgroundColor
  campaignId
  cardImage ${IMAGE_FRAGMENT}
  conditions ${ITEM_CONDITION_FRAGMENT}
  decryptedOfferId
  decryptedOfferProgramId
  description
  details ${ITEM_CONTENT_FRAGMENT}
  disclaimer
  gradient
  howItWorksSection ${ITEM_HOW_IT_WORKS_SECTION_FRAGMENT}
  howToUseSection ${ITEM_CONTENT_FRAGMENT}
  itemId
  itemType
  landingUrl
  logoImage ${IMAGE_FRAGMENT}
  offerSpecificsSection ${ITEM_OFFER_SPECIFICS_SECTION_FRAGMENT}
  offerType
  primaryImage ${IMAGE_FRAGMENT}
  rank
  rewardsPointsLabel
  rewardsPointsSubtitle
  savedToWallet
  secondaryImage ${IMAGE_FRAGMENT}
  shortTitle
  shoppingCategories
  storeIds
  storeName
  subtitle
  tags ${TAGS_FRAGMENT}
  termsAndConditionsSection ${ITEM_CONTENT_FRAGMENT}
  textColor
  title
  trackingDetails ${TRACKING_DETAILS_FRAGMENT}
  totalItems
}`
