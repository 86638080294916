import { NavConfigType } from 'components/SubNavBarV3/Links'

export const EXPLORE: NavConfigType = {
  id: 'explore',
  textKey: 'subnavigation.link.explore',
  href: '/offers',
  className: 'subNavBarV3__link'
}

export const SAVED: NavConfigType = {
  id: 'savedOffers',
  textKey: 'subnavigation.link.saved',
  href: '/offers/saved-offers',
  className: 'subNavBarV3__link'
}
