/**
 * Check if the error throw contain a valid JSON to parse
 * @param error
 * @returns Object
 */
export const hasJsonError = (error: string) => {
  try {
    return JSON.parse(error.replace('Error:', ''))
  } catch (e) {
    return {}
  }
}
