import React from 'react'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'
import BannerLogo from './BannerLogo'

export interface Image {
  accessibilityLabel?: string
  url: string
  backgroundColor?: string
}

interface Props {
  isWhiteBackground: boolean
  contentTextColor: string
  title: string | null
  description: string | null
  primaryImage: Image | null
  isTallBanner?: boolean
  children?: JSX.Element | null
  isReversed?: boolean | null
}

interface BannerTitleProp {
  title: string | null
  textColor: string
  isWhiteBackground: boolean
  isReversed?: boolean | null
}
interface BannerDescriptionProp {
  description: string | null
  textColor: string
  isWhiteBackground: boolean
  isReversed?: boolean | null
}
const BannerTitle = ({
  title,
  textColor,
  isWhiteBackground,
  isReversed
}: BannerTitleProp) => {
  if (!title) return null
  if (isReversed)
    return (
      <BodyText
        className="banner--v2__title--reversed"
        isInverse={isWhiteBackground}
        style={{ color: textColor }}
      >
        {title}
      </BodyText>
    )
  return (
    <HeadingText
      className="banner--v2__title"
      size="sm"
      isInverse={isWhiteBackground}
      style={{ color: textColor }}
    >
      {title}
    </HeadingText>
  )
}

const BannerDescription = ({
  description,
  textColor,
  isWhiteBackground,
  isReversed
}: BannerDescriptionProp) => {
  if (!description) return null
  if (isReversed)
    return (
      <HeadingText
        size="sm"
        isInverse={isWhiteBackground}
        style={{ color: textColor }}
        className="banner--v2__description--reversed"
      >
        {description}
      </HeadingText>
    )
  return (
    <BodyText
      isInverse={isWhiteBackground}
      style={{ color: textColor }}
      className="banner--v2__description"
    >
      {description}
    </BodyText>
  )
}

const BannerContent = ({
  isWhiteBackground,
  contentTextColor,
  title,
  description,
  primaryImage,
  isTallBanner,
  children,
  isReversed
}: Props): JSX.Element | null => {
  const bannerContentClass = isTallBanner
    ? 'banner--v2__container--tall'
    : 'banner--v2__container'
  return (
    <div className={bannerContentClass}>
      <BannerLogo image={primaryImage} />
      <div className="banner--v2__info-container">
        <BannerTitle
          title={title}
          textColor={contentTextColor}
          isWhiteBackground={isWhiteBackground}
          isReversed={isReversed}
        />
        <BannerDescription
          description={description}
          textColor={contentTextColor}
          isWhiteBackground={isWhiteBackground}
          isReversed={isReversed}
        />
      </div>
      <div className="banner--v2__button-container">{children}</div>
    </div>
  )
}
export default BannerContent
