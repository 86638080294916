import React from 'react'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'

import { LOCATORS } from 'constants/automation'
import { SectionType } from 'shoplist-app/shopping/types/ShoppingPageType'
import './pageTitle.scss'
import TextWithLinks from 'components/TextWithLinks/TextWithLinks'

type PageTitleProps = Pick<SectionType, 'title' | 'subtitle'>

const PageTitle = ({ title, subtitle }: PageTitleProps) => {
  return (
    <div data-cy={LOCATORS.PAGE_TITLE}>
      <HeadingText
        data-cy={LOCATORS.PAGE_TITLE__TITLE}
        className="page-title__title"
        size="sm"
      >
        {title}
      </HeadingText>
      <BodyText data-cy={LOCATORS.PAGE_TITLE__SUBTITLE}>
        <TextWithLinks text={subtitle} />
      </BodyText>
    </div>
  )
}

export default PageTitle
