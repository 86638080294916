// Reference: https://github.paypal.com/pages/Globalization-R/worldready-js/docs/message-api
import { useCallback, useContext } from 'react'

import { MessageFormat, WorldReady } from '@paypalcorp/worldready'
import type { MessageFormatConfig, MessageValues } from '@paypalcorp/worldready'
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react'

export type FormatMessage = (
  id: string,
  formatOptions?: MessageValues
) => string

function formatMessage(
  config: MessageFormatConfig,
  worldReady: WorldReady,
  formatOptions?: MessageValues
) {
  return new MessageFormat(worldReady, config).format(formatOptions ?? {})
}

function useWorldReady() {
  const worldReady = useContext<WorldReady>(WorldReadyContext)

  const formatMessageMemoized: FormatMessage = useCallback(
    (id, formatOptions) => formatMessage({ id }, worldReady, formatOptions),
    [worldReady]
  )

  return {
    formatMessage: formatMessageMemoized
  }
}

export default useWorldReady
