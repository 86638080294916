import React, { useEffect } from 'react'
import {
  LoadingSpinner,
  CaptionText,
  BodyText,
  Link
} from '@paypalcorp/pp-react'
import { Logo } from '@paypalcorp/shopping-components'
import { Message } from '@paypalcorp/worldready-react'

import './searchPreview.scss'
import { LOCATORS, SEARCH_PREVIEW_SELECTORS } from 'constants/automation'
import { useLocatorContext } from 'contexts/locator'
import useGetHoneyPopularStores from 'hooks/useGetHoneyPopularStores'
import { PopularPPStore } from '../../views/SearchHoney/fetchPopularSearches'
import clientData from '../../lib/client-data'
import getAffiliateLink from 'shoplist-app/store/utils/get-affiliate-link'
import RecentSearches from 'components/RecentSearches'
import {
  DEALS,
  POPULAR_SEARCHES,
  POPULAR_SEARCH_PAGE,
  SEE_ALL,
  SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_PRESSED,
  SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED,
  SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN
} from 'lib/client-constants'
import { sendFptiClick, sendFptiImpression } from 'lib/fpti-pa'
import { getSearchFptiData } from 'views/SearchHoney/utils/getSearchFptiData'

export interface SearchPreviewProps {
  isOpen: boolean
  onStoreClick: (store: PopularPPStore) => void
  query?: string
  showNoMatches: boolean
}

const SearchPreview = ({
  isOpen,
  onStoreClick,
  showNoMatches,
  query
}: SearchPreviewProps) => {
  const contentNamespace = 'main.storeSearch.popularSearches'
  const { getLocatorProps } = useLocatorContext()
  const { isLoading, popularStores } = useGetHoneyPopularStores(5)

  useEffect(() => {
    if (isOpen && popularStores?.length) {
      popularStores.forEach((store, index) => {
        sendFptiImpression({
          ...getSearchFptiData({ store, index }),
          event_name: SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN
        })
      })
    }
  }, [popularStores, isOpen])

  const getPayLaterBadge = (store: PopularPPStore) => {
    return store.searchBuyNowPayLaterDescription
      ? {
          label: store.searchBuyNowPayLaterDescription
        }
      : null
  }

  const handlePopularStoreClick = (store: PopularPPStore, index: number) => {
    sendFptiClick({
      ...getSearchFptiData({ store, index }),
      event_name: SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED,
      banner_type: DEALS
    })
    onStoreClick(store)
  }

  return (
    <div style={{ display: isOpen ? '' : 'none' }} className="search-preview">
      {showNoMatches && (
        <CaptionText
          data-cy={SEARCH_PREVIEW_SELECTORS.NO_MATCHES}
          className="popular-stores__container-header__no-matches"
        >
          <Message namespace={contentNamespace} id="noMatches" query={query} />
        </CaptionText>
      )}
      {isOpen && clientData.isUserLoggedIn && <RecentSearches />}
      <div className="popular-stores">
        {isLoading && (
          <div
            className="popular-stores__container-loading"
            data-cy={SEARCH_PREVIEW_SELECTORS.LOADING}
          >
            <LoadingSpinner size="sm" />
          </div>
        )}
        {!isLoading && popularStores.length > 0 && (
          <div>
            <div className="popular-stores__container-header">
              <header>
                <BodyText>
                  <Message namespace={contentNamespace} id="header" />
                </BodyText>
              </header>
            </div>
            {popularStores.map((store, index) => {
              return (
                <Link
                  onClick={() => handlePopularStoreClick(store, index)}
                  key={index}
                  href={getAffiliateLink(
                    store.storeId,
                    clientData.fptiSessionId || '',
                    store.storeUrl
                  )}
                  target="_blank"
                  className="popular-stores__container-store"
                  data-cy={SEARCH_PREVIEW_SELECTORS.STORE}
                >
                  <Logo
                    className="popular-stores__container-logo"
                    logoImage={{ url: store.logoUrl }}
                    size="xs"
                    payLaterBadge={getPayLaterBadge(store)}
                  />
                  <CaptionText clampAtLine={1}>{store.name}</CaptionText>
                </Link>
              )
            })}
            <div className="popular-stores__container-footer">
              <Link
                onClick={() => {
                  sendFptiClick({
                    event_name: SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_PRESSED,
                    link_name: SEE_ALL,
                    banner_type: POPULAR_SEARCHES
                  })
                }}
                className="popular-stores__show-all"
                href={POPULAR_SEARCH_PAGE}
                theme="v2"
                {...getLocatorProps(LOCATORS.SHOW_ALL)}
              >
                <Message namespace={contentNamespace} id="showAllLabel" />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchPreview
