import React, { useCallback, useEffect, useRef } from 'react'
import './popover.scss'
import {
  AUTOCOMPLETE_SEARCH_SELECTORS,
  POPOVER_SEARCH_SELECTORS
} from 'constants/automation'
import { sendFptiClick, sendFptiImpression } from 'lib/fpti-pa'
import {
  POPULAR_SEARCHES,
  SHOPPING_HOME_SEARCH_PRESSED,
  SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN
} from 'lib/client-constants'

export interface PopoverProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  children: React.ReactNode
  searchInput: string
  SearchAutocomplete: React.ReactNode
  onInputSelect: (isSelected: boolean) => void
}

interface EventTargetExtended extends EventTarget {
  offsetParent: HTMLElement
  className: string
}

interface MouseEventExtended extends MouseEvent {
  relatedTarget: EventTargetExtended
  target: Node
}

export enum KEYS {
  ESCAPE = 'Escape'
}

const Popover = ({
  isOpen,
  children,
  onClose,
  onOpen,
  searchInput,
  SearchAutocomplete,
  onInputSelect
}: PopoverProps) => {
  const clickContainerRef = useRef<HTMLDivElement>(null)
  const focusContainerRef = useRef<HTMLDivElement>(null)

  const handleClickAway = useCallback(() => {
    onInputSelect(false)
    onClose()
  }, [onClose, onInputSelect])

  const handleClickIn = useCallback(() => {
    onInputSelect(true)
    sendFptiClick({ event_name: SHOPPING_HOME_SEARCH_PRESSED })
    if (searchInput === '') {
      onOpen()
    }
  }, [onInputSelect, onOpen, searchInput])

  const handleItemFocusOut = useCallback(() => {
    onInputSelect(false)
    onClose()
  }, [onClose, onInputSelect])

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYS.ESCAPE) {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById(AUTOCOMPLETE_SEARCH_SELECTORS.INPUT)?.focus()
      handleClickAway()
    }
  }

  const handleClick = useCallback(
    (event: MouseEventExtended) => {
      if (
        clickContainerRef.current &&
        !clickContainerRef.current.contains(event.target)
      ) {
        handleClickAway()
      } else {
        handleClickIn()
      }
    },
    [clickContainerRef, handleClickAway, handleClickIn]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [clickContainerRef, handleClick])

  const handleFocusOut = useCallback(
    (event: MouseEventExtended): void => {
      const popoverClassName = 'popover'
      const input = 'search-input'

      if (event.relatedTarget) {
        const isFocusOnSearchInput =
          event.relatedTarget?.offsetParent?.className.includes(input)

        const isFocusOutPopover =
          event.relatedTarget?.offsetParent?.className !== popoverClassName

        if (isFocusOutPopover && !isFocusOnSearchInput) {
          handleItemFocusOut()
        } else if (isFocusOnSearchInput && !searchInput) {
          onOpen()
        }
        if (isFocusOnSearchInput) {
          onInputSelect(true)
        }
      } else if (!event.isTrusted) {
        handleItemFocusOut()
      }
    },
    [handleItemFocusOut, onInputSelect, onOpen, searchInput]
  )

  useEffect(() => {
    document.addEventListener('focusout', handleFocusOut)
    return () => {
      document.removeEventListener('focusout', handleFocusOut)
    }
  }, [focusContainerRef, handleFocusOut])

  useEffect(() => {
    if (isOpen) {
      sendFptiImpression({
        event_name: SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN,
        banner_type: POPULAR_SEARCHES
      })
    }
  }, [isOpen])

  return (
    <div
      data-cy={POPOVER_SEARCH_SELECTORS.POPOVER_SEARCH_CONTAINER}
      className="search-popover-container"
      ref={clickContainerRef}
      onKeyUp={handleOnKeyUp}
    >
      {SearchAutocomplete}
      <div
        onFocus={() => onInputSelect(true)}
        style={{ display: isOpen ? '' : 'none' }}
        data-cy={POPOVER_SEARCH_SELECTORS.POPOVER_SEARCH}
        className="popover"
        ref={focusContainerRef}
      >
        {children}
      </div>
    </div>
  )
}

export default Popover
