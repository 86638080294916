import React from 'react'
import { CaptionText, BodyText } from '@paypalcorp/pp-react'
import BannerLogo from './BannerLogo'

export interface Image {
  accessibilityLabel?: string
  url: string
  backgroundColor?: string
}

interface Props {
  isWhiteBackground: boolean
  contentTextColor: string
  title: string | null
  description: string | null
  primaryImage: Image | null
  children?: JSX.Element | null
  isReversed: boolean
}

interface BannerTitleProp {
  title: string | null
  textColor: string
  isWhiteBackground: boolean
  isReversed?: boolean | null
}
interface BannerDescriptionProp {
  description: string | null
  textColor: string
  isWhiteBackground: boolean
  isReversed?: boolean | null
}
const BannerTitle = ({
  title,
  textColor,
  isWhiteBackground,
  isReversed
}: BannerTitleProp) => {
  if (!title) return null
  if (isReversed)
    return (
      <CaptionText
        isInverse={isWhiteBackground}
        style={{ color: textColor }}
        className="banner--v2__title--reversed"
      >
        {title}
      </CaptionText>
    )
  return (
    <BodyText
      className="banner--v2__title"
      isInverse={isWhiteBackground}
      style={{ color: textColor }}
    >
      {title}
    </BodyText>
  )
}

const BannerDescription = ({
  description,
  textColor,
  isWhiteBackground,
  isReversed
}: BannerDescriptionProp) => {
  if (isReversed)
    return (
      <BodyText
        className="banner--v2__description--reversed"
        isInverse={isWhiteBackground}
        style={{ color: textColor }}
      >
        {description}
      </BodyText>
    )
  return (
    <CaptionText
      isInverse={isWhiteBackground}
      style={{ color: textColor }}
      className="banner--v2__description"
    >
      {description}
    </CaptionText>
  )
}

const BannerContentMobile = ({
  isWhiteBackground,
  contentTextColor,
  title,
  description,
  primaryImage,
  children,
  isReversed
}: Props): JSX.Element | null => {
  return (
    <div className="banner--v2__container">
      <BannerTitle
        title={title}
        textColor={contentTextColor}
        isWhiteBackground={isWhiteBackground}
        isReversed={isReversed}
      />
      <BannerDescription
        description={description}
        textColor={contentTextColor}
        isWhiteBackground={isWhiteBackground}
        isReversed={isReversed}
      />
      <div className="banner--v2__footer">
        <div className="banner--v2__button-container">{children}</div>
        <BannerLogo image={primaryImage} />
      </div>
    </div>
  )
}
export default BannerContentMobile
