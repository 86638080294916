import axios from 'axios'
import clientData from '../lib/client-data'
import { FRAUDNET_CORRELATION_ID } from '../constants/fraudnet'

const graphql = (query, variables, operationName) => {
  const data = { query }
  const { csrf } = clientData
  if (variables) {
    data.variables = variables
  }
  if (operationName) {
    data.operationName = operationName
  }
  let axiosResponse = axios({
    url: '/offers/graphql',
    method: 'post',
    origin: 'same-origin',
    headers: {
      'x-csrf-token': csrf || '',
      'paypal-client-metadata-id':
        sessionStorage.getItem(FRAUDNET_CORRELATION_ID) || ''
    },
    data
  })

  return new Promise((resolve, reject) => {
    axiosResponse.then(resolve).catch(error => {
      location.reload()
      reject(error)
    })
  })
}

export default graphql
