// Initialize DataDog RUM Monitoring
import 'lib/browserMonitoring/initialize'

import './shoplist-app/shopping/bundle.scss'
import 'marketing-components/dist/components/organisms/header/less/header.less'
import 'marketing-components/dist/components/organisms/footer/less/footer.less'
import { initMetricsCapture } from './lib/initMetrics'
import { Page } from './lib/types/metric'
import { initA11yLinting } from 'lib/initA11yLinting'

// @ts-ignore
__webpack_public_path__ = window.webpackPublicPath
window.addEventListener('load', () => {
  require('./views/Shopping')
})

initMetricsCapture({ page: Page.Hub })
initA11yLinting()
