import {
  ModalContextReducer,
  ModalContextState,
  OpenOfferDetailsModalDispatchAction,
  OpenSaveOfferDetailsModalDispatchAction
} from '../../shoplist-app/shopping/types/ShoppingPageType'
import { areAllModalsClosed } from './ModalContext'
import {
  MODAL_OPEN_STATUS,
  MODAL_OPEN_SAVE_STATUS,
  MODAL_OFFER_SAVED_STATUS,
  MODAL_CLOSED_STATUS,
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION
} from '../../lib/client-constants'
type OpenOfferDetailsModalAction = (
  prevState: ModalContextState,
  action: OpenOfferDetailsModalDispatchAction
) => ModalContextState
type OpenSaveOfferDetailsModalAction = (
  prevState: ModalContextState,
  action: OpenSaveOfferDetailsModalDispatchAction
) => ModalContextState
type SaveOfferDetailsModalAction = (
  prevState: ModalContextState
) => ModalContextState
type CloseOfferDetailsModalAction = (
  prevState: ModalContextState
) => ModalContextState

const openOfferDetailsModalAction: OpenOfferDetailsModalAction = (
  prevState,
  action
) => {
  if (areAllModalsClosed(prevState)) {
    if (action.payload.offerProgramId) {
      const state = { ...prevState }
      state.offerDetailsModal.status = MODAL_OPEN_STATUS
      state.offerDetailsModal.offerProgramId = action.payload.offerProgramId
      return state
    } else if (action.payload.honeyOfferId && action.payload.honeyStoreId) {
      const state = { ...prevState }
      state.offerDetailsModal.status = MODAL_OPEN_STATUS
      state.offerDetailsModal.honeyOfferId = action.payload.honeyOfferId
      state.offerDetailsModal.honeyStoreId = action.payload.honeyStoreId
      return state
    }
  }
  return { ...prevState }
}
export const openSaveOfferDetailsModalAction: OpenSaveOfferDetailsModalAction =
  (prevState, action) => {
    /* Only offers containing offerProgramId can perform auto save action
  Offers with honeyOfferId and honeyStoreId are excluded from this action */
    if (areAllModalsClosed(prevState) && action.payload.offerProgramId) {
      const state = { ...prevState }
      state.offerDetailsModal.status = MODAL_OPEN_SAVE_STATUS
      state.offerDetailsModal.offerProgramId = action.payload.offerProgramId
      return state
    }
    return { ...prevState }
  }
const saveOfferDetailsModalAction: SaveOfferDetailsModalAction = prevState => {
  if (
    prevState.offerDetailsModal.status === MODAL_OPEN_STATUS ||
    prevState.offerDetailsModal.status === MODAL_OPEN_SAVE_STATUS
  ) {
    const state = { ...prevState }
    state.offerDetailsModal.status = MODAL_OFFER_SAVED_STATUS
    return state
  }
  return { ...prevState }
}
const closeOfferDetailsModalAction: CloseOfferDetailsModalAction =
  prevState => {
    if (
      prevState.offerDetailsModal.status === MODAL_OPEN_STATUS ||
      prevState.offerDetailsModal.status === MODAL_OPEN_SAVE_STATUS ||
      prevState.offerDetailsModal.status === MODAL_OFFER_SAVED_STATUS
    ) {
      const state = { ...prevState }
      state.offerDetailsModal.status = MODAL_CLOSED_STATUS
      state.offerDetailsModal.offerProgramId = null
      state.offerDetailsModal.honeyOfferId = null
      state.offerDetailsModal.honeyStoreId = null
      return state
    }
    return { ...prevState }
  }

export const offerDetailsModalReducer: ModalContextReducer = (
  prevState,
  action
) => {
  switch (action.type) {
    case MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION:
      return openOfferDetailsModalAction(prevState, action)
    case MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION:
      return openSaveOfferDetailsModalAction(prevState, action)
    case MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION:
      return saveOfferDetailsModalAction(prevState)
    case MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION:
      return closeOfferDetailsModalAction(prevState)
    default:
      return { ...prevState }
  }
}
