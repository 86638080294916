import clientData from './client-data'

type Treatment = { [key: string]: boolean }
type GetTreatments = (treatmentNames?: string[]) => Treatment

const getTreatments: GetTreatments = treatmentNames => {
  const treatments: Treatment = {}

  if (!Array.isArray(treatmentNames)) {
    throw Error(
      `getTreatments() should be call with an array of string and received: ${treatmentNames}`
    )
  }

  treatmentNames.forEach(treatmentName => {
    treatments[treatmentName] =
      !!clientData.experiments && !!clientData.experiments[treatmentName]
  })

  return treatments
}

export { getTreatments }
