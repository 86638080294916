import React, { ReactNode } from 'react'
import { Card as PPUICard } from '@paypalcorp/pp-react'

interface CardContentProps {
  isTile: boolean
  className?: string
  backgroundColor?: string
  children: ReactNode
}

const CardContent = ({
  isTile,
  className,
  backgroundColor,
  children
}: CardContentProps) => {
  if (isTile)
    return (
      <PPUICard className={className} style={{ backgroundColor }}>
        {children}
      </PPUICard>
    )

  return <div className={className}>{children}</div>
}

export default CardContent
