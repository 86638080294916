import React from 'react'
import {
  Action,
  ActionHandler,
  HandleClick,
  ModalContext,
  LinkTarget
} from '../shoplist-app/shopping/types/ShoppingPageType'
import {
  handleOfferDetailsModal,
  handleGoldStoreTermsModal,
  handleHybridOfferDetailsModal,
  handleAction,
  handleLoginToSaveToWallet
} from './actionHandlers'
export interface GetActionProps {
  action?: Action
  modalContext?: ModalContext
  feedbackModalHandler?: ActionHandler
  privacyDisclosureModalHandler?: ActionHandler
  saveToWalletHandler?: ActionHandler
  dismissHandler?: ActionHandler
  onClick?: HandleClick
}

type GetLinkTarget = (
  navigationType: Action['navigationType']
) => LinkTarget | undefined
type IsLink = (
  action: Action['type'],
  navigationType: Action['navigationType']
) => boolean

const getLinkTarget: GetLinkTarget = navigationType => {
  if (navigationType === 'NEW_TAB') return '_blank'
  if (navigationType === 'SAME_TAB') return '_self'
  return undefined
}

export const isLink: IsLink = (type, navigationType) => {
  if (type !== 'NAVIGATION') return false
  return navigationType === 'NEW_TAB' || navigationType === 'SAME_TAB'
}

// TODO: feedbackModalHandler and privacyDisclosureModalHandler should be handled inside and not be received as props.
const getActionProps = ({
  action,
  modalContext,
  feedbackModalHandler,
  privacyDisclosureModalHandler,
  saveToWalletHandler,
  dismissHandler,
  onClick
}: GetActionProps) => {
  action = action || {}
  const { state: modalContextState, dispatch: modalDispatch } =
    modalContext || {}
  const { type, title, navigationTarget, navigationType } = action
  const target = getLinkTarget(navigationType)
  const href = isLink(type, navigationType) ? navigationTarget : undefined
  const isNavigation = type === 'NAVIGATION'
  const isOfferDetailsModal =
    isNavigation && navigationType === 'OFFER_DETAILS_MODAL'
  const isGoldStoreTermsModal = isNavigation && navigationType === 'GOLD_MODAL'
  const isHybridOfferDetailsModal =
    isNavigation && navigationType === 'HYBRID_OFFER_MODAL'
  const SAVE_TO_WALLET_ACTIONS = [
    'SAVE_TO_WALLET',
    'SAVE_HYBRID_OFFER',
    'QUICK_SAVE_HYBRID_OFFER'
  ]
  const isSaveToWallet = type && SAVE_TO_WALLET_ACTIONS.includes(type)
  const isLoginToSaveToWallet = type === 'LOGIN_TO_SAVE_TO_WALLET'
  const isDismiss = type === 'DISMISS'
  const isFeedbackModal = isNavigation && !navigationType
  const isPrivacyDisclosureModal = type === 'MODAL'
  const isButton =
    isOfferDetailsModal ||
    isGoldStoreTermsModal ||
    isHybridOfferDetailsModal ||
    isLoginToSaveToWallet ||
    isFeedbackModal ||
    isPrivacyDisclosureModal ||
    isSaveToWallet ||
    isDismiss

  const handleLinkClick: HandleClick = event => onClick && onClick(event)
  const handleButtonClick: HandleClick = event => {
    switch (true) {
      case isOfferDetailsModal:
        handleOfferDetailsModal({ modalDispatch, navigationTarget })
        break
      case isGoldStoreTermsModal:
        handleGoldStoreTermsModal({ modalDispatch, navigationTarget })
        break
      case isHybridOfferDetailsModal:
        handleHybridOfferDetailsModal({ modalDispatch, navigationTarget })
        break
      case isFeedbackModal:
        handleAction({ actionHandler: feedbackModalHandler })
        break
      case isPrivacyDisclosureModal:
        handleAction({ actionHandler: privacyDisclosureModalHandler })
        break
      case isSaveToWallet:
        handleAction({ actionHandler: saveToWalletHandler })
        break
      case isLoginToSaveToWallet:
        handleLoginToSaveToWallet({ modalContextState, navigationTarget })
        break
      case isDismiss:
        handleAction({ actionHandler: dismissHandler })
        break
      default:
        break
    }
    if (onClick) onClick(event)
  }

  const handleButtonKeyPress: HandleClick<React.KeyboardEvent> = event => {
    if (!event) return
    if ((event.key === ' ' || event.key === 'Enter') && isButton) {
      event.preventDefault()
      handleButtonClick(event)
    }
  }

  return {
    title,
    isLink: isLink(type, navigationType),
    href,
    target,
    isButton,
    handleLinkClick,
    handleButtonClick,
    handleButtonKeyPress
  }
}

/**
 * title: text content for element.
 * isLink: if true element should be rendered as <a> tag.
 * href: href for <a> element in case isLink is true.
 * target: target for <a> element in case isLink is true.
 * handleLinkClick: onClick handler for <a> element in case isLink is true.
 * isButton: element should be redered as <button> or similar (role="button") in case isButton is true.
 * handleButtonClick: onClick handler for element in case isButton is true.
 * handleButtonKeyPress: handler to simulate space bar or enter key press on element (natively button triggers on click event on spacebar or enter key press) in case isButton is true and element is not <button>.
 */
export { getActionProps }
