import { mapValues } from 'lodash'
import { isPayPalAnalyticsLoaded, ready } from './track-client-side'
import logger from './logger'
/**
 * Client-Side Implementation FPTI using pa.js
 * Documentation: https://github.paypal.com/pages/Tracking-R/client-side-instrumentation/docs/index.html
 */
import {
  CLICK,
  PAGE_VIEW,
  WEB,
  SEARCH_RESULT_SCREEN_SHOWN,
  SEARCH_RESULT_SEARCH_TYPING,
  SEARCH_RESULT_SEARCH_CLICKED,
  SEARCH_RESULT_SEARCH_CANCEL_CLICKED,
  SEARCH_RESULT_DISMISS_CLICKED,
  SEARCH_RESULT_RETURNED_MODULE_SHOWN,
  SEARCH_RESULT_RETURNED_TILE_SHOWN,
  SEARCH_RESULT_RETURNED_TILE_PRESSED,
  SEARCH_SUBPAGE_SCREEN_SHOWN,
  SEARCH_SUBPAGE_SELECTION_PRESSED,
  SEARCH_SUBPAGE_SELECTION_SHOWN,
  OFFER_DETAIL_CTA_CLICKED,
  OFFER_DETAIL_DISMISS_CLICKED,
  OFFER_DETAIL_SCREEN_SHOWN,
  SHOPPING_HOME_SEARCH_PRESSED,
  SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN,
  SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_PRESSED,
  SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN,
  SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED,
  SHOPPING_SEARCH_AUTOCOMPLETE_SCREEN_SHOWN,
  SHOPPING_SEARCH_AUTOCOMPLETE_TILE_SHOWN,
  SHOPPING_SEARCH_AUTOCOMPLETE_TILE_PRESSED,
  SHOPPING_SEARCH_RESULT_SEARCH_TYPING_STARTED,
  SHOPPING_SEARCH_RESULT_SEARCH_TYPING_ENDED,
  SHOPPING_DETAIL_SCREEN_SHOWN,
  SHOPPING_SEARCH_RESULT_DETAILS_PRESSED,
  SHOPPING_SEARCH_SPINNER_SHOWN,
  SHOPPING_HOME_MODULE_RENDERED,
  SHOPPING_HOME_MODULE_SHOWN,
  SHOPPING_HOME_MODULE_TILE_PRESSED_V2,
  SHOPPING_STORE_SCREEN_SHOWN,
  SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED,
  SHOPPING_OFFER_DEAL_DETAIL_DISMISS_PRESSED,
  SHOPPING_OFFER_DEAL_DETAIL_ACTION_OCCURRED,
  SHOPPING_STORE_EXPAND_PRESSED,
  SHOPPING_STORE_ITEM_SHOWN,
  SHOPPING_STORE_ITEM_PRESSED,
  SHOPPING_STORE_DISMISS_PRESSED,
  SHOPPING_STORE_TAB_PRESSED,
  SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN,
  SHOPPING_STORE_SHOP_PRESSED,
  SHOPPING_CASHBACK_FAQ_ACCTMERGE_SHOWN,
  SHOPPING_FAILED_CUSTOMER_INTERACTION_OCCURRED,
  SHOPPING_FAILED_CUSTOMER_INTERACTION_RETRY_PRESSED,
  SHOPPING_SECONDARY_NAV_EXPLORE_PRESSED,
  SHOPPING_SECONDARY_NAV_SAVED_PRESSED,
  SHOPPING_SECONDARY_NAV_SEARCH_PRESSED,
  SHOPPING_EXPLORE_SCREEN_SHOWN,
  SHOPPING_SAVED_SCREEN_SHOWN,
  SHOPPING_OMNI_CHANNEL_COMPONENT_ADD_PRESSED
} from './client-constants'
import { toCSVformat } from './toCSVformat'

export type FptiEventNames =
  | typeof SEARCH_RESULT_SCREEN_SHOWN
  | typeof SEARCH_RESULT_SEARCH_TYPING
  | typeof SEARCH_RESULT_SEARCH_CLICKED
  | typeof SEARCH_RESULT_SEARCH_CANCEL_CLICKED
  | typeof SEARCH_RESULT_DISMISS_CLICKED
  | typeof SEARCH_RESULT_RETURNED_MODULE_SHOWN
  | typeof SEARCH_RESULT_RETURNED_TILE_SHOWN
  | typeof SEARCH_RESULT_RETURNED_TILE_PRESSED
  | typeof SEARCH_SUBPAGE_SCREEN_SHOWN
  | typeof SEARCH_SUBPAGE_SELECTION_PRESSED
  | typeof SEARCH_SUBPAGE_SELECTION_SHOWN
  | typeof OFFER_DETAIL_SCREEN_SHOWN
  | typeof OFFER_DETAIL_CTA_CLICKED
  | typeof OFFER_DETAIL_DISMISS_CLICKED
  | typeof SHOPPING_HOME_SEARCH_PRESSED
  | typeof SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN
  | typeof SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_PRESSED
  | typeof SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN
  | typeof SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED
  | typeof SHOPPING_SEARCH_AUTOCOMPLETE_SCREEN_SHOWN
  | typeof SHOPPING_SEARCH_AUTOCOMPLETE_TILE_SHOWN
  | typeof SHOPPING_SEARCH_AUTOCOMPLETE_TILE_PRESSED
  | typeof SHOPPING_SEARCH_RESULT_SEARCH_TYPING_STARTED
  | typeof SHOPPING_SEARCH_RESULT_SEARCH_TYPING_ENDED
  | typeof SHOPPING_DETAIL_SCREEN_SHOWN
  | typeof SHOPPING_SEARCH_RESULT_DETAILS_PRESSED
  | typeof SHOPPING_SEARCH_SPINNER_SHOWN
  | typeof SHOPPING_HOME_MODULE_RENDERED
  | typeof SHOPPING_HOME_MODULE_SHOWN
  | typeof SHOPPING_HOME_MODULE_TILE_PRESSED_V2
  | typeof SHOPPING_STORE_SCREEN_SHOWN
  | typeof SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED
  | typeof SHOPPING_OFFER_DEAL_DETAIL_DISMISS_PRESSED
  | typeof SHOPPING_OFFER_DEAL_DETAIL_ACTION_OCCURRED
  | typeof SHOPPING_STORE_TAB_PRESSED
  | typeof SHOPPING_STORE_EXPAND_PRESSED
  | typeof SHOPPING_STORE_ITEM_SHOWN
  | typeof SHOPPING_STORE_ITEM_PRESSED
  | typeof SHOPPING_STORE_DISMISS_PRESSED
  | typeof SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN
  | typeof SHOPPING_STORE_SHOP_PRESSED
  | typeof SHOPPING_CASHBACK_FAQ_ACCTMERGE_SHOWN
  | typeof SHOPPING_FAILED_CUSTOMER_INTERACTION_OCCURRED
  | typeof SHOPPING_FAILED_CUSTOMER_INTERACTION_RETRY_PRESSED
  | typeof SHOPPING_SECONDARY_NAV_EXPLORE_PRESSED
  | typeof SHOPPING_SECONDARY_NAV_SAVED_PRESSED
  | typeof SHOPPING_SECONDARY_NAV_SEARCH_PRESSED
  | typeof SHOPPING_EXPLORE_SCREEN_SHOWN
  | typeof SHOPPING_SAVED_SCREEN_SHOWN
  | typeof SHOPPING_OMNI_CHANNEL_COMPONENT_ADD_PRESSED

type FPTIMaybeStringOrStrings = string | string[] | null
export interface FptiDataType {
  event_name: FptiEventNames
  offer_program_id?: FPTIMaybeStringOrStrings
  merchant_id?: FPTIMaybeStringOrStrings
  store_id?: FPTIMaybeStringOrStrings
  merchant_name?: FPTIMaybeStringOrStrings
  pageId?: number
  cashback_amt?: FPTIMaybeStringOrStrings
  cashback_enabled?: boolean | boolean[] | string
  dismiss_method?: string
  screen?: string
  banner_type?: string
  tab?: string
  rank?: number | number[]
  link_name?: string | null
  category?:
    | string
    | null
    | string[]
    | (string[] | null)[]
    | FptiCategoriesChunk[]
  offer_type?: 'msb' | 'psb' | 'coupon' | 'affiliate'
  click_source?: string
  //TODO: string type on page_variant can be removed once Offer Details Modal V1 is removed
  page_variant?: 'saved' | 'unsaved' | string
  is_outclick?: 'yes' | 'no' | string
  position?: number | null
  bnpl_eligible?: boolean | string | null
  [key: string]: unknown
}
interface FptiCategoriesChunk {
  rank: number
  categories: string
}

type OptionalEventData = {
  [key: string]: string
}

export type FptiClick = (
  eventName: FptiEventNames,
  optionalEventData?: OptionalEventData
) => void

/**
 * Documentation: https://github.paypal.com/pages/Tracking-R/client-side-instrumentation/docs/index.html
 * @param data For data payload, refer to FPTI dictionary at https://go/fptigov
 * @param isContextAware Should the data payload be merged with window.fpti object? By default it's true
 */
export const sendFptiClick = (
  data: FptiDataType,
  isContextAware = true
): void => {
  if (!isPayPalAnalyticsLoaded()) return

  ready
    .then(() => {
      const formattedLinkName = data.link_name
        ? data.link_name.toLowerCase()
        : data.link_name
      const dataInCSVformat = mapValues(
        { ...data, link_name: formattedLinkName, e: CLICK, event_source: WEB },
        toCSVformat
      )
      PAYPAL.analytics.instance.recordEvent(
        data.event_name,
        dataInCSVformat,
        isContextAware
      )
      return
    })
    .catch(logger.error)
}

/**
 * Documentation: https://github.paypal.com/pages/Tracking-R/client-side-instrumentation/docs/index.html
 * @param data For data payload, refer to FPTI dictionary at https://go/fptigov
 * @param isContextAware Should the data payload be merged with window.fpti object? By default it's true
 */
export const sendFptiImpression = (
  data: FptiDataType,
  isContextAware = true as boolean
): void => {
  if (!isPayPalAnalyticsLoaded()) return

  ready
    .then(() => {
      const formattedLinkName = data.link_name
        ? data.link_name.toLowerCase()
        : data.link_name
      const dataInCSVformat = mapValues(
        {
          ...data,
          link_name: formattedLinkName,
          e: PAGE_VIEW,
          event_source: WEB
        },
        toCSVformat
      )
      PAYPAL.analytics.instance.recordEvent(
        data.event_name,
        dataInCSVformat,
        isContextAware
      )
      return
    })
    .catch(logger.error)
}
