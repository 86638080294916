import { isProd } from 'constants/environment'

/**
 * Returns an object, typically spread as props.
 * Allows components to be unaware of the exact
 * attribute we use for automation.
 */
const createLocatorProps = (locator: string) => {
  if (isProd()) {
    return {}
  }
  return {
    'data-cy': locator
  }
}

export default createLocatorProps
