import React, { useState } from 'react'
import { Avatar, MerchantIcon } from '@paypalcorp/pp-react'
import {
  DEFAULT_MERCHANT_LOGO,
  LOADING
} from '../../../../../lib/client-constants'

interface Props {
  src: string
  className?: string
  alt?: string
  onLoad?: Function
  size?: 'lg' | 'md' | 'sm' | 'xs'
  isVisible?: boolean
}

const Logo = ({
  className,
  src,
  alt,
  onLoad,
  size = 'md',
  isVisible = true
}: Props) => {
  const [hasError, setHasError] = useState<boolean>(false)
  if (!isVisible) return null
  const logoClassName = className ? `logo--v2 ${className}` : 'logo--v2'
  const handleError = () => {
    setHasError(true)
  }

  const handleLoadImage = (_e: React.SyntheticEvent<HTMLImageElement>) => {
    if (onLoad) {
      onLoad()
    }
  }

  if (hasError) {
    return (
      <Avatar
        data-cy="logo-v2-default"
        className={logoClassName}
        svgIconComponent={MerchantIcon}
        size={size}
      />
    )
  }

  return (
    <Avatar
      data-cy="logo-v2"
      className={logoClassName}
      src={src}
      style={{ backgroundColor: 'unset' }}
      alt={alt}
      size={size}
      loading={LOADING}
      data-is-default-image={src === DEFAULT_MERCHANT_LOGO}
      onError={handleError}
      onLoad={handleLoadImage}
    />
  )
}

export default Logo
