import {
  RecentSearchItem,
  RecentSearchType
} from 'shoplist-app/shopping/types/ShoppingPageType'
import { PopularPPStore } from '../fetchPopularSearches'
import { AutocompletePPStore } from '../fetchSearchSuggestions'
import { DEALS } from 'lib/client-constants'
import { isNil } from 'lodash'

interface GetSearchFptDataProps {
  store: AutocompletePPStore | PopularPPStore
  position?: number
  index?: number
}
interface GetRecentSearchFptDataProps {
  item: RecentSearchItem
  index: number
}

export const getSearchFptiData = ({
  store,
  position,
  index
}: GetSearchFptDataProps) => ({
  position: position || (isNil(index) ? 0 : index + 1),
  cashback_amt: `${store.currentCashbackOffer?.fixedRatePercent}` || null,
  cashback_enabled: store.currentCashbackOffer?.active ? 'yes' : 'no',
  store_id: store.storeId,
  merchant_name: store.name,
  coupons: store.publicCouponCount,
  savable: store.searchBuyNowPayLaterEligible ? 'yes' : 'no',
  ...((store.searchEncryptedMerchantId || store.encryptedMerchantId) && {
    merchant_id:
      store.searchEncryptedMerchantId || store.encryptedMerchantId || ''
  }),
  ...(store.currentDoubleCashbackOffer?.activeNow &&
    store.currentCashbackOffer?.fixedRatePercent && {
      previous_offer: (store.currentCashbackOffer.fixedRatePercent / 2).toFixed(
        2
      )
    }),
  bnpl_eligible: store.searchBuyNowPayLaterEligible ? 'yes' : 'no'
})

export const getRecentSearchFptiData = ({
  item,
  index
}: GetRecentSearchFptDataProps) => {
  const isRecentSearchStore = item.type === RecentSearchType.STORE
  return {
    banner_type: DEALS,
    ...(isRecentSearchStore
      ? {
          store_id: item.storeId,
          merchant_name: item.name
        }
      : {
          keyword: item.name
        }),
    is_outclick: isRecentSearchStore ? 'yes' : 'no',
    position: index + 1
  }
}
