import React from 'react'
import { BodyText, CaptionText } from '@paypalcorp/pp-react'
import { Image } from '../../../types/ShoppingPageType'
import Logo from '../Logo/Logo'
import useBreakpoint from '../../../../../lib/useBreakpoint'

type ModalStoreLogoProps = {
  storeName?: string
  logo: Image
  className?: string
}

const StoreName = ({
  storeName,
  className
}: {
  storeName?: string
  className?: string
}) => {
  const { isMobile } = useBreakpoint()
  const titleClassName = className
    ? `modal-store-logo__store-name ${className}`
    : 'modal-store-logo__store-name'
  if (!storeName) return null
  if (isMobile)
    return <CaptionText className={titleClassName} children={storeName} />
  return <BodyText className={titleClassName} children={storeName} />
}

const ModalStoreLogo = ({
  storeName,
  logo,
  className
}: ModalStoreLogoProps) => {
  const { isMobile } = useBreakpoint()
  const containerClassName = className
    ? `modal-store-logo ${className}`
    : 'modal-store-logo'
  const logoClassName = className
    ? `modal-store-logo__logo ${className}__logo`
    : 'modal-store-logo__logo'
  const storeNameClassName = className
    ? `modal-store-logo__store-name ${className}__store-name`
    : 'modal-store-logo__store-name'

  return (
    <div className={containerClassName} data-cy="offer-details-modal__logo">
      <Logo
        className={logoClassName}
        src={logo.url}
        alt={logo.accessibilityLabel}
        size={isMobile ? 'sm' : 'md'}
      />
      <StoreName storeName={storeName} className={storeNameClassName} />
    </div>
  )
}

export default ModalStoreLogo
