import React from 'react'
import { Link } from '@paypalcorp/pp-react'
import { useLocatorContext } from 'contexts/locator'

import { LOCATORS } from '../../constants/automation'
import { EXPLORE, SAVED } from '../../constants/link'
import { FptiEventNames, sendFptiClick } from 'lib/fpti-pa'
import { SubNavBarV3Props } from './SubNavBarV3'
import {
  SHOPPING_SECONDARY_NAV_EXPLORE_PRESSED,
  SHOPPING_SECONDARY_NAV_SAVED_PRESSED
} from 'lib/client-constants'
import useWorldReady from 'hooks/useWorldReady'

export type Props = {
  linkItems: NavConfigType[]
  activeLinkId: SubNavBarV3Props['active']
  points?: number
}

export type NavConfigType = {
  id: 'explore' | 'savedOffers'
  textKey: string
  href: '/offers' | '/offers/saved-offers'
  className: 'subNavBarV3__link'
}

const getTabEvent = (id: NavConfigType['id']) => {
  let tabEvent: FptiEventNames | null
  switch (id) {
    case EXPLORE.id:
      tabEvent = SHOPPING_SECONDARY_NAV_EXPLORE_PRESSED
      break
    case SAVED.id:
      tabEvent = SHOPPING_SECONDARY_NAV_SAVED_PRESSED
      break
    default:
      tabEvent = null
      break
  }

  return tabEvent
}

const Links = ({ linkItems, activeLinkId }: Props) => {
  const { formatMessage } = useWorldReady()
  const { getLocatorProps } = useLocatorContext()

  const isActive = (id: NavConfigType['id']): string => {
    return id === activeLinkId ? '--active' : ''
  }

  const handleOnclick = (id: NavConfigType['id']) => () => {
    const tabEvent = getTabEvent(id)
    if (tabEvent) {
      sendFptiClick({ event_name: tabEvent })
    }
  }

  return (
    <div {...getLocatorProps(LOCATORS.LINKS)} className="subNavBarV3__links">
      {linkItems.map(({ id, className, href, textKey }) => {
        return (
          <Link
            {...getLocatorProps(LOCATORS.LINK)}
            aria-current={id === activeLinkId}
            key={id}
            theme="v2"
            secondary
            className={`${className + isActive(id)} ${
              'ppvx_link' + isActive(id)
            }`}
            href={href}
            children={formatMessage(`main.${textKey}`)}
            onClick={handleOnclick(id)}
          />
        )
      })}
    </div>
  )
}

export default Links
