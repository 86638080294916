import React, { ComponentProps, Fragment } from 'react'
import { Link } from '@paypalcorp/pp-react'
import { isEmpty } from 'lodash'

const ANCHOR_TAG_REGEX = /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/
const CUSTOM_PP_SHOPPING_SEPARATOR = '{ppshopping-link/}'

const hasInlineAnchorTags = (text: string) => {
  return ANCHOR_TAG_REGEX.test(text)
}

const getAllPropsFromAnchorTag = (text: string) => {
  // Using the DOM API to get href value
  const container = document.createElement('div')
  container.innerHTML = text
  const anchorTag = container.querySelector('a') as HTMLAnchorElement
  const props: { [key: string]: any } = {}
  for (let i = 0; i < anchorTag.attributes.length; i++) {
    props[anchorTag.attributes[i].name] = anchorTag.attributes[i].value
  }
  return props
}

export type TextWithLinksParserProps = {
  text?: string | null
  linkProps?: ComponentProps<typeof Link>
}

export const TextWithLinksParser = ({
  text,
  linkProps = {}
}: TextWithLinksParserProps) => {
  if (isEmpty(text)) {
    return <></>
  }

  let textContent = text as string
  if (!hasInlineAnchorTags(textContent)) return <>{textContent}</>

  const links = []
  // Parse all possible inline links one by one
  while (hasInlineAnchorTags(textContent)) {
    const matchingAnchorTagRegex =
      textContent && textContent.match(ANCHOR_TAG_REGEX)
    const anchorProps = getAllPropsFromAnchorTag(textContent)
    const innerText = matchingAnchorTagRegex?.[2]
    links.push(
      <Link {...anchorProps} {...linkProps}>
        {innerText}
      </Link>
    )
    // Replace current text and add a custom separator string to where the link was.
    // eg: "Please visit us at {link\} an check our social media {link\}"
    textContent = textContent.replace(
      ANCHOR_TAG_REGEX,
      CUSTOM_PP_SHOPPING_SEPARATOR
    )
  }
  // Split using custom separator to join with text segment
  const textSegments = textContent.split(CUSTOM_PP_SHOPPING_SEPARATOR)
  const nodes = links.map((link, index) => (
    <Fragment key={index}>
      {textSegments[index]}
      {link}
    </Fragment>
  ))
  // There is a relation of textSegments length = links length + 1
  // Join last text segment
  nodes.push(<Fragment key="last">{textSegments[links.length]}</Fragment>)
  return <>{nodes}</>
}

export default TextWithLinksParser
