import {
  ModalContextReducer,
  ModalContextState,
  OpenCashBackOfferDetailsModalDispatchAction,
  OpenSaveCashBackOfferDetailsModalDispatchAction
} from '../../shoplist-app/shopping/types/ShoppingPageType'
import { areAllModalsClosed } from './ModalContext'
import {
  MODAL_OPEN_STATUS,
  MODAL_OPEN_SAVE_STATUS,
  MODAL_OFFER_SAVED_STATUS,
  MODAL_CLOSED_STATUS,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_SAVE_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_SAVE_OFFER_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_CLOSE_ACTION
} from '../../lib/client-constants'
type OpenCashBackOfferDetailsAction = (
  prevState: ModalContextState,
  action: OpenCashBackOfferDetailsModalDispatchAction
) => ModalContextState
type OpenSaveCashBackOfferDetailsAction = (
  prevState: ModalContextState,
  action: OpenSaveCashBackOfferDetailsModalDispatchAction
) => ModalContextState
type SaveCashBackOfferDetailsAction = (
  prevState: ModalContextState
) => ModalContextState
type CloseCashBackOfferDetailsAction = (
  prevState: ModalContextState
) => ModalContextState

const openCashBackOfferDetailsAction: OpenCashBackOfferDetailsAction = (
  prevState,
  action
) => {
  if (
    areAllModalsClosed(prevState) &&
    action.payload.campaignId &&
    action.payload.storeId
  ) {
    const state = { ...prevState }
    state.cashBackOfferDetailsModal.status = MODAL_OPEN_STATUS
    state.cashBackOfferDetailsModal.campaignId = action.payload.campaignId
    state.cashBackOfferDetailsModal.storeId = action.payload.storeId
    return state
  }
  return { ...prevState }
}
const openSaveCashBackOfferDetailsAction: OpenSaveCashBackOfferDetailsAction = (
  prevState,
  action
) => {
  if (
    areAllModalsClosed(prevState) &&
    action.payload.campaignId &&
    action.payload.storeId
  ) {
    const state = { ...prevState }
    state.cashBackOfferDetailsModal.status = MODAL_OPEN_SAVE_STATUS
    state.cashBackOfferDetailsModal.campaignId = action.payload.campaignId
    state.cashBackOfferDetailsModal.storeId = action.payload.storeId
    return state
  }
  return { ...prevState }
}

const saveCashBackOfferDetailsAction: SaveCashBackOfferDetailsAction =
  prevState => {
    if (
      prevState.cashBackOfferDetailsModal.status === MODAL_OPEN_STATUS ||
      prevState.cashBackOfferDetailsModal.status === MODAL_OPEN_SAVE_STATUS
    ) {
      const state = { ...prevState }
      state.cashBackOfferDetailsModal.status = MODAL_OFFER_SAVED_STATUS
      return state
    }
    return { ...prevState }
  }

const closeCashBackOfferDetailsAction: CloseCashBackOfferDetailsAction =
  prevState => {
    if (
      prevState.cashBackOfferDetailsModal.status === MODAL_OPEN_STATUS ||
      prevState.cashBackOfferDetailsModal.status === MODAL_OPEN_SAVE_STATUS ||
      prevState.cashBackOfferDetailsModal.status === MODAL_OFFER_SAVED_STATUS
    ) {
      const state = { ...prevState }
      state.cashBackOfferDetailsModal.status = MODAL_CLOSED_STATUS
      state.cashBackOfferDetailsModal.campaignId = null
      state.cashBackOfferDetailsModal.storeId = null
      return state
    }
    return { ...prevState }
  }

export const cashBackOfferDetailsModalReducer: ModalContextReducer = (
  prevState,
  action
) => {
  switch (action.type) {
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION:
      return openCashBackOfferDetailsAction(prevState, action)
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_SAVE_ACTION:
      return openSaveCashBackOfferDetailsAction(prevState, action)
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_SAVE_OFFER_ACTION:
      return saveCashBackOfferDetailsAction(prevState)
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_CLOSE_ACTION:
      return closeCashBackOfferDetailsAction(prevState)
    default:
      return { ...prevState }
  }
}
