import {
  addRecentSearchToLocalStorage,
  getRecentSearchValue,
  removeRecentSearchFromLocalStorage
} from 'lib/recentSearches'
import { useState } from 'react'
import { RecentSearchItem } from 'shoplist-app/shopping/types/ShoppingPageType'

const useRecentSearches = (encryptedID: string) => {
  const [recentSearches, setRecentSearches] = useState<RecentSearchItem[]>(
    () => {
      const recentSearchValue = getRecentSearchValue(encryptedID)
      return (recentSearchValue || []).slice(0, 6)
    }
  )

  const addRecentSearch = (recentSearchItem: RecentSearchItem): void => {
    if (!recentSearchItem || !encryptedID) return
    const searchItems = addRecentSearchToLocalStorage(
      encryptedID,
      recentSearchItem
    )
    setRecentSearches(searchItems)
  }

  const removeRecentSearch = (recentSearchItem: RecentSearchItem): void => {
    if (!recentSearchItem || !encryptedID) return
    const searchItems = removeRecentSearchFromLocalStorage(
      encryptedID,
      recentSearchItem
    )
    setRecentSearches(searchItems)
  }

  return {
    recentSearches,
    addRecentSearch,
    removeRecentSearch
  }
}

export default useRecentSearches
