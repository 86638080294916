import React from 'react'
import { LoadingSpinner } from '@paypalcorp/pp-react'

const ModalLoadingSpinner = ({ isVisible }: { isVisible?: boolean }) => {
  if (!isVisible) return null
  return (
    <div className="modal-loading-spinner--v2" data-cy="modal-loading-spinner">
      <LoadingSpinner
        size="xl"
        className="modal-loading-spinner--v2__spinner"
      />
    </div>
  )
}

export default ModalLoadingSpinner
