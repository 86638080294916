import { ShoppingFeedType } from 'types/graphql/constants/ShoppingFeed'

export const SHOPPING_FEED_TYPES: {
  [key in ShoppingFeedType]: ShoppingFeedType
} = {
  SHOPPING_HUB: 'SHOPPING_HUB',
  SHOPPING_HUB_DEALS: 'SHOPPING_HUB_DEALS',
  REWARD_POINTS_ACTIVITIES: 'REWARD_POINTS_ACTIVITIES',
  REWARD_POINTS_ONBOARDING: 'REWARD_POINTS_ONBOARDING',
  EXPLORE_TAB: 'EXPLORE_TAB',
  SAVED_TAB: 'SAVED_TAB'
}
