import React from 'react'
import { BodyText } from '@paypalcorp/pp-react'
import { createLocatorProps } from '../../../../../contexts/locator'
import { LOCATORS } from 'constants/automation'

type Props = {
  as?:
    | keyof JSX.IntrinsicElements
    | React.JSXElementConstructor<{ className?: string }>
  title: string
  children: React.ReactNode
  className?: string
}
const ModalTitleSection = ({
  as = 'div',
  title,
  children,
  className
}: Props) => {
  const containerClassName = className
    ? `modal-title-section ${className}`
    : 'modal-title-section'
  const titleClassName = className
    ? `modal-title-section__title ${className}__title`
    : 'modal-title-section__title'

  return (
    <div
      className={containerClassName}
      {...createLocatorProps(LOCATORS.MODAL_TITLE_SECTION)}
    >
      <BodyText as={as} strong className={titleClassName}>
        {title}
      </BodyText>
      {children}
    </div>
  )
}

export default ModalTitleSection
