import React from 'react'
import { Link, BodyText } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

import { PP_REWARDS_TERMS_LINK } from 'lib/client-constants'
import { withLocatorContext, useLocatorContext } from 'contexts/locator/index'
import { LOCATORS } from 'constants/automation'

const Terms = () => {
  const { getLocatorProps } = useLocatorContext()

  return (
    <BodyText {...getLocatorProps()}>
      <Message
        id="main.searchPage.terms"
        link={(innerMessage: string) => (
          <Link href={PP_REWARDS_TERMS_LINK} target="_blank">
            {innerMessage}
          </Link>
        )}
      />
    </BodyText>
  )
}

export default withLocatorContext(LOCATORS.TERMS)(Terms)
