import React, { useMemo, useReducer, ReactNode, Reducer } from 'react'
import {
  ModalContextState,
  ModalContextReducer,
  ModalContext,
  ModalContextDispatchAction
} from '../../shoplist-app/shopping/types/ShoppingPageType'
import { offerDetailsModalReducer } from './offerDetailsModalReducer'
import { cashBackOfferDetailsModalReducer } from './cashBackOfferDetailsModalReducer'
import { goldStoreTermsModalReducer } from './goldStoreTermsModalReducer'
import {
  MODAL_CLOSED_STATUS,
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_SAVE_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_SAVE_OFFER_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_CLOSE_ACTION,
  MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION,
  MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION
} from '../../lib/client-constants'
export const areAllModalsClosed = (state: ModalContextState) => {
  return (
    state.offerDetailsModal.status === MODAL_CLOSED_STATUS &&
    state.cashBackOfferDetailsModal.status === MODAL_CLOSED_STATUS &&
    state.goldModal.status === MODAL_CLOSED_STATUS
  )
}

const reducer: ModalContextReducer = (prevState, action) => {
  switch (action.type) {
    case MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION:
    case MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION:
    case MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION:
    case MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION:
      return offerDetailsModalReducer(prevState, action)
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION:
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_SAVE_ACTION:
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_SAVE_OFFER_ACTION:
    case MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_CLOSE_ACTION:
      return cashBackOfferDetailsModalReducer(prevState, action)
    case MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION:
    case MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION:
      return goldStoreTermsModalReducer(prevState, action)
    default:
      return { ...prevState }
  }
}

const initialContext: ModalContext = {
  state: {
    offerDetailsModal: {
      status: MODAL_CLOSED_STATUS,
      offerProgramId: null,
      honeyStoreId: null,
      honeyOfferId: null
    },
    cashBackOfferDetailsModal: {
      status: MODAL_CLOSED_STATUS,
      campaignId: null,
      storeId: null
    },
    goldModal: {
      status: MODAL_CLOSED_STATUS,
      storeId: null
    }
  },
  dispatch: () => {}
}

export const modalContext = React.createContext<ModalContext>(initialContext)

export const ModalContextProvider = (props: { children?: ReactNode }) => {
  const [contextState, dispatch] = useReducer<
    Reducer<ModalContextState, ModalContextDispatchAction>
  >(reducer, initialContext.state)

  const contextValue = useMemo(
    () => ({ state: contextState, dispatch }),
    [contextState, dispatch]
  )

  return (
    <modalContext.Provider value={contextValue}>
      {props.children}
    </modalContext.Provider>
  )
}
