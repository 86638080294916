import React, { FC } from 'react'

export type LayoutProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: React.ReactNode
}

const Layout: FC<LayoutProps> = props => {
  // eslint-disable-next-line react/prop-types
  const { className, ...restProps } = props
  const classes = className ? `slnw-layout ${className}` : 'slnw-layout'
  return <div {...restProps} className={classes} />
}

export default Layout
