import {
  ModalContextReducer,
  ModalContextState,
  OpenGoldStoreTermsModalDispatchAction
} from '../../shoplist-app/shopping/types/ShoppingPageType'
import { areAllModalsClosed } from './ModalContext'
import {
  MODAL_OPEN_STATUS,
  MODAL_CLOSED_STATUS,
  MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION,
  MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION
} from '../../lib/client-constants'

type OpenGoldStoreTermsModalAction = (
  prevState: ModalContextState,
  action: OpenGoldStoreTermsModalDispatchAction
) => ModalContextState
type CloseGoldStoreTermsModalAction = (
  prevState: ModalContextState
) => ModalContextState

const openGoldStoreTermsModalAction: OpenGoldStoreTermsModalAction = (
  prevState,
  action
) => {
  if (areAllModalsClosed(prevState) && action.payload.storeId) {
    const state = { ...prevState }
    state.goldModal.status = MODAL_OPEN_STATUS
    state.goldModal.storeId = action.payload.storeId
    return state
  }
  return { ...prevState }
}

const closeGoldStoreTermsModalAction: CloseGoldStoreTermsModalAction =
  prevState => {
    if (prevState.goldModal.status === MODAL_OPEN_STATUS) {
      const state = { ...prevState }
      state.goldModal.status = MODAL_CLOSED_STATUS
      state.goldModal.storeId = null
      return state
    }
    return { ...prevState }
  }

export const goldStoreTermsModalReducer: ModalContextReducer = (
  prevState,
  action
) => {
  switch (action.type) {
    case MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION:
      return openGoldStoreTermsModalAction(prevState, action)
    case MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION:
      return closeGoldStoreTermsModalAction(prevState)
    default:
      return { ...prevState }
  }
}
