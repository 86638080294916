import { useState, useCallback } from 'react'

import logger from '../lib/logger'
import graphql from '../services/graphql'
import { GET_HOW_REWARD_POINTS_WORK } from '../shoplist-app/shopping/queries'
import { GoldStore } from '../shoplist-app/shopping/types/ShoppingPageType'
import { UIShoppingGoldStoresResponse } from '../shoplist-app/shopping/types/Graphql'

interface Props {
  storeId: string | null
}

export const useGoldStoreTermsModalData = ({ storeId }: Props) => {
  const [store, setStore] = useState<GoldStore | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  const fetchAllSections = useCallback(async () => {
    try {
      setLoading(true)
      const {
        data: { data, errors }
      }: UIShoppingGoldStoresResponse = await graphql(
        GET_HOW_REWARD_POINTS_WORK,
        { storeId }
      )
      if (errors) {
        logger.error('useGoldStoreTermsModalDataHook', errors)
        throw new Error(JSON.stringify(errors))
      }

      setStore(data.uiShopping?.stores?.stores?.[0] || null)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [storeId])

  /**
   * fetchAllSections : call GET_HOW_REWARD_POINTS_WORK gql query
   * store : response from GET_HOW_REWARD_POINTS_WORK
   * loading : indicator loader for the call
   * error : if the call has any error
   */

  return {
    fetchAllSections,
    store,
    loading,
    error
  }
}
