import { useState, useEffect, useContext } from 'react'

import resizeObserverContext from './resize-observer-context'
import {
  MAX_MOBILE_WIDTH,
  MAX_TABLET_WIDTH,
  MAX_DESKTOP_WIDTH,
  MAX_WIDTH_FOR_MOBILE_ASSETS
} from './client-constants'

export interface Breakpoint {
  isMobile: boolean
  isTablet?: boolean
  isDeskTop?: boolean
  isLargeScreen?: boolean
  useMobileAssets?: boolean
  screenSize?: number
}

const getDeviceType = (screenSize: number): Breakpoint => {
  const isMobile = screenSize <= MAX_MOBILE_WIDTH
  const isTablet =
    screenSize > MAX_MOBILE_WIDTH && screenSize <= MAX_TABLET_WIDTH
  const isDeskTop =
    screenSize > MAX_TABLET_WIDTH && screenSize <= MAX_DESKTOP_WIDTH
  const isLargeScreen = screenSize > MAX_DESKTOP_WIDTH
  const useMobileAssets = screenSize <= MAX_WIDTH_FOR_MOBILE_ASSETS

  return {
    isMobile,
    isTablet,
    isDeskTop,
    isLargeScreen,
    useMobileAssets,
    screenSize
  }
}

const useBreakpoint = (size?: number): Breakpoint => {
  let screenSize = useContext(resizeObserverContext)
  if (size) {
    screenSize = size
  }
  const [breakpoint, setBreakpoint] = useState(getDeviceType(screenSize))

  useEffect(() => {
    setBreakpoint(getDeviceType(screenSize))
  }, [screenSize])

  return breakpoint
}

export default useBreakpoint
