import logger from './logger'
import { getCounterPayload } from './metrics'
import { ShubWindow } from './types/shubWindow'
import { Dimensions, MetricName } from './types/metric'

export const initMetricsCapture = (dimensions?: Dimensions) => {
  const shubWindow = window as ShubWindow

  if (shubWindow?.__SHUB__?.logger) return

  shubWindow.__SHUB__ = {
    ...(shubWindow.__SHUB__ || {}),
    logger
  }

  window.addEventListener('load', () => {
    const payload = getCounterPayload(MetricName.traffic, dimensions)
    logger.metric(payload)
  })

  window.addEventListener('error', event => {
    logger.error(`CatchAll:${event.type}.${event.message}.`)
    logger.error(`Filename: ${event.filename}`)
    logger.error(`lineno: ${event.lineno}`)

    try {
      const err = event.error
      logger.error(err)
    } catch (_) {
      // ignore
    }
  })
}
