import React from 'react'
import { BodyText } from '@paypalcorp/pp-react'
import { get } from 'lodash'
import { Image } from '../../../types/ShoppingPageType'

interface Props {
  condition: {
    image: Image
    content: string
  }
}

const TagIcon = ({ icon }: { icon: Image | null }) => {
  if (!icon) return null
  return (
    <img
      className="icon-tag--v2__image"
      src={icon.url}
      alt={icon.accessibilityLabel}
    />
  )
}

const TagText = ({ text }: { text: string | null }) => {
  if (!text) return null
  return <BodyText>{text}</BodyText>
}

const IconTag = ({ condition }: Props) => {
  const icon: null | Image = get(condition, 'image') || null
  const content: null | string = get(condition, 'content') || null
  if (!icon && !content) return null
  return (
    <div className="icon-tag--v2">
      <TagIcon icon={icon} />
      <TagText text={content} />
    </div>
  )
}

export default IconTag
