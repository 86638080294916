/* TODO: plain-value backwards-compatible logic cleanup follow-up ticket:
 * https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2198
 */
import { get } from 'lodash'
import {
  Action,
  ActionHandler,
  ModalContextDispatch,
  ModalContextState
} from '../shoplist-app/shopping/types/ShoppingPageType'
import {
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION,
  MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION,
  MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION,
  CAMPAIGN_ID,
  STORE_ID,
  OFFER_PROGRAM_ID
} from './client-constants'
import { getSearchParams } from './searchParams'

interface HandleModalDispatch {
  modalDispatch?: ModalContextDispatch
  navigationTarget: Action['navigationTarget']
}
type HandleOfferDetailsModal = HandleModalDispatch
type HandleGoldStoreTermsModal = HandleModalDispatch
type HandleHybridOfferDetailsModal = HandleModalDispatch
interface HandleAction {
  actionHandler?: ActionHandler
}
interface HandleLoginToSaveToWalletAction {
  modalContextState?: ModalContextState
  navigationTarget?: string | null
}
export const handleOfferDetailsModal = ({
  modalDispatch,
  navigationTarget
}: HandleOfferDetailsModal) => {
  const urlSearchParams = getSearchParams(navigationTarget || '')
  const offerProgramId = urlSearchParams.get(OFFER_PROGRAM_ID)

  if (offerProgramId && modalDispatch) {
    modalDispatch({
      type: MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION,
      payload: { offerProgramId }
    })
  }
}

export const handleGoldStoreTermsModal = ({
  modalDispatch,
  navigationTarget
}: HandleGoldStoreTermsModal) => {
  const urlSearchParams = getSearchParams(navigationTarget || '')
  const storeId = urlSearchParams.get(STORE_ID)
  if (storeId && modalDispatch) {
    modalDispatch({
      type: MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION,
      payload: { storeId }
    })
  }
}

export const handleHybridOfferDetailsModal = ({
  modalDispatch,
  navigationTarget
}: HandleHybridOfferDetailsModal) => {
  const urlSearchParams = getSearchParams(navigationTarget || '')
  const campaignId = urlSearchParams.get(CAMPAIGN_ID)
  const storeId = urlSearchParams.get(STORE_ID)
  if (campaignId && storeId && modalDispatch) {
    modalDispatch({
      type: MODAL_CONTEXT_CASHBACK_OFFER_DETAILS_OPEN_ACTION,
      payload: { storeId, campaignId }
    })
  }
}

export const handleLoginToSaveToWallet = ({
  modalContextState,
  navigationTarget
}: HandleLoginToSaveToWalletAction) => {
  // navigationTarget required for performing login to save to wallet flow if not present ignore action
  // autoSaveAction sessionStorage item will store ids (offerProgramId, campaignId, storeId)
  // from opened modal (offerDetailsModal or cashbackOfferDetailsModal) in query params format
  // offerDetailsModalHandler and CashbackOfferDetailsModalHandler will look for that key
  // in case it is present and user is logged out it will perform auto save action,
  // in case it is not present or user is not logged out key will be ignored and get deleted
  if (!navigationTarget) return
  const offerDetailsModalStatus =
    get(modalContextState, 'offerDetailsModal.status') || ''
  const cashBackOfferDetailsModalStatus =
    get(modalContextState, 'cashBackOfferDetailsModal.status') || ''
  if (offerDetailsModalStatus === 'OPEN') {
    const offerProgramId =
      get(modalContextState, 'offerDetailsModal.offerProgramId') || ''
    // Do not perform action if offer is not MSB
    if (offerProgramId) {
      const params = new URLSearchParams()
      params.append('offerProgramId', offerProgramId)
      window.sessionStorage.setItem('autoSaveAction', params.toString())
      window.location.assign(navigationTarget)
    }
  } else if (cashBackOfferDetailsModalStatus === 'OPEN') {
    const campaignId =
      get(modalContextState, 'cashBackOfferDetailsModal.campaignId') || ''
    const storeId =
      get(modalContextState, 'cashBackOfferDetailsModal.storeId') || ''
    const params = new URLSearchParams()
    params.append('campaignId', campaignId)
    params.append('storeId', storeId)
    window.sessionStorage.setItem('autoSaveAction', params.toString())
    window.location.assign(navigationTarget)
  }
}

export const handleAction = ({ actionHandler }: HandleAction) => {
  if (actionHandler) actionHandler()
}
