import { useState, useCallback } from 'react'

import { QueryType } from 'types/graphql/queries'
import { GRAPHQL_STATUS } from 'graphql/constants/status'
import { Status } from 'types/graphql/constants/Status'

import fetchGraphql from '../lib/fetchGraphql'

/**
 * @description hook for fetching data from SPNS.
 * IMPORTANT! Use corresponding type for each query
 */
const useGraphQL = <GraphQLQueryType extends QueryType>(query: string) => {
  const [data, setData] = useState<GraphQLQueryType['data'] | null>(null)
  const [status, setStatus] = useState<Status>(GRAPHQL_STATUS.INITIAL)
  const [error, setError] = useState<string | null>(null)

  const fetchData = useCallback(
    async (variables?: GraphQLQueryType['variables']) => {
      setStatus(GRAPHQL_STATUS.LOADING)

      try {
        const result = await fetchGraphql({
          query,
          variables
        })
        setData(result)
        setStatus(GRAPHQL_STATUS.DONE)
      } catch (err) {
        setError(err.toString())
        setStatus(GRAPHQL_STATUS.ERROR)
      }
    },
    [query]
  )

  return { fetchData, data, status, error }
}

export default useGraphQL
