import React from 'react'
import { FallbackProps } from 'react-error-boundary'

import { createLocatorProps } from 'contexts/locator'
import { LOCATORS } from 'constants/automation'
import { PAYPAL_ERROR_PAGE } from 'lib/client-constants'
import PageMessages from 'views/Shopping/components/PageMessages'
import clientData from 'lib/client-data'

import './errorFallback.scss'

interface ErrorFallbackProps extends Partial<FallbackProps> {
  useGenericError?: boolean
}

const ErrorFallback = ({ useGenericError }: ErrorFallbackProps) => {
  const { isUserLoggedIn } = clientData
  const userState = isUserLoggedIn ? 'logged-in' : 'logged-out'

  if (useGenericError) {
    window.location.replace(`${PAYPAL_ERROR_PAGE}?code=500`)
    return null
  }

  return (
    <div
      {...createLocatorProps(LOCATORS.ERROR_FALLBACK)}
      className={`error-fallback__${userState}`}
    >
      <PageMessages hasError noSubNav />
    </div>
  )
}

export default ErrorFallback
