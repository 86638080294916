// OfferDetailsModalHandler intention is to keep a generic way of importing modal
// and to keep modal logic and flows inside one single component to prevent duplicated logic/code within pages.
// This component handles the next flows:
// * Auto save action for logged out flow
// * Modal open by offerProgramId query param

import React, { useEffect, useContext, useCallback } from 'react'
import clientData from '../../../../../lib/client-data'
import OfferDetailsModal from '../OfferDetailsModal/OfferDetailsModal'
import { modalContext } from '../../../../../contexts/ModalContext/ModalContext'
import { sendFptiClick, FptiEventNames } from '../../../../../lib/fpti-pa'
import {
  MODAL_OPEN_STATUS,
  MODAL_OPEN_SAVE_STATUS,
  MODAL_OFFER_SAVED_STATUS,
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION,
  MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION,
  OFFER_PROGRAM_ID
} from '../../../../../lib/client-constants'

type Props = {
  fptiSource: string
  fptiPage?: string
  fptiPgrp?: string
  fptiCloseEvent?: FptiEventNames
}

const OfferDetailsModalHandler = ({
  fptiSource,
  fptiCloseEvent,
  fptiPage,
  fptiPgrp
}: Props) => {
  const { state: modalState, dispatch } = useContext(modalContext)

  // Auto save for logged out flow and modal open with query params flows
  // If auto save action is valid (autoSaveAction includes offerProgramId and user is logged out)
  // Open Modal with query params logic should be ignored
  useEffect(() => {
    // Auto save for logged out flow.
    // autoSaveAction session item is defined on client/src/lib/actionHandlers.
    // Behaviour: Checks if user logged in, in case it is auto save action is performed and autoSaveAction data is cleared,
    // if not action is ignored and autoSaveAction data is cleared
    let ignoreQueryParams = false
    const autoSaveAction = window.sessionStorage.getItem('autoSaveAction') || ''
    const params = new URLSearchParams(autoSaveAction)
    const offerProgramId = params.get(OFFER_PROGRAM_ID)
    if (offerProgramId && clientData.isUserLoggedIn) {
      dispatch({
        type: MODAL_CONTEXT_OFFER_DETAILS_OPEN_SAVE_ACTION,
        payload: {
          offerProgramId
        }
      })
      ignoreQueryParams = true
    }

    if (offerProgramId) window.sessionStorage.removeItem('autoSaveAction')

    if (!ignoreQueryParams) {
      //Open Offer Details modal with query params logic
      const queryParams = new URLSearchParams(window.location.search)
      const offerProgramIdQueryParam = queryParams.get('offerProgramId')
      const honeyOfferIdQueryParam = queryParams.get('honeyOfferId')
      const honeyStoreIdQueryParam = queryParams.get('honeyStoreId')
      const isHoneyOffer = honeyOfferIdQueryParam && honeyStoreIdQueryParam
      if (offerProgramIdQueryParam || isHoneyOffer) {
        dispatch({
          type: MODAL_CONTEXT_OFFER_DETAILS_OPEN_ACTION,
          payload: {
            offerProgramId: offerProgramIdQueryParam,
            honeyOfferId: honeyOfferIdQueryParam,
            honeyStoreId: honeyStoreIdQueryParam
          }
        })
      }
    }
  }, [dispatch])

  const handleOfferDetailsModalClose = useCallback(() => {
    dispatch({ type: MODAL_CONTEXT_OFFER_DETAILS_CLOSE_ACTION })
    if (fptiCloseEvent) {
      sendFptiClick({
        event_name: fptiCloseEvent,
        page: fptiPage,
        pgrp: fptiPgrp
      })
    }
  }, [fptiCloseEvent, fptiPage, fptiPgrp, dispatch])

  const handleSaveOffer = useCallback(
    () => dispatch({ type: MODAL_CONTEXT_OFFER_DETAILS_SAVE_OFFER_ACTION }),
    [dispatch]
  )

  const isOpen =
    modalState.offerDetailsModal.status === MODAL_OPEN_STATUS ||
    modalState.offerDetailsModal.status === MODAL_OPEN_SAVE_STATUS ||
    modalState.offerDetailsModal.status === MODAL_OFFER_SAVED_STATUS

  const autoSave =
    modalState.offerDetailsModal.status === MODAL_OPEN_SAVE_STATUS

  if (!isOpen) return null
  return (
    <OfferDetailsModal
      isOpen={isOpen}
      autoSave={autoSave}
      onClose={handleOfferDetailsModalClose}
      onSaveToWallet={handleSaveOffer}
      offerProgramId={modalState.offerDetailsModal.offerProgramId}
      honeyOfferId={modalState.offerDetailsModal.honeyOfferId}
      honeyStoreId={modalState.offerDetailsModal.honeyStoreId}
      fptiSource={fptiSource}
    />
  )
}

export default OfferDetailsModalHandler
