import React, { useState } from 'react'
import { Button } from '@paypalcorp/pp-react'

interface Props {
  textToCopy: string
  buttonText: string
  size?: 'sm' | 'lg'
  onCopyToClipboard?: () => void
}

const CopyToClipboardButton = ({
  textToCopy,
  buttonText,
  size,
  onCopyToClipboard
}: Props) => {
  const [isCopied, setIsCopied] = useState(false)
  const handleClick = () => {
    navigator.clipboard.writeText(textToCopy)
    setIsCopied(true)
    if (onCopyToClipboard) onCopyToClipboard()
  }
  return (
    <Button
      data-cy="copy-to-clipboard-button"
      onClick={handleClick}
      btnState={isCopied ? 'success' : 'initial'}
      secondary
      theme="v2"
      size={size}
    >
      {buttonText}
    </Button>
  )
}

export default CopyToClipboardButton
