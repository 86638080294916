import React from 'react'
import { Toast as PPReactToast } from '@paypalcorp/pp-react'

const Toast = ({
  ['data-cy']: dataCy,
  isVisible = true,
  isSuccess,
  message = ''
}: {
  'data-cy'?: string
  isVisible: boolean
  isSuccess: boolean
  message?: string
}) => {
  if (!isVisible) return null
  return (
    <PPReactToast isSuccess={isSuccess} message={message} data-cy={dataCy} />
  )
}

export default Toast
