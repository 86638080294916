import {
  RecentSearchItem,
  RecentSearchType
} from 'shoplist-app/shopping/types/ShoppingPageType'

export const recentSearchesKey = 'recentSearches'
export const getRecentSearchValue = (encryptedID: string) => {
  if (typeof window === 'undefined') {
    return null
  }

  const localStorePayload = window.localStorage.getItem(recentSearchesKey)
  if (!localStorePayload) return []

  try {
    const recentSearchvalue = JSON.parse(localStorePayload)?.[encryptedID]
    return recentSearchvalue || []
  } catch (_) {
    return []
  }
}

export const setRecentSearchValue = (
  encryptedID: string,
  value: RecentSearchItem[]
) => {
  if (typeof window !== 'undefined') {
    const item = window.localStorage.getItem(recentSearchesKey)
    const storageValue = item ? JSON.parse(item) : null
    window.localStorage.setItem(
      recentSearchesKey,
      JSON.stringify({ ...storageValue, [encryptedID]: value })
    )
  }
}
export const addRecentSearchToLocalStorage = (
  encryptedID: string,
  recentSearchItem: RecentSearchItem
): RecentSearchItem[] => {
  try {
    const recentSearchValue = getRecentSearchValue(encryptedID)
    if (!recentSearchItem || !encryptedID || !recentSearchValue) return []

    let newRecentSearches: RecentSearchItem[] = []
    if (recentSearchItem.type === RecentSearchType.STORE) {
      newRecentSearches = [
        recentSearchItem,
        ...recentSearchValue.filter(
          (item: RecentSearchItem) =>
            item.name.toLocaleLowerCase() !==
            recentSearchItem.name.toLocaleLowerCase()
        )
      ].slice(0, 6)
    } else {
      newRecentSearches = [
        recentSearchItem,
        ...recentSearchValue.filter(
          (item: RecentSearchItem) =>
            item.name.toLocaleLowerCase() !==
              recentSearchItem.name.toLocaleLowerCase() ||
            item.type === RecentSearchType.STORE
        )
      ].slice(0, 6)
    }
    setRecentSearchValue(encryptedID, newRecentSearches)

    return newRecentSearches
  } catch {
    return []
  }
}

export const removeRecentSearchFromLocalStorage = (
  encryptedID: string,
  recentSearchItem: RecentSearchItem
): RecentSearchItem[] => {
  try {
    const recentSearchValue = getRecentSearchValue(encryptedID)
    if (!recentSearchItem || !encryptedID || !recentSearchValue) return []
    const newRecentSearches = recentSearchValue.filter(
      (item: RecentSearchItem) =>
        item.name !== recentSearchItem.name ||
        item.type !== recentSearchItem.type
    )
    setRecentSearchValue(encryptedID, newRecentSearches)

    return newRecentSearches
  } catch {
    return []
  }
}
