import { WorldReady } from '@paypalcorp/worldready'
import type { WorldReadyMetadata } from '@paypalcorp/worldready'

export const initWorldReady = (
  worldReady: WorldReadyMetadata,
  isTest: boolean
) => {
  if (!isTest) {
    // paypal-scripts will load worldReady with content automatically during tests
    WorldReady.load(worldReady)
  }
}
