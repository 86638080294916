import { QueryType } from 'types/graphql/queries'

import clientData from '../client-data'
import { filterNullItemsDeep } from '../filterNullItems'
import logger from '../logger'
import { FRAUDNET_CORRELATION_ID } from '../../constants/fraudnet'

const fetchGraphql = async <GraphQLQueryType extends QueryType>({
  query,
  variables
}: {
  query: string
  variables?: QueryType['variables']
}) => {
  try {
    const fetchResponse = await fetch('/offers/graphql', {
      body: JSON.stringify({
        query,
        variables
      }),
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'x-csrf-token': clientData.csrf || '',
        'Content-Type': 'application/json',
        'paypal-client-metadata-id':
          sessionStorage.getItem(FRAUDNET_CORRELATION_ID) || ''
      }
    })
    const jsonResponse = await fetchResponse.json()
    if (jsonResponse?.data?.errors) {
      throw new Error(
        JSON.stringify({
          msg: 'GraphQL response contains errors',
          body: jsonResponse,
          statusCode: fetchResponse.status
        })
      )
    } else if (jsonResponse?.data?.uiShopping) {
      const filteredData = filterNullItemsDeep(jsonResponse.data.uiShopping)
      return filteredData as GraphQLQueryType['data'] | null
    } else {
      throw new Error(
        JSON.stringify({
          msg: 'Unexpected graphQL response',
          body: jsonResponse,
          statusCode: fetchResponse.status
        })
      )
    }
  } catch (err) {
    logger.error('fetchGraphql_error', err)
    throw err
  }
}

export default fetchGraphql
