import React from 'react'

import LocatorContext from '../context/LocatorContext'
import useLocatorContext from '../hooks/useLocatorContext'
import getDisplayName from '../../../lib/getDisplayName'

type PropsAny = { [key: string]: any }

/**
 * HOC wrapper to append locator identifiers to Locator Context
 * For appending onto an existing locator context
 */
const withLocatorContext =
  <T extends {} = PropsAny>(
    locator: string | ((props: T) => string),
    { isRootContext = false }: { isRootContext?: boolean } = {}
  ) =>
  (Component: (props: T) => JSX.Element | null) => {
    const WithLocatorContext = (props: T) => {
      const locatorVal =
        typeof locator === 'function' ? locator(props) : locator
      const { getLocator } = useLocatorContext(locatorVal)

      return (
        <LocatorContext.Provider
          value={isRootContext ? locatorVal : getLocator()}
        >
          <Component {...props} />
        </LocatorContext.Provider>
      )
    }
    WithLocatorContext.displayName = `withLocatorContext(${getDisplayName(
      Component
    )})`
    return WithLocatorContext
  }

export default withLocatorContext
