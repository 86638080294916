import React, { FC, useCallback } from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps
} from 'react-error-boundary'
import logger from 'lib/logger'

export type ErrorBoundaryProps = ReactErrorBoundaryProps

// eslint-disable-next-line react/prop-types
const ErrorBoundary: FC<ErrorBoundaryProps> = ({ onError, ...rest }) => {
  const handleOnError = useCallback(
    (
      error: Error,
      info: {
        componentStack: string
      }
    ) => {
      if (onError) {
        onError(error, info)
      }
      logger.error(error, info)
    },
    [onError]
  )
  return <ReactErrorBoundary {...rest} onError={handleOnError} />
}

export default ErrorBoundary
