import React from 'react'
import { BodyText, HeadingText, Link, PAYPAL_THEME } from '@paypalcorp/pp-react'
import { ThemeProvider } from '@emotion/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import '../../styles/pageMessages.scss'

import { createLocatorProps } from 'contexts/locator'
import { LOCATORS } from 'constants/automation'
import useWorldReady, { FormatMessage } from 'hooks/useWorldReady'
import {
  ICON_V3_HOUR_GLASS,
  SHOPPING_FAILED_CUSTOMER_INTERACTION_RETRY_PRESSED
} from 'lib/client-constants'
import { sendFptiClick } from 'lib/fpti-pa'

type PageMessagesProps = {
  hasError?: boolean
  hasNoDeals?: boolean
  hasNoSavedDeals?: boolean
  isUserLoggedOut?: boolean
  noSubNav?: boolean
}

const getPageMessage = (
  { hasError, hasNoDeals, hasNoSavedDeals, isUserLoggedOut }: PageMessagesProps,
  formatMessage: FormatMessage
) => {
  const { href, origin } = window.location

  if (hasError) {
    return {
      heading: formatMessage('main.shoppingHub.pageMessages.error.heading'),
      body: formatMessage('main.shoppingHub.pageMessages.error.body'),
      link: formatMessage('main.shoppingHub.pageMessages.error.link'),
      onClick: () => {
        sendFptiClick({
          event_name: SHOPPING_FAILED_CUSTOMER_INTERACTION_RETRY_PRESSED
        })
      },
      href
    }
  }

  if (isUserLoggedOut) {
    return {
      heading: formatMessage(
        'main.shoppingHub.pageMessages.dealsLogOut.heading'
      ),
      body: formatMessage('main.shoppingHub.pageMessages.dealsLogOut.body'),
      link: formatMessage('main.shoppingHub.pageMessages.dealsLogOut.link'),
      href: `${origin}/signin/?returnUri=${origin}/offers/saved-offers`
    }
  }

  if (hasNoDeals) {
    return {
      heading: formatMessage('main.shoppingHub.pageMessages.empty.heading'),
      body: formatMessage('main.shoppingHub.pageMessages.empty.body'),
      link: formatMessage('main.shoppingHub.pageMessages.empty.link'),
      href: `${origin}/offers/saved-offers`
    }
  }

  if (hasNoSavedDeals) {
    return {
      heading: formatMessage('main.shoppingHub.pageMessages.deals.heading'),
      body: formatMessage('main.shoppingHub.pageMessages.deals.body'),
      link: formatMessage('main.shoppingHub.pageMessages.deals.link'),
      href: `${origin}/offers`
    }
  }

  return null
}

const PageMessages = (props: PageMessagesProps) => {
  const { formatMessage } = useWorldReady()
  const pageContent = getPageMessage(props, formatMessage)

  if (!pageContent) return null

  const { body, heading, href, link, onClick } = pageContent

  return (
    <ThemeProvider theme={PAYPAL_THEME}>
      <div
        {...createLocatorProps(LOCATORS.PAGE_MESSAGES)}
        className={`pageMessages${props.noSubNav ? '--noSubNav' : ''}`}
      >
        <div className="pageMessages__container">
          <img className="pageMessages__icon" src={ICON_V3_HOUR_GLASS} />
          <HeadingText
            {...createLocatorProps(LOCATORS.PAGE_MESSAGES_TITLE)}
            as="h1"
            className="pageMessages__title"
            level={1}
            size="sm"
            children={heading}
          />
          <BodyText
            {...createLocatorProps(LOCATORS.PAGE_MESSAGES_BODY)}
            className="pageMessages__body"
            children={body}
          />
          <Link
            {...createLocatorProps(LOCATORS.PAGE_MESSAGES_CTA)}
            href={href}
            children={link}
            onClick={onClick}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default PageMessages
