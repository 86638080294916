import { Dimensions, MetricName } from './types/metric'

export const getCounterPayload = (
  metricName: MetricName,
  dimensions?: Dimensions
) => {
  return {
    name: metricName,
    type: 'counter',
    dimensions
  }
}
