import { LOADING } from 'lib/client-constants'
import React from 'react'
import { Image } from '../../../types/ShoppingPageType'

interface Props {
  image: Image | null
}
const BannerLogo = ({ image }: Props) => {
  if (!image) return null
  return (
    <div className="banner--v2__logo-container">
      <img
        loading={LOADING}
        className="banner--v2__logo-img"
        src={image.url}
        alt={image.accessibilityLabel}
      />
    </div>
  )
}

export default BannerLogo
