/**
 * Follow the Honey Affiliate Tagging API which follows
 * a redirect chain that always ends up on the requested merchantUrl
 */
const getAffiliateLink = (
  honeyStoreId: string,
  fptiSessionId: string,
  turl?: string | null
): string => {
  if (!turl) {
    return 'https://www.joinhoney.com'
  }
  if (!honeyStoreId) {
    return turl
  }
  if (!fptiSessionId) {
    return turl
  }

  const affiliateLink = [
    `https://o.honey.io/store/${honeyStoreId}/website`,
    '?afsrc=1',
    `&param0=p${fptiSessionId}`,
    '&src=paypal-shopping',
    '&feat=ppd',
    '&cat=deeplink',
    `&turl=${encodeURIComponent(turl)}`
  ].join('')
  return affiliateLink
}

export default getAffiliateLink
