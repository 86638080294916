import { useState, useEffect } from 'react'
import {
  fetchPopularStores,
  PopularPPStore
} from 'views/SearchHoney/fetchPopularSearches'

const useGetHoneyPopularStores = (limit: number) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [popularStores, setPopularStores] = useState<PopularPPStore[]>([])
  const [error, setError] = useState<Error | undefined>()

  useEffect(() => {
    const getHoneyPopularStores = async () => {
      try {
        setIsLoading(true)
        setPopularStores(await fetchPopularStores({ limit }))
      } catch (err) {
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    getHoneyPopularStores()
  }, [limit])

  return {
    isLoading,
    popularStores,
    error
  }
}

export default useGetHoneyPopularStores
