import React from 'react'
import { render } from 'react-dom'
import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ReactResizeDetector from 'react-resize-detector'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import { PAYPAL_THEME } from '@paypalcorp/pp-react'
import createCache from '@emotion/cache'
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react'

import ErrorBoundary from 'components/ErrorBoundary'
import { isTest } from 'constants/environment'
import { ModalContextProvider } from 'contexts/ModalContext/ModalContext'
import {
  SHOPPING_OFFER_DEAL_DETAIL_DISMISS_PRESSED,
  EXPLORE_TAB_SOURCE
} from 'lib/client-constants'
import clientData, { init as initClientData } from 'lib/client-data'
import { initWorldReady } from 'lib/initWorldReady'
import { appendParamsToUrl } from 'lib/browser-history'
import ResizeObserverContext from 'lib/resize-observer-context'
import PortalFooter from 'shoplist-app/shopping/components/v2/PortalFooter'
import OfferDetailsModalHandler from 'shoplist-app/shopping/components/v2/OfferDetailsModalHandler'
import GoldStoreTermsModalHandler from 'shoplist-app/shopping/components/v2/GoldStoreTermsModalHandler/GoldStoreTermsModalHandler'
import ShoppingPageV3 from './pages/ShoppingPage'
import ErrorFallback from 'views/Shopping/components/ErrorFallback'
import { RecentSearchesProvider } from 'contexts/RecentSearchesContext/RecentSearchesContext'

initClientData()
initWorldReady(clientData.worldReady, isTest())

const history = createBrowserHistory({ basename: clientData.requestURI })
// Route is hit for the first time
appendParamsToUrl(history, clientData)

// Route is updated by navigation
history.listen((_location, action) => {
  if (action !== 'REPLACE') {
    appendParamsToUrl(history, clientData)
  }
})

const Home = render(
  <WorldReadyContextProvider locale={clientData.locality.locale}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router history={history}>
        <Route
          exact
          path="/"
          render={() => (
            <CacheProvider
              value={createCache({ key: 'shopping-view', prepend: true })}
            >
              <ReactResizeDetector handleWidth>
                {({ width }: { width: number }) => (
                  <ResizeObserverContext.Provider value={width}>
                    {/* Do not style resize-observer with width, margin or padding!
                    That will might falsy breakpoint logic */}
                    <div id="resize-observer">
                      <ThemeProvider theme={PAYPAL_THEME}>
                        <RecentSearchesProvider>
                          <ModalContextProvider>
                            <OfferDetailsModalHandler
                              fptiSource={EXPLORE_TAB_SOURCE}
                              fptiCloseEvent={
                                SHOPPING_OFFER_DEAL_DETAIL_DISMISS_PRESSED
                              }
                            />
                            <GoldStoreTermsModalHandler
                              fptiSource={EXPLORE_TAB_SOURCE}
                            />
                            <ShoppingPageV3 />
                            <PortalFooter />
                          </ModalContextProvider>
                        </RecentSearchesProvider>
                      </ThemeProvider>
                    </div>
                  </ResizeObserverContext.Provider>
                )}
              </ReactResizeDetector>
            </CacheProvider>
          )}
        />
      </Router>
    </ErrorBoundary>
  </WorldReadyContextProvider>,
  document.getElementById('root')
)

export default Home
