import React from 'react'
import { createPortal } from 'react-dom'

import Footer from 'shoplist-app/shopping/components/v2/Footer'
import clientData from '../../../../../lib/client-data'

const PortalFooter = () => {
  const container = document.getElementById('shopping-footer')
  const { merchantOfferTerms } = clientData

  if (!container || !merchantOfferTerms) {
    return null
  }
  return createPortal(<Footer terms={merchantOfferTerms} />, container)
}

export default PortalFooter
