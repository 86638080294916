import React, { useEffect, useState, useRef } from 'react'
import { get } from 'lodash'
import { BodyText } from '@paypalcorp/pp-react'
import ResponsiveModal from '../ResponsiveModal/ResponsiveModal'
import ModalLoadingSpinner from '../ModalLoadingSpinner/ModalLoadingSpinner'
import ModalStoreLogo from '../ModalStoreLogo/ModalStoreLogo'
import useOfferDetailsData from '../../../../../hooks/useOfferDetailsModalData'
import { OfferDetailsType, Action } from '../../../types/ShoppingPageType'
import ModalTitleSection from '../ModalTitleSection/ModalTitleSection'
import ModalFooter from '../ModalFooter/ModalFooter'
import ActionButton from '../ActionButton/ActionButton'
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton'
import Toast from '../Toast/Toast'
import IconTag from '../IconTag/IconTag'
import {
  sendFptiClick,
  sendFptiImpression,
  FptiDataType
} from '../../../../../lib/fpti-pa'
import {
  SHOPPING_OFFER_DEAL_DETAIL_ACTION_OCCURRED,
  SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED,
  SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN
} from '../../../../../lib/client-constants'
import useWorldReady, { FormatMessage } from 'hooks/useWorldReady'

const TOAST_REFRESH_TIME = 100

export interface OfferDetailsModalProps {
  isOpen: boolean
  autoSave: boolean
  offerProgramId?: string | null
  honeyOfferId?: string | null
  honeyStoreId?: string | null
  onSaveToWallet?: () => void
  onClose: (event?: React.SyntheticEvent<Element, Event>) => void
  fptiSource: string
}

interface ModalContentProps {
  isVisible?: boolean
  offerDetails: OfferDetailsType | null
  onCopyToClipboard: ({ linkName }: { linkName: string }) => void
  formatMessage: FormatMessage
}

interface OfferDetailsModalFooterProps {
  isVisible: boolean
  offerDetails: OfferDetailsType | null
  saveToWalletLoading: boolean
  onSaveToWallet: ({ linkName }: { linkName: string }) => void
  onCopyToClipboard: ({ linkName }: { linkName: string }) => void
  fptiSource: string
  formatMessage: FormatMessage
}

interface PromoCodeSectionProps {
  content?: OfferDetailsType['couponCodeSection'] | null
  onCopyToClipboard: ({ linkName }: { linkName: string }) => void
  formatMessage: FormatMessage
}

interface FptiEventType {
  offerDetails: OfferDetailsType
  source: string
}
interface FptiActionEventType extends FptiEventType {
  linkName: FptiDataType['link_name']
}

const DetailsSection = ({
  title,
  content
}: {
  title?: string
  content?: string
}) => {
  if (!content || !title) return null
  return (
    <ModalTitleSection
      className="offer-details-modal--v2__details-section"
      title={title}
    >
      <BodyText>{content}</BodyText>
    </ModalTitleSection>
  )
}

const HowToUseSection = ({
  content
}: {
  content?: OfferDetailsType['howToUseSection']
}) => {
  const text: string | null = get(content, 'content') || null
  if (!text) return null
  return (
    <BodyText
      className="offer-details-modal--v2__how-to-use-section"
      children={text}
    />
  )
}

const TermsSection = ({
  content
}: {
  content?: OfferDetailsType['termsAndConditionsSection']
}) => {
  if (!content) return null
  return (
    <ModalTitleSection title={content.title}>
      <BodyText>{content.content}</BodyText>
    </ModalTitleSection>
  )
}

const ConditionsSection = ({
  conditions
}: {
  conditions?: OfferDetailsType['conditions']
}) => {
  if (!conditions) return null
  return (
    <>
      {conditions.map((condition, index) => (
        <IconTag key={`condition-tag-${index}`} condition={condition} />
      ))}
    </>
  )
}

const PromoCodeSection = ({
  content,
  onCopyToClipboard,
  formatMessage
}: PromoCodeSectionProps) => {
  if (!content) return null

  const handleCopyToClipboard = () => {
    const linkName: string = formatMessage(
      'main.shoppingHub.modal.offerDetails.copy'
    )
    onCopyToClipboard({ linkName })
  }

  return (
    <ModalTitleSection
      title={content.title}
      className="offer-details-modal--v2__promo-code-section"
    >
      <div className="offer-details-modal--v2__promo-code-section__container">
        <div className="offer-details-modal--v2__promo-code-section__code">
          {content.content}
        </div>
        <CopyToClipboardButton
          textToCopy={content.content}
          buttonText={formatMessage('main.shoppingHub.modal.offerDetails.copy')}
          size="sm"
          onCopyToClipboard={handleCopyToClipboard}
        />
      </div>
    </ModalTitleSection>
  )
}

const getFptiDefaultProps = ({
  offerDetails,
  source
}: FptiEventType): Pick<
  FptiDataType,
  Exclude<keyof FptiDataType, 'event_name'>
> => {
  return {
    cashback_amt: null,
    cashback_enabled: false, // Cashback enabled indicator of store or offer
    store_id: get(offerDetails, 'storeIds[1]'), // Honey store id
    merchant_id: get(offerDetails, 'storeIds[0]'),
    merchant_name: offerDetails.title,
    offer_program_id: offerDetails.decryptedOfferProgramId, // Offer program id. Required in case search result is offer
    offer_id: !offerDetails.decryptedOfferProgramId
      ? offerDetails.itemId
      : null, //offer_id returned for honey offers that doesn't contain a decryptedOfferProgramId
    category: offerDetails.shoppingCategories,
    expiry_date: get(offerDetails, 'conditions[0].content', ''),
    click_source: source,
    incentive_offer_value: offerDetails.subtitle || offerDetails.description
  }
}

const handleFptiOnActionClick = ({
  offerDetails,
  linkName,
  source
}: FptiActionEventType) => {
  sendFptiClick({
    event_name: SHOPPING_OFFER_DEAL_DETAIL_BUTTON_PRESSED,
    link_name: linkName,
    ...getFptiDefaultProps({ offerDetails, source })
  })
}

const handleFptiOnActionOccurred = ({
  offerDetails,
  linkName,
  source
}: FptiActionEventType) => {
  sendFptiClick({
    event_name: SHOPPING_OFFER_DEAL_DETAIL_ACTION_OCCURRED,
    link_name: linkName,
    ...getFptiDefaultProps({ offerDetails, source })
  })
}

const handleFptiOnImpression = ({ offerDetails, source }: FptiEventType) => {
  sendFptiImpression({
    event_name: SHOPPING_OFFER_DEAL_DETAIL_SCREEN_SHOWN,
    page_variant: offerDetails.savedToWallet ? 'saved' : 'unsaved',
    ...getFptiDefaultProps({ offerDetails, source })
  })
}

const OfferDetailsModalFooter = ({
  isVisible = true,
  offerDetails,
  saveToWalletLoading,
  onSaveToWallet,
  onCopyToClipboard,
  fptiSource,
  formatMessage
}: OfferDetailsModalFooterProps) => {
  const actions: Action[] | null = get(offerDetails, 'actions') || null
  const copyToClipboardContent: OfferDetailsType['couponCodeSection'] | null =
    get(offerDetails, 'couponCodeSection') || null
  if ((!actions && !copyToClipboardContent) || !isVisible) return null

  const handleButtonClick = () => {
    const linkName = get(actions, '[0].title') || ''
    if (offerDetails)
      handleFptiOnActionClick({ offerDetails, linkName, source: fptiSource })
  }

  const handleSaveToWallet = () => {
    const linkName = get(actions, '[0].title') || ''
    onSaveToWallet({ linkName })
  }

  const handleCopyToClipboard = () => {
    const linkName: string = formatMessage(
      'main.shoppingHub.modal.offerDetails.copyCode'
    )
    onCopyToClipboard({ linkName })
  }

  return (
    <ModalFooter>
      {copyToClipboardContent ? (
        <CopyToClipboardButton
          textToCopy={copyToClipboardContent.content}
          buttonText={formatMessage(
            'main.shoppingHub.modal.offerDetails.copyCode'
          )}
          onCopyToClipboard={handleCopyToClipboard}
        />
      ) : null}
      <ActionButton
        actions={actions}
        btnState={saveToWalletLoading ? 'processing' : undefined}
        saveToWalletHandler={handleSaveToWallet}
        onClick={handleButtonClick}
      />
    </ModalFooter>
  )
}

const ModalContent = ({
  isVisible = true,
  offerDetails,
  onCopyToClipboard,
  formatMessage
}: ModalContentProps) => {
  if (!isVisible || !offerDetails) return null
  const {
    title,
    primaryImage,
    howToUseSection,
    conditions,
    termsAndConditionsSection,
    couponCodeSection,
    details
  } = offerDetails

  return (
    <>
      {/*Tabindex -1 used to keep focus on the top everytime modal opens*/}
      <span tabIndex={-1} />
      <ModalStoreLogo logo={primaryImage} storeName={title} />
      <HowToUseSection content={howToUseSection} />
      <ConditionsSection conditions={conditions} />
      <DetailsSection {...details} />
      <PromoCodeSection
        content={couponCodeSection}
        onCopyToClipboard={onCopyToClipboard}
        formatMessage={formatMessage}
      />
      <TermsSection content={termsAndConditionsSection} />
    </>
  )
}

const OfferDetailsModal = ({
  isOpen,
  autoSave,
  offerProgramId,
  honeyOfferId,
  honeyStoreId,
  onSaveToWallet,
  onClose,
  fptiSource
}: OfferDetailsModalProps) => {
  const { formatMessage } = useWorldReady()

  const {
    fetchOfferDetails,
    saveToWallet,
    offerDetails,
    fetchLoading,
    saveToWalletLoading,
    error
  } = useOfferDetailsData({
    offerProgramId: offerProgramId || '',
    honeyOfferId: honeyOfferId || '',
    honeyStoreId: honeyStoreId || ''
  })

  const savedToWallet: boolean = get(offerDetails, 'savedToWallet') || false
  const savedToWalletMessage: string | undefined =
    get(offerDetails, 'savedToWalletMessage') || undefined
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false)
  const [isToastSuccess, setIsToastSuccess] = useState<boolean>(true)
  // Toast message as object, to detect changes even if message content didn't changed
  const [toastMessage, setToastMessage] = useState<{ message: string }>({
    message: ''
  })
  const pressedCTALinkName = useRef('')

  useEffect(() => {
    if (isOpen) {
      setToastMessage({ message: '' })
      fetchOfferDetails()
    }
  }, [fetchOfferDetails, isOpen])

  useEffect(() => {
    // If fetch returned error or if fetchLoading is false
    // and offerDetailsFalse (fetch didn't throw error but didn't returned data)
    // trigger modal close
    if (error || (!fetchLoading && !offerDetails)) onClose()
  }, [error, fetchLoading, offerDetails, onClose])

  useEffect(() => {
    if (!fetchLoading) {
      if (offerDetails)
        handleFptiOnImpression({ offerDetails, source: fptiSource })
    }
  }, [fetchLoading, offerDetails, fptiSource])

  useEffect(() => {
    //If savedToWallet is false and savedToWalletMessage is null
    //It means offer is not saved and any save event haven't been triggered
    //The goal is to save the offer if autosave is true just after the offer details at modal open got fetched
    if (
      offerDetails &&
      !offerDetails.savedToWallet &&
      !offerDetails.savedToWalletMessage &&
      autoSave
    )
      saveToWallet()
  }, [offerDetails, autoSave, saveToWallet])

  // Toast reset on toastMessage change
  useEffect(() => {
    let timeoutRef: NodeJS.Timeout
    setIsToastOpen(false)
    if (toastMessage.message) {
      timeoutRef = setTimeout(() => {
        setIsToastOpen(true)
      }, TOAST_REFRESH_TIME)
    }
    return () => {
      if (timeoutRef) clearTimeout(timeoutRef)
    }
  }, [toastMessage])

  useEffect(() => {
    // savedToWalletMessage gets updated when a saveToWallet fetch is done
    // if saveLoading change from true to false and savedToWalletMessage is not undefined
    // this means a save request was done
    if (!saveToWalletLoading && savedToWalletMessage) {
      setToastMessage({ message: savedToWalletMessage })
      setIsToastSuccess(savedToWallet)
      if (savedToWallet) {
        if (onSaveToWallet) onSaveToWallet()
        if (offerDetails)
          handleFptiOnActionOccurred({
            offerDetails,
            linkName: pressedCTALinkName.current,
            source: fptiSource
          })
      }
    }
  }, [
    saveToWalletLoading,
    savedToWalletMessage,
    fptiSource,
    offerDetails,
    onSaveToWallet,
    savedToWallet
  ])

  const handleSaveToWallet = ({ linkName }: { linkName: string }) => {
    pressedCTALinkName.current = linkName
    saveToWallet()
  }

  const handleCopyToClipboard = ({ linkName }: { linkName: string }) => {
    if (offerDetails)
      handleFptiOnActionClick({
        offerDetails,
        linkName,
        source: fptiSource
      })
    setToastMessage({
      message: formatMessage('main.shoppingHub.modal.offerDetails.codeCopied')
    })
    setIsToastSuccess(true)
    if (offerDetails)
      handleFptiOnActionOccurred({
        offerDetails,
        linkName: 'copy',
        source: fptiSource
      })
  }

  return (
    <ResponsiveModal
      id="offer-details-modal"
      data-cy="offer-details-modal"
      containerClassName="offer-details-modal--v2"
      contentClassName="offer-details-modal--v2__content ppvx_modal__contents"
      isOpen={isOpen}
      requestClose={onClose}
      closeOnBackgroundClick
      title={formatMessage('main.shoppingHub.modal.offerDetails.title')}
      hideTitle
      skipFormFocus
      closeButtonProps={{ className: 'ppvx_icon-button' }}
      footerContents={
        <OfferDetailsModalFooter
          isVisible={!fetchLoading && !error}
          offerDetails={offerDetails}
          saveToWalletLoading={saveToWalletLoading}
          onSaveToWallet={handleSaveToWallet}
          onCopyToClipboard={handleCopyToClipboard}
          fptiSource={fptiSource}
          formatMessage={formatMessage}
        />
      }
    >
      <Toast
        data-cy="offer-details-modal-toast"
        isVisible={isToastOpen}
        isSuccess={isToastSuccess}
        message={toastMessage.message}
      />
      <ModalContent
        isVisible={!fetchLoading && !error}
        offerDetails={offerDetails}
        onCopyToClipboard={handleCopyToClipboard}
        formatMessage={formatMessage}
      />
      <ModalLoadingSpinner isVisible={fetchLoading} />
    </ResponsiveModal>
  )
}

export default OfferDetailsModal
