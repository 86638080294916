import React, { useContext, useEffect } from 'react'
import GoldStoreTermsModal from '../GoldStoreTermsModal/GoldStoreTermsModal'
import { modalContext } from '../../../../../contexts/ModalContext/ModalContext'
import {
  MODAL_OPEN_STATUS,
  MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION,
  MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION
} from '../../../../../lib/client-constants'
type Props = {
  fptiSource: string
}

const GoldStoreTermsModalHandler = ({ fptiSource }: Props) => {
  const queryParams = new URLSearchParams(window.location.search)
  const storeIdQueryParam = queryParams.get('storeId')
  const campaignIdQueryParam = queryParams.get('campaignId')
  const { state: modalState, dispatch } = useContext(modalContext)

  const handleGoldStoreTermsModalClose = () => {
    dispatch({ type: MODAL_CONTEXT_GOLD_STORE_TERMS_CLOSE_ACTION })
  }

  useEffect(() => {
    if (storeIdQueryParam && !campaignIdQueryParam) {
      dispatch({
        type: MODAL_CONTEXT_GOLD_STORE_TERMS_OPEN_ACTION,
        payload: {
          storeId: storeIdQueryParam
        }
      })
    }
  }, [storeIdQueryParam, campaignIdQueryParam, dispatch])

  return (
    <GoldStoreTermsModal
      isOpen={modalState.goldModal.status === MODAL_OPEN_STATUS}
      onClose={handleGoldStoreTermsModalClose}
      storeId={modalState.goldModal.storeId}
      fptiSource={fptiSource}
    />
  )
}

export default GoldStoreTermsModalHandler
