import React, { useContext, useEffect } from 'react'
import {
  SuggestionList,
  AutoSuggest,
  SearchInput,
  type Suggestion,
  Avatar
} from '@paypalcorp/pp-react'
import sanitizeHtml from 'sanitize-html'

import { useLocatorContext } from 'contexts/locator'
import { AUTOCOMPLETE_SEARCH_SELECTORS, LOCATORS } from 'constants/automation'
import useGetHoneyAutocomplete, {
  StoreSuggestion
} from 'hooks/useGetHoneyAutocomplete'
import useWorldReady from 'hooks/useWorldReady'
import clientData from 'lib/client-data'
import { RecentSearchesContext } from 'contexts/RecentSearchesContext/RecentSearchesContext'
import { RecentSearchType } from 'shoplist-app/shopping/types/ShoppingPageType'
import {
  SHOPPING_SEARCH_AUTOCOMPLETE_SCREEN_SHOWN,
  SHOPPING_SEARCH_AUTOCOMPLETE_TILE_PRESSED,
  SHOPPING_SEARCH_AUTOCOMPLETE_TILE_SHOWN
} from 'lib/client-constants'
import { sendFptiClick, sendFptiImpression } from 'lib/fpti-pa'
import Terms from 'components/Terms'
import { getSearchFptiData } from 'views/SearchHoney/utils/getSearchFptiData'
import { AutocompletePPStore } from 'views/SearchHoney/fetchSearchSuggestions'

import './searchAutocomplete.scss'

interface SearchAutocompleteProps {
  searchInput: string
  placeholder: string
  isInputSelected: boolean
  onChange: (input: string) => void
  onSelect: (suggestion: StoreSuggestion) => void
  onPressedEsc: () => void
  onPressedEnter: (input: string) => void
  onLoading: (isLoading: boolean, isEmptyResults: boolean) => void
}

const DEBOUNCE_TIMEOUT = 500

const SearchAutocomplete = ({
  searchInput,
  placeholder,
  isInputSelected,
  onChange,
  onSelect,
  onPressedEsc,
  onPressedEnter,
  onLoading
}: SearchAutocompleteProps) => {
  const { formatMessage } = useWorldReady()
  const { getLocatorProps } = useLocatorContext()

  const { isLoading, isEmptyResults, fetchSuggestions, suggestions } =
    useGetHoneyAutocomplete()

  useEffect(() => {
    onLoading(isLoading, isEmptyResults)
  }, [isLoading, isEmptyResults, onLoading])

  const getStoreFromSuggestion = (suggestion: Suggestion) => {
    const store: AutocompletePPStore = suggestions.filter(
      item => item.storeId === suggestion.id
    )[0]
    const position =
      suggestions.findIndex(item => item.storeId === store.storeId) + 1 ?? null

    return {
      store,
      position
    }
  }

  useEffect(() => {
    if (isInputSelected && suggestions?.length) {
      sendFptiImpression({
        event_name: SHOPPING_SEARCH_AUTOCOMPLETE_SCREEN_SHOWN
      })
      suggestions.forEach((suggestion, index) => {
        sendFptiImpression({
          ...getSearchFptiData({ store: suggestion, index }),
          event_name: SHOPPING_SEARCH_AUTOCOMPLETE_TILE_SHOWN
        })
      })
    }
  }, [suggestions, isInputSelected])

  const { isUserLoggedIn } = clientData
  const { addRecentSearch } = useContext(RecentSearchesContext) || {}

  const handleSelect = (
    _evt: React.ChangeEvent,
    suggestion: StoreSuggestion
  ) => {
    const { store, position } = getStoreFromSuggestion(suggestion)
    sendFptiClick({
      ...getSearchFptiData({ store, position }),
      event_name: SHOPPING_SEARCH_AUTOCOMPLETE_TILE_PRESSED
    })
    if (isUserLoggedIn && suggestion.primaryText && addRecentSearch) {
      addRecentSearch({
        name: suggestion.primaryText,
        storeUrl: suggestion.storeUrl,
        logoUrl: suggestion.logoUrl,
        storeId: suggestion.id,
        type: RecentSearchType.STORE
      })
    }
    onSelect(suggestion)
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = evt => {
    if (evt.target.value !== searchInput) {
      onChange(evt.target.value)
    }
  }

  const handleClear = () => {
    if (searchInput !== '') {
      onChange('')
    }
  }

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      onPressedEsc()
      return
    } else if (event.key === 'Enter') {
      onPressedEnter(searchInput)
      if (isUserLoggedIn && searchInput) {
        const cleanKeyword = sanitizeHtml(searchInput)
        if (addRecentSearch) {
          addRecentSearch({
            name: cleanKeyword,
            type: RecentSearchType.QUERY
          })
        }
      }
    }
  }

  const getSuggestions = (): Suggestion[] =>
    suggestions.map(suggestion => {
      // If we have cashback available, make a string like "5% back"
      const cashbackText: string =
        suggestion.currentCashbackOffer?.active &&
        suggestion.currentCashbackOffer?.fixedRatePercent
          ? formatMessage('main.storecard.currentCashback', {
              cbPercent: suggestion.currentCashbackOffer?.fixedRatePercent
            })
          : ''

      // If we have coupons available, make a string like "1 coupon available"
      // or "2 coupons available"
      const { publicCouponCount } = suggestion

      let couponText = ''

      if (publicCouponCount === 1) {
        couponText = formatMessage('main.storecard.oneAvailableCoupon')
      } else if (publicCouponCount && publicCouponCount > 1) {
        couponText = formatMessage('main.storecard.manyAvailableCoupons', {
          couponCount: publicCouponCount
        })
      }

      // "5% back, 1 coupon available", or else just one of those
      // clauses
      const secondaryText =
        !!couponText && !!cashbackText
          ? `${cashbackText}, ${couponText}`
          : cashbackText || couponText

      return {
        id: suggestion.storeId,
        primaryText: suggestion.name,
        secondaryText,
        decoration: suggestion.logoUrl ? (
          <Avatar src={suggestion.logoUrl} size="sm" />
        ) : (
          <Avatar initials={suggestion.name[0]} size="sm" />
        ),
        storeUrl: suggestion.storeUrl,
        logoUrl: suggestion.logoUrl
      }
    })

  const handleOnFocusEvent = () => {
    if (searchInput !== '') {
      onLoading(isLoading, isEmptyResults)
    }
  }

  return (
    <div
      className="search_autocomplete"
      {...getLocatorProps(LOCATORS.SEARCH_BAR)}
    >
      <AutoSuggest
        {...getLocatorProps(AUTOCOMPLETE_SEARCH_SELECTORS.INPUT)}
        id={AUTOCOMPLETE_SEARCH_SELECTORS.INPUT}
        debounceTimeout={DEBOUNCE_TIMEOUT}
        suggestionList={
          <SuggestionList
            {...getLocatorProps(AUTOCOMPLETE_SEARCH_SELECTORS.SUGGESTIONS)}
            enableKeyboardInteraction="yes"
            suggestions={getSuggestions()}
            query={searchInput}
            onSelect={handleSelect}
          />
        }
        suggestionListFooter={<Terms />}
        isLoadingSuggestions={isLoading}
        name="keyword"
        onKeyDown={event => {
          // By default, typing enter leaves the autocomplete suggestions visible
          // On the search page when performing a click side search, we want them
          // to hide. By triggering a click event, we cause the autosuggest widget's
          // internal state machine to hide the suggestions. This must be done
          // after a delay used by the autosuggest widget, otherwise they would
          // immediately re-appear.
          if (event.key === 'Enter') {
            setTimeout(() => document.head.click(), DEBOUNCE_TIMEOUT + 1)
          }
        }}
        label={placeholder}
        value={searchInput}
        onChange={handleChange}
        fetchSuggestions={(input: string) => {
          if (input !== searchInput) {
            fetchSuggestions(input)
          }
        }}
        triggerComponent={SearchInput}
        onClear={handleClear}
        onKeyUp={handleOnKeyUp}
        onFocus={handleOnFocusEvent}
        autoComplete="off"
      />
    </div>
  )
}

export default SearchAutocomplete
