import { ACTION_FRAGMENT } from './ActionFragment'
import { CURRENCY_VALUE_FRAGMENT } from './CurrencyValueFragment'
import { ERROR_FRAGMENT } from './ErrorFragment'
import { IMAGE_FRAGMENT } from './ImageFragment'
import { MODAL_CONTENT_FRAGMENT } from './ModalContentFragment'
import { POINTS_LABELS_FRAGMENT } from './PointsLabelsFragment'

export const REDEEM_FLOW_INFO_FRAGMENT = `{
  primaryImage ${IMAGE_FRAGMENT}
  title
  subtitle
  buttonText
  currencyValues ${CURRENCY_VALUE_FRAGMENT}
  pointsAvailable
  pointsLabels ${POINTS_LABELS_FRAGMENT}
}`

export const SHOPPING_IMAGE = `{
  url
  ppuiToken
}`

export const SHOPPING_TAG = `{
  text
  type
  iconUrl
  icon ${SHOPPING_IMAGE}
}`

export const REDEEM_FLOW_REDEMPTION_METHOD_OPTIONS_ITEM = `{
  id
  title
  icon ${SHOPPING_IMAGE}
  tags ${SHOPPING_TAG}
}`

export const REDEEM_FLOW_REDEMPTION_METHOD_OPTIONS = `{
  title
  items ${REDEEM_FLOW_REDEMPTION_METHOD_OPTIONS_ITEM}
}`

export const REDEEM_FLOW_OPTION = `{
  primaryImage ${IMAGE_FRAGMENT}
  title
  subtitle
  description
  rewardCash
  rewardPoints
  redemptionMethod
  modalContent ${MODAL_CONTENT_FRAGMENT}
  redemptionMethodOptions ${REDEEM_FLOW_REDEMPTION_METHOD_OPTIONS}
}`

export const REDEEM_FLOW_SELECT_FRAGMENT = `{
  title
  subtitle
  description
  redeemOptions ${REDEEM_FLOW_OPTION}
}`

export const CONSENT_DISCLOSURE_FRAGMENT = `{
  text
  description
}`

export const REDEEM_FLOW_PAYLOAD_FRAGMENT = `{
  screenName
  screenTitle
  redeemErrors ${ERROR_FRAGMENT}
  redeemInfo ${REDEEM_FLOW_INFO_FRAGMENT}
  redeemSelect ${REDEEM_FLOW_SELECT_FRAGMENT}
  actions ${ACTION_FRAGMENT}
  consentDisclosure ${CONSENT_DISCLOSURE_FRAGMENT}
}`

export const REDEEM_FLOW_FRAGMENT = `{
  redeemFlow(input: {
    step: $step,
    method: $method,
    points: $points,
    nonProfitId: $nonProfitId,
    consentGranted: $consentGranted,
    idempotencyKey: $idempotencyKey
  }) ${REDEEM_FLOW_PAYLOAD_FRAGMENT}
}`
