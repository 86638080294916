import React from 'react'
import { HeadingText, BodyText } from '@paypalcorp/pp-react'
import ResponsiveModal from '../ResponsiveModal/ResponsiveModal'
import ModalPrimaryImage from '../ModalPrimaryImage/ModalPrimaryImage'
import ModalTitleSection from '../ModalTitleSection/ModalTitleSection'
import { ModalContent, Action } from '../../../types/ShoppingPageType'
import ActionButton from '../ActionButton/ActionButton'
export interface ActionModalProps {
  id?: string
  isOpen: boolean
  modalContent?: ModalContent | null
  setIsOpen: (isOpen: boolean) => void
}
interface ModalFooterProps {
  actions: Action[] | null
  setIsOpen: ActionModalProps['setIsOpen']
}

const ModalSections = ({
  sections
}: {
  sections: ModalContent['modalSections']
}) => {
  return (
    <>
      {sections.map(({ title, description }, index) => (
        <ModalTitleSection
          as="h2"
          title={title}
          key={`action-modal-section-${index}`}
          className="action-modal__section"
        >
          <BodyText dangerouslySetInnerHTML={{ __html: description }} />
        </ModalTitleSection>
      ))}
    </>
  )
}

const ModalTitle = ({ title }: { title?: ModalContent['title'] | null }) => {
  if (!title) return null
  return (
    <HeadingText as="h1" className="action-modal__title">
      {title}
    </HeadingText>
  )
}

const ModalDescription = ({
  description
}: {
  description?: ModalContent['description'] | null
}) => {
  if (!description) return null
  return (
    <BodyText className="action-modal__description">{description}</BodyText>
  )
}

const ModalFooter = ({ actions, setIsOpen }: ModalFooterProps) => {
  const handleDismiss = () => {
    setIsOpen(false)
  }
  return (
    <div className="action-modal__footer">
      <ActionButton
        className="action-modal__footer__button"
        actions={actions}
        dismissHandler={handleDismiss}
      />
    </div>
  )
}

const ActionModal = ({
  id,
  modalContent,
  isOpen,
  setIsOpen
}: ActionModalProps) => {
  if (!modalContent) return null
  const { title, description, primaryImage, modalSections, actions } =
    modalContent
  const handleOnClose = () => {
    setIsOpen(false)
  }
  return (
    <ResponsiveModal
      containerClassName="action-modal"
      contentClassName="action-modal__content"
      id={id}
      isOpen={isOpen}
      requestClose={handleOnClose}
      closeOnBackgroundClick
      title={title}
      hideTitle
      skipFormFocus
      footerContents={<ModalFooter actions={actions} setIsOpen={setIsOpen} />}
    >
      {/*Tabindex -1 used to keep focus on the top everytime modal opens*/}
      <span tabIndex={-1} />
      <ModalPrimaryImage image={primaryImage} />
      <ModalTitle title={title} />
      <ModalSections sections={modalSections} />
      <ModalDescription description={description} />
    </ResponsiveModal>
  )
}

export default ActionModal
