import { isPlainObject } from 'lodash'
/**
 * Typeguarded function to check for nulls
 */
const isNotNull = <T>(x: T | null): x is T => x !== null

/**
 * Util for filtering nulls from an array in a typescript-friendly way
 */
const filterNullItems = <T>(items: (T | null)[] | null | undefined): T[] => {
  return items?.filter(isNotNull) || []
}

/**
 * TODO: Add types to filterNullItemsDeep
 * https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2716
 *
 * Recursive function to filter null items on arrays
 */
const filterNullItemsDeep = (inputVal: any) => {
  // typeof valueToFilter === 'object' equals true on object, array and null
  // Expecting array or object
  if (isPlainObject(inputVal) || Array.isArray(inputVal)) {
    const filteredNullItems: { [key: string]: any } = Array.isArray(inputVal)
      ? filterNullItems(inputVal)
      : inputVal
    // Call filterNullItemsDeep on each object prop or array item
    for (const key in filteredNullItems) {
      filteredNullItems[key] = filterNullItemsDeep(filteredNullItems[key])
    }

    return filteredNullItems
  }

  return inputVal
}

export { filterNullItems, filterNullItemsDeep }
