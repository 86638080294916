import React, { useEffect, useState } from 'react'

import LogoutModal from 'shoplist-app/shopping/components/v2/LogoutModal'
import SubNavBarV3, { SubNavBarV3Props } from 'components/SubNavBarV3'
import { setupPa, SetupPa } from 'lib/track-client-side'
import { createLocatorProps } from 'contexts/locator'
import {
  SHOPPING_MODULE,
  SHOPPING_HOME_SCREEN_SHOWN,
  SHOPPING_FAILED_CUSTOMER_INTERACTION_OCCURRED,
  SHOPPING_EXPLORE_SCREEN_SHOWN,
  SHOPPING_SAVED_SCREEN_SHOWN,
  EXPLORE_TAB_SOURCE,
  SAVED_TAB_SOURCE,
  FPTI_USER_STATE_LOGGED_IN,
  FPTI_USER_STATE_LOGGED_OUT,
  FPTI_PRODUCT
} from 'lib/client-constants'
import { PAGE_LOCATORS } from 'views/Shopping/constants/automation'
import PageMessages from 'views/Shopping/components/PageMessages'
import { Status } from 'types/graphql/constants/Status'
import { DONE, ERROR, INITIAL, LOADING } from 'graphql/constants/status'
import { sendFptiImpression } from 'lib/fpti-pa'
import { hasJsonError } from 'lib/hasJsonError'
import { SAVED } from 'constants/link'

export interface OmniChannelTemplateProps {
  activeSubNavPage: SubNavBarV3Props['active']
  children: React.ReactNode
  error: string | null
  fptiSetup: Parameters<SetupPa> | null
  status: Status
  locator: (typeof PAGE_LOCATORS)[keyof typeof PAGE_LOCATORS]
  isUserLoggedIn: boolean
}

const OmniChannelTemplate = ({
  activeSubNavPage,
  children,
  error,
  fptiSetup = [SHOPPING_MODULE, SHOPPING_HOME_SCREEN_SHOWN],
  status,
  locator,
  isUserLoggedIn
}: OmniChannelTemplateProps) => {
  const [, setIsPaSetup] = useState(false)

  useEffect(() => {
    /**
     * TODO: https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2237
     * FPTI is temporary. Should be setup for this page in DTSHOPATMS-2237
     */
    if (fptiSetup) {
      setupPa(...fptiSetup)
      setIsPaSetup(true)
    }
  }, [fptiSetup, setIsPaSetup])

  useEffect(() => {
    if (error) {
      const { msg, statusCode } = hasJsonError(error)
      sendFptiImpression({
        event_name: SHOPPING_FAILED_CUSTOMER_INTERACTION_OCCURRED,
        error_code: statusCode,
        error_desc: msg,
        screen: locator // Used to identify the link the user clicked on the split tender view
      })
    }
  }, [error, locator])

  const showError = status === ERROR
  const showLoading = status === INITIAL || status === LOADING
  const showChildren = status === DONE

  useEffect(() => {
    if (showChildren) {
      sendFptiImpression({
        event_name:
          activeSubNavPage === SAVED.id
            ? SHOPPING_SAVED_SCREEN_SHOWN
            : SHOPPING_EXPLORE_SCREEN_SHOWN,
        source:
          activeSubNavPage === SAVED.id ? SAVED_TAB_SOURCE : EXPLORE_TAB_SOURCE,
        user_state: isUserLoggedIn
          ? FPTI_USER_STATE_LOGGED_IN
          : FPTI_USER_STATE_LOGGED_OUT,
        product: FPTI_PRODUCT
      })
    }
  }, [activeSubNavPage, isUserLoggedIn, showChildren])

  return (
    <div {...createLocatorProps(locator)} className="omnichannel-template">
      <SubNavBarV3 active={activeSubNavPage} />
      {showError && <PageMessages hasError />}
      {showLoading && <div>Loading Screen</div>}
      {showChildren && children}
      <LogoutModal />
    </div>
  )
}

export default OmniChannelTemplate
