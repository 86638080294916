import { isPlainObject } from 'lodash'

const NIL = 'nil' // Absence of any value for FPTI

export const toCSVformat = (data: unknown): string => {
  if (typeof data === 'undefined' || data === null) return NIL

  if (isPlainObject(data)) return JSON.stringify(data)

  if (Array.isArray(data)) {
    const dataCVS = data
      .map(value => (`${value}`.trim() ? `${value}`.trim() : NIL))
      .join(',')
      .replace(/undefined|null/gi, NIL)

    return dataCVS
  }

  return `${data}`.trim().length ? `${data}` : NIL
}
