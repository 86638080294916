import { get, isEmpty } from 'lodash'
import type { History } from 'history'
import queryString from 'query-string'

import { ClientData } from './types/ClientData'

type QueryOverrides = {
  entry?: string
}

/**
 * Update specific params in the query string
 * The second argument - overrides - accepts an object of params that needs to be updated
 *
 * Example:
 * overrides: { entry: alert }
 * Before: '/shopping?entry=settings'
 * After: '/shopping?entry=alert'
 */
export const updateQueryParam = (
  history: History,
  overrides: QueryOverrides
) => {
  const { pathname, search, hash } = history.location
  const parsedQuery = queryString.parse(search)
  const updatedQuery = queryString.stringify({
    ...parsedQuery,
    ...overrides
  })
  history.replace(`${pathname}?${updatedQuery}${hash}`)
}

/**
 * Append/Update params to url query string
 * Generate an overrides object consisted with params that need to be updated
 */
export const appendParamsToUrl = (history: History, clientData: ClientData) => {
  const { entryPoint } = clientData
  const overrides: QueryOverrides = {}

  const parsedQuery = queryString.parse(history.location.search)
  if (get(parsedQuery, 'entry') !== entryPoint) {
    overrides.entry = entryPoint
  }

  if (!isEmpty(overrides)) {
    updateQueryParam(history, overrides)
  }
}
