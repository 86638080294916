export type EnvInfo = {
  isProd: boolean
  env: string
}

/**
 * Derives environment information required during initialization.
 * Since this script runs immediately on load it can't rely on
 * client-data set in the DOM.
 *
 * @returns {EnvInfo}
 */
const getEnvInfo = (): EnvInfo => {
  const hostname = window.location.hostname
  if (APP_DEVELOPMENT) {
    return {
      isProd: false,
      env: 'dev'
    }
  } else if (hostname === 'www.paypal.com') {
    return {
      isProd: true,
      env: 'prod'
    }
  }
  return {
    isProd: false,
    env: 'qa'
  }
}

export default getEnvInfo
