import axios from 'axios'
import logger from '../../../lib/logger'
import { get } from 'lodash'

import clientData from '../../../lib/client-data'

export const sendPStudioTrackingEvent = (
  url: string | null,
  eventType: 'impression' | 'click' | 'presentment',
  component: string
) => {
  const allEnvs = get(clientData, 'env', {
    development: false,
    production: true
  })

  // url can be empty string if message is updated on the client
  if (!url || (allEnvs && allEnvs.development)) return
  if (!allEnvs.production) {
    // Remove CSP Errors by replacing the default host by the current one.
    // eg: msmaster.qa.paypal.com --> te-alm123.qa.paypal.com
    // If you're on local host, this will change the url into local host and won't be detected on msmaster staging
    const [hostToReplace] = url.match(/www.+paypal\.com/) || ''
    if (!hostToReplace) return
    url = url.replace(hostToReplace as string, window.location.host)
  }

  axios.get(url).catch(error => {
    const errorMessage = error && (error.message || error.response)
    logger.error(
      `Error sending ${eventType} event for ${component}: ${errorMessage}`,
      error
    )
  })
}
