/**
 * Backend can pass in full urls with the search string included
 * or simply pass in the search string. DTSHOPATMS-2513
 *
 * @param urlOrSearchParamString:string - Absolute URL or just the search string (?t=1&y=2)
 */
export function getSearchParams(
  urlOrSearchParamString: string = ''
): URLSearchParams {
  try {
    const url = new URL(urlOrSearchParamString)
    return url.searchParams
  } catch (_) {
    // Not an absolute URL. Do nothing.
  }

  // if not an absolute URL, we expect it to be just the search part
  return new URLSearchParams(urlOrSearchParamString)
}
