import React from 'react'
import { PAYPAL_THEME } from '@paypalcorp/pp-react'

import './footer.scss'
import TextWithLinks from '../../../../../components/TextWithLinks'

const { sysColorBackgroundSurface: ppBackgroundWhite } = PAYPAL_THEME

interface FooterProps {
  terms: string[]
}

const Footer = ({ terms }: FooterProps) => {
  return (
    <section className="footer--v2" style={{ background: ppBackgroundWhite }}>
      <div className="footer--v2__terms">
        {terms.map((text, idx) => (
          <p key={idx}>
            <TextWithLinks linkProps={{ size: 'sm' }} text={text} />
          </p>
        ))}
      </div>
    </section>
  )
}

export default Footer
