import React from 'react'
import { get } from 'lodash'
import { Image } from '../../../types/ShoppingPageType'

interface Props {
  image?: Image | null
}
const ModalPrimaryImage = ({ image }: Props) => {
  const url: string | null = get(image, 'url') || null
  const accessibilityLabel: string | undefined =
    get(image, 'accessibilityLabel') || undefined
  if (!url) return null
  return (
    <img className="modal-primary-image" src={url} alt={accessibilityLabel} />
  )
}

export default ModalPrimaryImage
