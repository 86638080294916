import { useState, useRef, useCallback } from 'react'
import { get } from 'lodash'
import graphql from '../services/graphql'
import {
  GET_OFFER_DETAILS,
  SAVE_OFFER_TO_WALLET
} from '../shoplist-app/shopping/queries'
import {
  OfferDetailsType,
  Action
} from '../shoplist-app/shopping/types/ShoppingPageType'
import logger from '../lib/logger'

/***
 *  Use custom hook to create a fucntion we can use to get all offerDetails by .
 *  See more here(https://reactjs.org/docs/hooks-custom.html)
 */

interface Props {
  offerProgramId?: string
  honeyOfferId?: string
  honeyStoreId?: string
}

const useOfferDetailsModalData = ({
  offerProgramId,
  honeyOfferId,
  honeyStoreId
}: Props) => {
  const [offerDetails, setOfferDetails] = useState<OfferDetailsType | null>(
    null
  )
  const [fetchLoading, setFetchLoading] = useState<boolean>(true)
  const [saveToWalletLoading, setSaveToWalletLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()
  // requestCountRef will help to determine if a new request was done.
  // If a new request was done, all previous request should be ignored and should not update the state
  // requestsCountRef will keep consitency on its value during the hook life.
  // On a request requestsCountRef will be increased by one
  // and requestCount will declared with requestsCountRef value
  // if by the end of the request requestCount and requestsCountRef are different
  // this means a new request was done and that this request (previosuly requested) is expired
  // and should be ignored.
  const requestsCountRef = useRef(0)
  const isRequestUnexpired = (requestCount: number) =>
    requestCount === requestsCountRef.current
  const increaseRequestCount = () => {
    requestsCountRef.current = requestsCountRef.current + 1
  }

  const fetchOfferDetails = useCallback(async () => {
    setFetchLoading(true)
    setSaveToWalletLoading(false)
    increaseRequestCount()
    const requestCount = requestsCountRef.current
    const query = GET_OFFER_DETAILS
    const variables = {
      offerProgramId,
      honeyOfferId,
      honeyStoreId
    }
    try {
      const {
        data: { data, errors }
      } = await graphql(query, variables)
      if (isRequestUnexpired(requestCount)) {
        if (errors) {
          logger.error('useOfferDetailsModalDataHook', errors)
          throw new Error(JSON.stringify(errors))
        }
        setOfferDetails(get(data, 'uiShopping.offers.items[0]') || null)
      }
    } catch (err) {
      if (isRequestUnexpired(requestCount)) {
        setError(err)
      }
    } finally {
      if (isRequestUnexpired(requestCount)) {
        setFetchLoading(false)
      }
    }
  }, [offerProgramId, honeyOfferId, honeyStoreId])

  const saveToWallet = useCallback(async () => {
    //Ignore saveToWalletRequest if data haven't been fetched first or offer is saved
    if (!offerDetails || offerDetails.savedToWallet) return
    setSaveToWalletLoading(true)
    setFetchLoading(false)
    increaseRequestCount()
    const requestCount = requestsCountRef.current
    try {
      const queryVariables = {
        offerProgramId,
        source: 'SHOPPING_HUB',
        storeName: offerDetails.title,
        landingUrl: offerDetails.landingUrl
      }
      const {
        data: { data, errors }
      } = await graphql(SAVE_OFFER_TO_WALLET, queryVariables)
      if (errors) {
        logger.error('useOfferDetailsModalDataHook', errors)
        throw new Error(JSON.stringify(errors))
      }
      if (isRequestUnexpired(requestCount)) {
        const savedToWallet: OfferDetailsType['savedToWallet'] = get(
          data,
          'uiShopping.saveOfferToWallet.savedToWallet'
        )
        const savedToWalletMessage: OfferDetailsType['savedToWalletMessage'] =
          get(data, 'uiShopping.saveOfferToWallet.message')
        const conditions: OfferDetailsType['conditions'] = get(
          data,
          'uiShopping.saveOfferToWallet.conditions'
        )
        const actions: Action[] =
          get(data, 'uiShopping.saveOfferToWallet.nextActions') || []
        setOfferDetails({
          ...offerDetails,
          savedToWallet,
          savedToWalletMessage,
          conditions,
          actions
        })
      }
    } catch (err) {
      if (isRequestUnexpired(requestCount)) {
        setError(err)
      }
    } finally {
      if (isRequestUnexpired(requestCount)) {
        setSaveToWalletLoading(false)
      }
    }
  }, [offerDetails, offerProgramId])

  return {
    fetchOfferDetails,
    saveToWallet,
    fetchLoading,
    saveToWalletLoading,
    error,
    offerDetails
  }
}

export default useOfferDetailsModalData
