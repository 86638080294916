/**
 * Shared automation constants
 */
export const LOCATORS = {
  ACTION_LINK: 'action_link',
  ACTION_BUTTON: 'action_button',
  EXCLUSIONS_LINK: 'exclusions_link',
  ERROR_FALLBACK: 'error_fallback',
  GOLD_STORE_TERMS_MODAL: 'gold_store_terms_modal',
  CONTAINER: 'container',
  SECTIONS: 'sections',
  SECTION: 'slnw-section',
  SECTION__TITLE_HEADER: 'section__title_header',
  SECTION__TITLE_SUBTITLE_HEADER: 'section__title_subtitle_header',
  DESCRIPTION: 'description',
  CASHBACK_OFFER_DETAILS_MODAL: 'cashback_offer_details_modal',
  CASHBACK_OFFER_DETAILS_MODAL_TOAST: 'cashback_offer_details_modal_toast',
  HOW_IT_WORKS_ITEM__TERMS: 'how_it_works_item__terms',
  HOW_IT_WORKS_ITEM__EXCLUSIONS: 'how_it_works_item__exclusions',
  MODAL_TITLE_SECTION: 'modal_title_section',
  SUBNAVIGATION_V3: 'subnavigation_v3',
  LINK: 'link',
  LINK_GOLD: 'Link_gold',
  LINKS: 'links',
  PAGE_MESSAGES: 'page_messages',
  PAGE_MESSAGES_TITLE: 'page_messages__title',
  PAGE_MESSAGES_BODY: 'page_messages__body',
  PAGE_MESSAGES_CTA: 'page_messages__cta',
  SEARCH_BAR: 'search_bar',
  SHOW_MORE: 'show_more',
  SHOW_ALL: 'show_all',
  PAGE_TITLE: 'page_title',
  PAGE_TITLE__TITLE: 'page_title__title',
  PAGE_TITLE__SUBTITLE: 'page_title__subtitle',
  GO_BUTTON: 'go_button',
  BANNER: 'banner',
  BANNER__LINK: 'banner__link',
  OMNICHANNEL_WIDGET__CAROUSEL: 'carousel',
  OMNICHANNEL_WIDGET__PRIMARY_OFFER_CARD: 'primary_offer_card',
  OMNICHANNEL_WIDGET__SECONDARY_OFFER_CARD: 'secondary_offer_card',
  OMNICHANNEL_WIDGET__TERTIARY_OFFER_CARD: 'tertiary_offer_card',
  OFFER_DETAILS_LOADING_SPINNER: 'offer_details_loading_spinner',
  OFFER_DETAILS_MESSAGE: 'offer_details_message',
  TERMS: 'terms',
  RECENT_SEARCHES: 'recent-searches'
}

export const SELECTORS_TEST_ID = {
  STORE_DETAILS_HEADER_AVERAGE_SAVINGS_30_D:
    'store-details-header__item-averageSavings30d',
  STORE_DETAILS_HEADER_FREE_SHIPPING: 'store-details-header__item-freeShipping',
  STORE_DETAILS_HEADER_FREE_RETURN: 'store-details-header__item-freeReturn',
  STORE_DETAILS_HEADER_PAY_IN_FOUR_PAYMENTS:
    'store-details-header__item-payInFourPayments',
  LOGOUT_MODAL_CLOSE_BUTTON: 'logout_modal_v2__close_button',
  LOGOUT_MODAL_CTA_BUTTON: 'logout_modal_v2__cta_button'
}

export const OFFER_SECTION_SELECTORS = {
  OFFER_SECTION_OFFER_CARD: 'offer-card--v2'
}

export const AUTOCOMPLETE_SEARCH_SELECTORS = {
  INPUT: 'autocomplete__input',
  SUGGESTIONS: 'autocomplete__suggestions'
}

export const POPOVER_SEARCH_SELECTORS = {
  POPOVER_SEARCH: 'popover',
  POPOVER_SEARCH_CONTAINER: 'search_popover__container'
}

export const SEARCH_PREVIEW_SELECTORS = {
  LOADING: 'popularStores__container-loading',
  STORE: 'popularStores__container-store',
  NO_MATCHES: 'popular-stores__container-header__no-matches'
}

export const RECENT_SEARCHES_SELECTORS = {
  ITEMS: 'recent-searches__items',
  ITEM: 'recent-searches__item',
  HEADER: 'recent-searches__header',
  STORE_LOGO: 'logo__image',
  SEARCH_LOGO: 'recent-searches__search-icon',
  CLOSE_ICON: 'recent-searches__close-icon'
}
