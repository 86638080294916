import React, { useCallback, useState } from 'react'

import { createLocatorProps } from 'contexts/locator'
import { SectionType } from 'shoplist-app/shopping/types/ShoppingPageType'

import { default as SectionComponent } from '../Shopping/components/Section'
import './renderSectionV3.scss'
import PageMessages from 'views/Shopping/components/PageMessages'
import { LOCATORS } from 'constants/automation'
import { Section } from 'types/graphql/fragments/Section'
import { LAYOUT_CONTAINER_TYPES } from 'lib/client-constants'

type RenderSectionV3Props = {
  sections: SectionType[]
  layoutId: string
}

const getHasOmniChannelWidget = (sections: SectionType[]) =>
  !!sections.find(
    section =>
      section.layout.containerType === LAYOUT_CONTAINER_TYPES.CAROUSEL ||
      section.layout.containerType === LAYOUT_CONTAINER_TYPES.LIST
  )

const getUpdatedVisibleSections = (
  sections: SectionType[],
  sectionId?: Section['sectionId']
) => {
  const filteredSections =
    sectionId !== undefined
      ? sections.filter(section => {
          return section.sectionId !== sectionId
        })
      : sections
  const hasOmniChannelWidget = getHasOmniChannelWidget(filteredSections)
  if (!hasOmniChannelWidget) return []
  return filteredSections
}

const RenderSectionV3 = ({ layoutId, sections }: RenderSectionV3Props) => {
  // This returns as empty array if there's no widget section on the section array
  const initialVisibleSections = getUpdatedVisibleSections(sections)
  // If sections does not contain a widget section there's an error with the SPNS response
  const initialHasError = !getHasOmniChannelWidget(sections)
  const [visibleSections, setVisibleSections] = useState(initialVisibleSections)
  const [hasSectionWithErrors, setHasSectionWithErrors] =
    useState(initialHasError)

  const handleEmpty = useCallback((sectionId: SectionType['sectionId']) => {
    setVisibleSections(prevVisibleSections =>
      getUpdatedVisibleSections(prevVisibleSections, sectionId)
    )
  }, [])

  const handleError = useCallback((sectionId: SectionType['sectionId']) => {
    setHasSectionWithErrors(true)
    setVisibleSections(prevVisibleSections =>
      getUpdatedVisibleSections(prevVisibleSections, sectionId)
    )
  }, [])

  if (!visibleSections.length) {
    return <PageMessages hasNoDeals hasError={hasSectionWithErrors} />
  }

  return (
    <div
      {...createLocatorProps(LOCATORS.SECTIONS)}
      className="render-section-v3"
    >
      {visibleSections.map((section, index) => (
        <SectionComponent
          layoutId={layoutId}
          section={section}
          key={`section-${index}`}
          onEmpty={handleEmpty}
          onError={handleError}
        />
      ))}
    </div>
  )
}

export default RenderSectionV3
