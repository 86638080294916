import React, { useContext, useEffect } from 'react'
import {
  BodyText,
  SearchIcon,
  Link,
  IconButton,
  CloseIcon
} from '@paypalcorp/pp-react'
import './recentSearches.scss'
import { Logo } from '@paypalcorp/shopping-components'
import { Message } from '@paypalcorp/worldready-react'

import { RecentSearchesContext } from 'contexts/RecentSearchesContext/RecentSearchesContext'
import useWorldReady from 'hooks/useWorldReady'
import {
  RecentSearchItem,
  RecentSearchType
} from 'shoplist-app/shopping/types/ShoppingPageType'
import getAffiliateLink from 'shoplist-app/store/utils/get-affiliate-link'
import clientData from 'lib/client-data'
import { LOCATORS } from 'constants/automation'
import { useLocatorContext, withLocatorContext } from 'contexts/locator'
import { sendFptiClick, sendFptiImpression } from 'lib/fpti-pa'
import {
  NEW_SEARCH_STORE_AND_DEALS,
  RECENT_SEARCHES,
  SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN,
  SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED,
  SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN
} from 'lib/client-constants'
import { getRecentSearchFptiData } from 'views/SearchHoney/utils/getSearchFptiData'

export interface RecentSearchesProps {
  isSearchResultsPage?: boolean
}

const RecentSearches = ({
  isSearchResultsPage = false
}: RecentSearchesProps) => {
  const { getLocatorProps } = useLocatorContext()
  const { formatMessage } = useWorldReady()
  const { recentSearches, addRecentSearch, removeRecentSearch } =
    useContext(RecentSearchesContext) || {}
  const searchResultsSelector = isSearchResultsPage ? 'search-results-page' : ''

  useEffect(() => {
    if (recentSearches?.length) {
      sendFptiImpression({
        event_name: SHOPPING_SEARCH_RESULT_DEFAULT_MODULE_SHOWN,
        banner_type: RECENT_SEARCHES
      })

      recentSearches.forEach((item: RecentSearchItem, index: number) => {
        sendFptiImpression({
          ...getRecentSearchFptiData({ item, index }),
          event_name: SHOPPING_SEARCH_RESULT_DEFAULT_TILE_SHOWN
        })
      })
    }
  }, [recentSearches])

  if (!recentSearches || recentSearches.length <= 0) {
    return null
  }

  const trackRecentSearchItemCLick = (
    item: RecentSearchItem,
    index: number
  ) => {
    sendFptiClick({
      ...getRecentSearchFptiData({ item, index }),
      event_name: SHOPPING_SEARCH_RESULT_DEFAULT_TILE_PRESSED
    })
  }

  return (
    <div
      className="recent-searches"
      {...getLocatorProps(searchResultsSelector)}
    >
      <div
        className={`recent-searches__container__header ${
          isSearchResultsPage && 'search-results-page__container__header'
        }`}
        {...getLocatorProps(searchResultsSelector, 'header')}
      >
        <header>
          <BodyText>
            <Message id="main.storeSearch.recentSearches.header" />
          </BodyText>
        </header>
      </div>
      <ol
        {...getLocatorProps(searchResultsSelector, 'items')}
        className={
          isSearchResultsPage
            ? 'search-results-page__items'
            : 'recent-searches__items'
        }
      >
        {recentSearches.map((item, index) => {
          let itemUrl = ''
          let target = '_self'
          if (item.type === RecentSearchType.STORE) {
            itemUrl = getAffiliateLink(
              item.storeId,
              clientData.fptiSessionId!,
              item.storeUrl
            )
            target = '_blank'
          } else {
            itemUrl = `${NEW_SEARCH_STORE_AND_DEALS}${encodeURIComponent(
              item.name
            )}`
          }
          return (
            <li
              key={item.name}
              className={`recent-searches__item ${
                isSearchResultsPage && 'search-results-page__item'
              }`}
            >
              <Link
                href={itemUrl}
                target={target}
                className="recent-searches__item__link"
                onClick={() => {
                  trackRecentSearchItemCLick(item, index)
                  if (addRecentSearch) {
                    addRecentSearch(item)
                  }
                }}
                {...getLocatorProps(searchResultsSelector, 'item')}
              >
                {item.type === RecentSearchType.STORE ? (
                  <Logo
                    className="recent-searches__item__store__logo"
                    logoImage={{ url: item.logoUrl }}
                    size="xs"
                    {...getLocatorProps('store-logo')}
                  />
                ) : (
                  <SearchIcon
                    size="xs"
                    {...getLocatorProps('search-icon')}
                    className="recent-searches__item__search__logo"
                  />
                )}
                <BodyText
                  className={`recent-searches__item__label ${
                    isSearchResultsPage && 'search-results-page__item__label'
                  }`}
                  clampAtLine={1}
                >
                  {item.name}
                </BodyText>
              </Link>
              <IconButton
                screenReaderText={formatMessage(
                  'main.storeSearch.recentSearches.delete'
                )}
                svgIconComponent={CloseIcon}
                className={`recent-searches__item__close__icon ${
                  isSearchResultsPage &&
                  'search-results-page__item__close__icon'
                }`}
                iconClassName="recent-searches__item__close__icon-img"
                {...getLocatorProps('close-icon')}
                onClick={() => {
                  if (removeRecentSearch) {
                    removeRecentSearch(item)
                  }
                }}
              >
                <Message id="main.storeSearch.recentSearches.delete" />
              </IconButton>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default withLocatorContext(LOCATORS.RECENT_SEARCHES, {
  isRootContext: true
})(RecentSearches)
