import React, { ReactEventHandler, ReactNode } from 'react'
import { Dialog as Modal, Sheet } from '@paypalcorp/pp-react'
import useBreakpoint from '../../../../../lib/useBreakpoint'

type ModalProps = {
  'data-cy'?: string
  className?: string
  closeButtonLabel?: string
  closeButtonProps?: React.ComponentProps<typeof Modal>['closeButtonProps']
  isOpen?: boolean | string
  center?: boolean
  hideTitle?: boolean
  title: string
  id?: string
  closeOnBackgroundClick?: boolean
  action?: React.ReactNode
  footerContents?: React.ReactNode
  footerAlign?: 'center' | 'right'
  rest?: unknown
  children?: React.ReactChild | React.ReactChild[]
  containerRef?: React.MutableRefObject<any>
  headerClassName?: string
  footerClassName?: string
  contentClassName?: string
}

type SheetProps = {
  'data-cy'?: string
  isOpen?: boolean
  center?: boolean
  noPadding?: boolean
  noCloseButton?: boolean
  closeButtonLabel?: string
  closeButtonProps?: React.ComponentProps<typeof Sheet>['closeButtonProps']
  hideTitle?: boolean
  mobileFixedHeight?: '90'
  title: string
  id?: string
  onOpen?: () => void
  action?: ReactNode
  headerContents?: ReactNode
  footerContents?: ReactNode
  footerAlign?: 'center' | 'right'
  containerRef?: React.MutableRefObject<any>
  requestClose?: ReactEventHandler
  className?: string
  containerClassName?: string
  wrapperClassName?: string
  headerClassName?: string
  footerClassName?: string
  returnFocusToTrigger?: boolean
  skipFormFocus?: boolean
  rest?: unknown
}

type Props = ModalProps & SheetProps

const ResponsiveModal = ({
  'data-cy': dataCy,
  isOpen,
  className,
  closeButtonLabel,
  closeButtonProps,
  center,
  noPadding,
  noCloseButton,
  hideTitle,
  mobileFixedHeight,
  title,
  id,
  closeOnBackgroundClick,
  onOpen,
  action,
  headerContents,
  footerContents,
  footerAlign,
  rest,
  children,
  containerRef,
  requestClose,
  containerClassName,
  contentClassName,
  wrapperClassName,
  headerClassName,
  footerClassName,
  returnFocusToTrigger,
  skipFormFocus
}: Props) => {
  const { isMobile } = useBreakpoint()
  /**
   * TODO: https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-3005
   * In every ResponsiveModal we're using both events (onClose and requestClose), and in each event we're sending the same function, so this makes that the function is executed twice.
   * requestClose: Is executed right after the dismiss button is clicked
   * onClose: Is executed when the dialog is closed and is not visible anymore, it can't be executed if requestClose is not executed first
   * We just need one function, that's why the onClose() is overwritten with an empty function until we decide how to solve this issue, full description on the ticket above
   */
  return (
    <>
      {isMobile ? (
        <Sheet
          data-cy={dataCy}
          isOpen={isOpen}
          onClose={() => {}}
          center={center}
          noPadding={noPadding}
          noCloseButton={noCloseButton}
          closeButtonLabel={closeButtonLabel}
          closeButtonProps={closeButtonProps}
          hideTitle={hideTitle}
          mobileFixedHeight={mobileFixedHeight}
          title={title}
          id={id}
          onOpen={onOpen}
          action={action}
          headerContents={headerContents}
          footerContents={footerContents}
          footerAlign={footerAlign}
          containerRef={containerRef}
          requestClose={requestClose}
          className={className}
          containerClassName={containerClassName}
          wrapperClassName={wrapperClassName}
          headerClassName={headerClassName}
          footerClassName={footerClassName}
          returnFocusToTrigger={returnFocusToTrigger}
          skipFormFocus={skipFormFocus}
          rest={rest}
        >
          {children}
        </Sheet>
      ) : (
        <Modal
          data-cy={dataCy}
          onClose={() => {}}
          className={contentClassName}
          containerClassName={containerClassName}
          closeButtonLabel={closeButtonLabel}
          closeButtonProps={closeButtonProps}
          isOpen={isOpen}
          center={center}
          hideTitle={hideTitle}
          title={title}
          id={title}
          closeOnBackgroundClick={closeOnBackgroundClick}
          action={action}
          footerContents={footerContents}
          footerAlign={footerAlign}
          requestClose={requestClose}
          rest={rest}
          containerRef={containerRef}
          headerClassName={headerClassName}
          footerClassName={footerClassName}
        >
          {children}
        </Modal>
      )}
    </>
  )
}

export default ResponsiveModal
