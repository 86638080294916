import React, { useEffect } from 'react'
import {
  DeepLinkOfferModal,
  useFraudnet
} from '@paypalcorp/shopping-components'

import OmniChannelTemplate from 'components/Template/OmnichannelTemplate'
import { EXPLORE } from 'constants/link'
import {
  SHOPPING_MODULE,
  SHOPPING_HOME_SCREEN_SHOWN
} from 'lib/client-constants'
import { PAGE_LOCATORS } from 'views/Shopping/constants/automation'
import RenderSectionV3 from 'views/RenderSectionV3'
import { SectionType } from 'shoplist-app/shopping/types/ShoppingPageType'
import useGraphQL from 'hooks/useGraphQL'
import { SHOPPING_FEED_TYPES } from 'graphql/constants/shoppingFeed'
import { GET_SHOPPING_FEED_QUERY } from 'graphql/queries'
import { GetShoppingFeedQuery } from 'types/graphql/queries'
import clientData from 'lib/client-data'

import { EXPLORE_TAB_TOUCHPOINT } from './constants'
import { FRAUDNET_APP_ID } from '../../../constants/fraudnet'
import { getBaseWidgetProps } from '../../../lib/widget'
import Layout from 'components/Layout'

const ShoppingPageV3 = () => {
  const { fetchData, data, status, error } = useGraphQL<GetShoppingFeedQuery>(
    GET_SHOPPING_FEED_QUERY
  )

  useEffect(() => {
    fetchData({
      feedType: SHOPPING_FEED_TYPES.EXPLORE_TAB,
      isPageLayout: true
    })
  }, [fetchData])

  useFraudnet(FRAUDNET_APP_ID)

  return (
    <Layout>
      <DeepLinkOfferModal {...getBaseWidgetProps()} />
      <OmniChannelTemplate
        activeSubNavPage={EXPLORE.id}
        error={error}
        fptiSetup={[SHOPPING_MODULE, SHOPPING_HOME_SCREEN_SHOWN]}
        status={status}
        locator={PAGE_LOCATORS.EXPLORE_PAGE}
        isUserLoggedIn={clientData.isUserLoggedIn}
      >
        {/**
         * TODO: Replace empty sections
         * https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2234
         */}
        <RenderSectionV3
          layoutId={EXPLORE_TAB_TOUCHPOINT}
          /**
           * TODO remove SectionType[] casting when RenderSections accept new types
           * https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2717
           */
          sections={(data?.shoppingFeed?.sections as SectionType[]) || []}
        />
      </OmniChannelTemplate>
    </Layout>
  )
}

export default ShoppingPageV3
