export const isMockModeEnabled = (url: { search: string } = { search: '' }) => {
  let params = new URLSearchParams(url.search.substring(1))
  return params.get('isMockMode') === 'true' ? true : false
}

export const REWARD_POINTS_ACTIVITIES_PAGE = `query getRewardsActivityPage {
  uiShopping {
    shoppingFeed(input: { isHoneyExtensionInstalled: false, feedType: REWARD_POINTS_ACTIVITIES }) {
      sections {
        slotId
        incentiveType
        sectionId
        touchpointId
        rank
        title
        subtitle
        icon {
          accessibilityLabel
          url
          name
        }
        backgroundColor
        loadingText
        layout {
          containerType
          itemType
        }
        trackingDetails {
          clickUrl
          dismissUrl
          impressionUrl
        }
        actions {
          type
          title
          navigationType
          navigationTarget
        }
        items {
          itemId
          itemType
          rank
          title
          subtitle
          shortTitle
          description
          textColor
          decryptedOfferProgramId
          encryptedOfferProgramId
          primaryImage {
            accessibilityLabel
            url
            backgroundColor
          }
          secondaryImage {
            accessibilityLabel
            url
            backgroundColor
          }
          logoImage {
            url
            name
            backgroundColor
          }
          tags {
            text
            type
            iconUrl
          }
          termsAndConditionsSection {
            title
            content
          }
          actions {
            type
            title
            navigationType
            navigationTarget
            modalContent {
              title
              modalSections {
                title
                description
                icon {
                  accessibilityLabel
                  url
                  name
                }
              }
              actions {
                type
              }
            }
          }
          textColor
          backgroundColor
        }
      }
    }
  }
}
`

export const GET_OFFER_DETAILS = `query getOfferDetails($offerProgramId: String, $honeyOfferId: String, $honeyStoreId: String) {
  uiShopping {
    offers(
      input: {
        offerProgramId: $offerProgramId,
        honeyOfferId: $honeyOfferId,
        honeyStoreId: $honeyStoreId
      }) {
        items {
          ...ItemFragment
        }
      }
    }
}
fragment ImageFragment on UI_Shopping_Image {
  accessibilityLabel
  url
  backgroundColor
}
fragment ActionsFragment on UI_Shopping_Action {
  type
  title
  navigationType
  navigationTarget
}
fragment ItemContentFragment on UI_Shopping_ItemContent {
  title
  content
}
fragment TrackingDetailsFragment on UI_Shopping_TrackingDetails {
  clickUrl
  dismissUrl
  impressionUrl
  presentmentUrl
}
fragment ItemFragment on UI_Shopping_Item {
  title
  itemId
  subtitle
  storeIds
  description
  landingUrl
  details {
    ...ItemContentFragment
  }
  termsAndConditionsSection {
    ...ItemContentFragment
  }
  howToUseSection {
    ...ItemContentFragment
  }
  conditions {
    image {
      ...ImageFragment
    }
    content
  }
  couponCodeSection {
    ...ItemContentFragment
  }
  primaryImage {
    ...ImageFragment
  }
  decryptedOfferId
  decryptedOfferProgramId
  actions {
    ...ActionsFragment
  }
  savedToWallet
  trackingDetails {
    ...TrackingDetailsFragment
  }
  rewardsPointsLabel
  shoppingCategories
}
`

export const SAVE_OFFER_TO_WALLET = `mutation saveOfferToWallet($offerProgramId: String, $source: UI_Shopping_Source, $storeName: String, $landingUrl: String) {
  uiShopping {
    saveOfferToWallet(
      input: {
        offerProgramId: $offerProgramId,
        source: $source,
        storeName: $storeName,
        landingUrl: $landingUrl
      }) {
        offerId
        savedToWallet
        message
        conditions {
          image {
            ...ImageFragment
          }
          content
        }
        nextActions {
          ...ActionsFragment
        }
      }
  }
}
fragment ActionsFragment on UI_Shopping_Action {
  type
  title
  navigationType
  navigationTarget
}
fragment ImageFragment on UI_Shopping_Image {
  accessibilityLabel
  url
  name
  backgroundColor
}
`

export const GET_HOW_REWARD_POINTS_WORK = `query getHowRewardPointsWork($storeId: String) {
  uiShopping {
    stores(input: { storeIds: [$storeId], howRewardPointsWork: true }) {
      stores {
        itemId
        title
        primaryImage {
          ...ImageFragment
        }
        rewardsPointsLabel
        rewardsPointsSubtitle
        howRewardPointsWork {
          ...SectionFragment
        }
        actions  {
          type
          title
          navigationType
          navigationTarget
        }
        storeIds
        shoppingCategories
      }
    }
  }
}
fragment SectionFragment on UI_Shopping_Section {
  title
  layout {
    containerType
    itemType
  }
  trackingDetails {
    ...TrackingDetailsFragment
  }
  items {
    ...ItemFragment
  }
}
fragment ItemFragment on UI_Shopping_Item {
  itemId
  rank
  title
  termsAndConditionsSection {
    title
    content
  }
  primaryImage {
    ...ImageFragment
  }
}
fragment ImageFragment on UI_Shopping_Image {
  accessibilityLabel
  url
  name
  backgroundColor
  imageSize
}
fragment TrackingDetailsFragment on UI_Shopping_TrackingDetails{
  clickUrl
  dismissUrl
  impressionUrl
  presentmentUrl
}
`

export const GET_HYBRID_OFFER_DETAILS = `query getHybridOfferDetails($storeId: String, $campaignId: String) {
    uiShopping {
      offers(
        input: {
          campaignId: $campaignId,
          storeIds: [$storeId]
        }) {
          items {
            itemId
            itemType
            campaignId
            title
            subtitle
            description
            storeIds
            rewardsPointsLabel
            primaryImage {
              ...ImageFragment
            }
            decryptedOfferProgramId
            howToUseSection {
              title
              content
            }
            actions {
              ...ActionsFragment
            }
            landingUrl
            savedToWallet
            textColor
            backgroundColor
            howItWorksSection {
              ...SectionFragment
            }
            tags {
              ...TagsFragment
            }
            offersEffectivePeriodInfo {
              content
            }
          }
        }
      }
  }
  fragment SectionFragment on UI_Shopping_Section {
    title
    layout {
      containerType
      itemType
    }
    items {
      ...ItemFragment
    }
  }
  fragment ItemFragment on UI_Shopping_Item {
    itemId
    rank
    title
    termsAndConditionsSection {
      title
      content
    }
    primaryImage {
      ...ImageFragment
    }
    actions{
      title
    }
  }
  fragment ImageFragment on UI_Shopping_Image {
    accessibilityLabel
    url
    name
    backgroundColor
    imageSize
  }
  fragment ActionsFragment on UI_Shopping_Action {
    type
    title
    navigationType
    navigationTarget
    modalContent {
      title
      description
      primaryImage {
        url
        accessibilityLabel
      }
      modalSections {
        title
        description
      }
      actions {
        type
        title
        navigationType
        navigationTarget
      }
    }
  }
  fragment TagsFragment on UI_Shopping_Tag {
    text
    type
  }
`

export const SAVE_HYBRID_OFFER_TO_WALLET = `
mutation saveHybridOffer($campaignId: String, $landingUrl: String) {
  uiShopping {
    saveOfferToWallet(
      input: {
        campaignId: $campaignId,
        landingUrl: $landingUrl
      }) {
        campaignId
        savedToWallet
        message
        nextActions {
          ...ActionsFragment
        }
      }
  }
}

fragment ActionsFragment on UI_Shopping_Action {
type
title
navigationType
navigationTarget
modalContent {
  title
  description
  primaryImage {
    url
    accessibilityLabel
  }
  modalSections {
    title
    description
  }
  actions {
    type
    title
    navigationType
    navigationTarget
  }
}
}
`
