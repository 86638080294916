import React from 'react'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'

import { LOCATORS } from 'constants/automation'
import { LAYOUT_CONTAINER_TYPES } from 'lib/client-constants'
import { isLink } from 'lib/getActionProps'
import Banner from 'shoplist-app/shopping/components/v2/Banner'
import { SectionType } from 'shoplist-app/shopping/types/ShoppingPageType'
import { Section as V3Section } from 'types/graphql/fragments/Section'

import GoButton from '../GoButton'
import OmniChannelComponentSection, {
  OmniChannelComponentSectionProps
} from '../OmniChannelComponentSection'
import PageTitle from '../PageTitle'
import './section.scss'

interface SectionWrapperProps {
  actions?: SectionType['actions']
  children: React.ReactNode
  subtitle?: SectionType['subtitle']
  title?: SectionType['title']
}

export interface SectionProps {
  section: SectionType
  layoutId: string
  onEmpty: OmniChannelComponentSectionProps['onEmpty']
  onError: OmniChannelComponentSectionProps['onError']
}

export const SectionWrapper = ({
  actions,
  children,
  subtitle,
  title
}: SectionWrapperProps) => {
  const actionType = actions?.[0]?.type
  const navigationType = actions?.[0]?.navigationType
  const navigationTarget = actions?.[0]?.navigationTarget
  const isLinkAction = isLink(actionType, navigationType) && navigationTarget

  if (title && subtitle)
    return (
      <div className="section-v3" data-cy={LOCATORS.SECTION}>
        <div
          className="section-v3__titleSubtitleHeader"
          data-cy={LOCATORS.SECTION__TITLE_SUBTITLE_HEADER}
        >
          <div>
            <HeadingText
              as="h2"
              className="section-v3__titleSubtitleHeader__title"
              size="sm"
            >
              {title}
            </HeadingText>
            <BodyText as="h3">{subtitle}</BodyText>
          </div>
        </div>
        {children}
      </div>
    )

  if (title || isLinkAction)
    return (
      <div className="section-v3" data-cy={LOCATORS.SECTION}>
        <div
          className="section-v3__titleHeader"
          data-cy={LOCATORS.SECTION__TITLE_HEADER}
        >
          {title ? (
            <BodyText className="section-v3__titleHeader__title" strong as="h2">
              {title}
            </BodyText>
          ) : null}
          {isLinkAction ? <GoButton actions={actions} /> : null}
        </div>
        {children}
      </div>
    )

  return (
    <div className="section-v3" data-cy={LOCATORS.SECTION}>
      {children}
    </div>
  )
}

const Section = ({ layoutId, section, onEmpty, onError }: SectionProps) => {
  const { items, layout, title, subtitle, htmlSubtitle, touchpointId } = section
  switch (layout.containerType) {
    case LAYOUT_CONTAINER_TYPES.PAGE_TITLE:
      return (
        <SectionWrapper>
          <PageTitle
            title={title}
            subtitle={htmlSubtitle?.content || subtitle}
          />
        </SectionWrapper>
      )
    case LAYOUT_CONTAINER_TYPES.LIST:
    case LAYOUT_CONTAINER_TYPES.CAROUSEL:
      return (
        <OmniChannelComponentSection
          layoutId={layoutId}
          /**
           * TODO: remove V3Section casting when RenderSections accept new types
           * https://engineering.paypalcorp.com/jira/browse/DTSHOPATMS-2717
           */
          section={section as V3Section}
          onEmpty={onEmpty}
          onError={onError}
        />
      )
    case LAYOUT_CONTAINER_TYPES.NONE:
      if (!items?.[0]) return null
      return (
        <SectionWrapper>
          <Banner item={items[0]} touchpointId={touchpointId} />
        </SectionWrapper>
      )
    default:
      return null
  }
}

export default Section
