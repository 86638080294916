import React from 'react'
import { ArrowRightIcon, IconButton, Link } from '@paypalcorp/pp-react'

import {
  Action,
  HandleClick
} from 'shoplist-app/shopping/types/ShoppingPageType'
import { getActionProps } from 'lib/getActionProps'
import useBreakpoint from 'lib/useBreakpoint'
import { LOCATORS } from 'constants/automation'

type GoButtonProps = { actions?: Action[]; onClick?: HandleClick }

const GoButton = ({ actions, onClick }: GoButtonProps) => {
  const action = actions?.[0]
  const { isMobile, isTablet } = useBreakpoint()
  const { title, href, target, handleLinkClick } = getActionProps({
    action,
    onClick
  })
  if (isMobile || isTablet) {
    return href ? (
      <IconButton
        as="a"
        data-cy={LOCATORS.GO_BUTTON}
        href={href}
        target={target}
        buttonType="secondary"
        inverse
        size="sm"
        svgIconComponent={ArrowRightIcon}
        onClick={handleLinkClick}
        theme="v2"
      >
        {title}
      </IconButton>
    ) : null
  }
  return title && href ? (
    <Link theme="v2" href={href} target={target} onClick={handleLinkClick}>
      {title}
    </Link>
  ) : null
}
export default GoButton
