import { BASE_HONEY_URL } from './client-constants'
import { HoneyOperationName } from '../types/HoneyOperationName'

export const getHoneyAPIUrl = ({
  variables,
  operationName
}: {
  variables?: Record<string, number | string | boolean>
  operationName: HoneyOperationName
}) => {
  let url = `${BASE_HONEY_URL}?operationName=${operationName}`

  if (variables) {
    const variablesAsString = encodeURIComponent(JSON.stringify(variables))
    url += `&variables=${variablesAsString}`
  }

  return url
}
