import React from 'react'

const ModalFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="modal-footer--v2" data-cy="modal-footer">
      {children}
    </div>
  )
}

export default ModalFooter
